/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from '@mui/material';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useDropzone } from 'react-dropzone';
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MDBadge from "components/MDBadge";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import "react-datepicker/dist/react-datepicker.css";
// Data
import MDPagination from 'components/MDPagination';
import { deleteDesign, editOrder, getOrders, uploadDesign } from "features/slices";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bcrypt from 'bcryptjs';
import NoteDialog from 'examples/NoteDialog';
import { receiveDesign, setLoading, updateLoading } from 'features/slices';
import { newLink } from 'features/slices';
import { updateCurrentPage } from 'features/slices';

function Tables() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.data || []);
  const permission = useSelector((state) => state.user.permission || []);
  const user = useSelector((state) => state.user.user);
  const filterStore = useSelector((state) => state.user.filterStore);
  const order_status = useSelector((state) => state.user.order_status);
  const startDate = useSelector((state) => state.user.startDate);
  const search = useSelector((state) => state.user.search);
  const currentPage = useSelector((state) => state.user.currentPage) || 1;
  const endDate = useSelector((state) => state.user.endDate);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [itemEditor, setItemEditor] = useState({})
  const [designLink, setDesignLink] = useState([])
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [type, setType] = useState(false);
  const [exist, setExits] = useState(false);
  const [asyncDesign, setAsyncDesign] = useState(false);
  const [alert, setAlert] = useState(false);
  const [note, setNote] = useState("");
  const [frontImageLink, setFrontImageLink] = useState("");
  const [backImageLink, setBackImageLink] = useState("");
  function getGoogleDriveFileId(url) {
    const regex = /\/d\/([^\/]+)/;
    const match = url.match(regex);
    return match ? `https://drive.usercontent.google.com/download?id=${match[1]}` : null;
  }
  const handleLinkSubmit = async (side, link) => {
    try {
      let linkConvert = link.replace(/,/g, "%2C")
      if (link.includes("drive.google")) {
        linkConvert = getGoogleDriveFileId(link)
      }
      const rest2 = await dispatch(newLink({ urls: linkConvert }))
      linkConvert = rest2.payload?.[0]
      dispatch(setLoading(true))
      const response = await fetch(linkConvert);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = btoa(reader.result);
        if (side === "front") {
          setImageFront([base64String]);
        } else if (side === "back") {
          setImageBack([base64String]);
        }
      };
      reader.readAsBinaryString(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
    dispatch(setLoading(false))
  };
  const handleDeleteNote = async (index) => {
    const updatedNotes = itemEditor.note.split("\n").filter((_, i) => i !== index);
    const res = await dispatch(editOrder({ user, field: "note", string: updatedNotes.join("\n"), id: itemEditor.id }))
    if (res.payload.status == 0) {
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
          }))

        });
      })
    }
    setOpen2(false)
  };
  const handleChangePage = async (newPage) => {
    await dispatch(updateCurrentPage(newPage))
  };

  const handleUploadDesign = async () => {
    let newEditor = { front: "", back: "" }
    if (imageFront && imageBack) {
      newEditor.back = imageBack
      newEditor.front = imageFront
    } else if (imageBack) {
      newEditor.back = imageBack
      newEditor.front = itemEditor.design_link?.front
    } else if (imageFront) {
      newEditor.front = imageFront
      newEditor.back = itemEditor.design_link?.back
    }
    const res = await dispatch(uploadDesign({ urls: newEditor, id: itemEditor.id, link: itemEditor.link, design: itemEditor.design_link || [], user: exist ? "" : user, product_name: itemEditor.product_name, type, async_design: asyncDesign, images: itemEditor.images, alert }));
    if (res.payload.status == 0) {
      setOpen(false);
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
          }))

        });
      })
    }
    setExits(false)
    setAsyncDesign(false)
    setOpen(false)
  };

  const handleAddDesign = async (item) => {
    setImageBack("")
    setImageFront("")
    setDesignLink(item.design_link)
    setItemEditor(item)
    setOpen(true)
  }

  const handleChangeNote = async (e) => {
    const res = await dispatch(editOrder({ user, field: "note", string: !itemEditor.note ? `${user.replace("@gmail.com", "")}: ${note}` : `${itemEditor.note}\n${user.replace("@gmail.com", "")}:${note}`, id: itemEditor.id }))
    if (res.payload.status == 0) {

      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //     }))

      //   });
      // })
    }
    setOpen2(false)
  }

  const handleAddNote = (item) => {
    setNote("")
    setItemEditor(item)
    setOpen2(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleType = (value) => {
    setType(value);
    setImageBack("")
  };



  const handleDelete = async (index) => {
    let newDesignLink = designLink;
    if (index == "front") {
      newDesignLink = { front: "", back: itemEditor.design_link.back }
    } else {
      newDesignLink = { front: itemEditor.design_link.front, back: "" }
    }
    setDesignLink(newDesignLink)
    const res = await dispatch(deleteDesign({ design_link: newDesignLink, id: itemEditor.id, link: itemEditor.link, user }))
    if (res.payload.status == 0) {
      let newItemEditor = { ...itemEditor, design_link: newDesignLink };
      setItemEditor(newItemEditor)
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
          }))

        });
      })
    }
  }
  const onDropFront = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = btoa(event.target.result);
      setImageFront([base64String]);
    };

    reader.readAsBinaryString(file);
  };

  const handleReceiveDesign = async (items) => {
    await dispatch(receiveDesign({ id: items, user }))
    // await dispatch(
    //   getOrders({
    //     store: filterStore, order_status,
    //     startDate: moment(startDate).startOf("day").format("x"),
    //     endDate: moment(endDate).endOf("day").format("x"),
    //     currentPage, search
    //   })
    // );
    bcrypt.genSalt(10, (err, salt) => {
      bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
        const time = moment().unix()
        const res = await dispatch(getOrders({
          store: filterStore, order_status,
          startDate: moment(startDate).startOf("day").format("x"),
          endDate: moment(endDate).endOf("day").format("x"),
          currentPage,
          search,
          version: hash,
        }))

      });
    })
  }

  const onDropBack = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64String = btoa(event.target.result);
      setImageBack([base64String]);
    };

    reader.readAsBinaryString(file);
  };

  function getFirstWords(text) {
    if (text.length > 50) {
      return text.substring(0, 50) + "...";
    } else {
      return text;
    }
  }
  const { getRootProps: getRootPropsFront, getInputProps: getInputPropsFront } = useDropzone({
    onDrop: onDropFront, accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
  });
  const { getRootProps: getRootPropsBack, getInputProps: getInputPropsBack } = useDropzone({
    onDrop: onDropBack, accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
  });
  const capitalizeAndColorBeforeColon = (note) => {
    const lines = note.split('\n');
    return lines.map((line, index) => {
      const [beforeColon, afterColon] = line.split(':', 2);
      const capitalizedBeforeColon = beforeColon.trim().charAt(0).toUpperCase() + beforeColon.trim().slice(1);
      return (
        <div key={index}>
          <span style={{ color: 'red' }}>{capitalizedBeforeColon}</span>: {afterColon ? afterColon.trim() : ""}
        </div>
      );
    });
  };

  const renderPagination = Array.from({ length: data.totalPage }, (_, index) => (
    <MDPagination
      item
      key={index + 1}
      onClick={() => handleChangePage(index + 1)}
      active={currentPage === index + 1}
    >
      {index + 1}
    </MDPagination>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "date", accessor: "date", align: "left", width: "1%" },
                      { Header: "order id", accessor: "order_id", align: "left" },
                      { Header: "name", accessor: "items_name", align: "left" },
                      { Header: "variation", accessor: "variation", align: "left" },
                      { Header: "note", accessor: "note", align: "left" },
                      { Header: "design", accessor: "design", align: "left" },
                    ],
                    rows: data.data && data.data.length > 0 ? [...data.data.map((items, index) => ({
                      order_status: items.error_order,
                      id: items.order_id,
                      cancel: items.tracking,
                      itemEditor: itemEditor.order_id,
                      date: <>
                        {items.price ? <span className="caption-text" style={{ fontWeight: items?.price > 0 ? "bold" : "", color: items?.price > 0 ? "black" : "" }}>{moment(items.date).format("DD/MM")}</span> : ""}
                      </>,
                      order_id: <>
                        {
                          items.price || search ? <>
                            <p className="caption-text" style={{ color: "black", fontWeight: "bold" }}> {`${items.order_id}`}</p>
                            <p className="caption-text">{`${items.store_name}`}</p></> : ""}</>,
                      items_name: <a href={items.link} target="_blank" rel="noopener noreferrer" className="caption-text" style={{ fontWeight: items?.price > 0 ? "400" : "" }}>{getFirstWords(items.items_name)}</a>,
                      variation: <span style={{ fontSize: "12px" }}>{items.size.replace("Type: ", "")
                        .replace("Fabric Brand: ", "")
                        .replace("Style: ", "")
                        .replace("Size: ", "")
                        .replace("Product Sides Options: ", "")} - <span style={{ color: "red", fontWeight: "bold" }}>{items.color}</span></span>
                      ,
                      note: <div style={{ display: "flex", flexDirection: "column" }}>
                        {items.price && items.customer_note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}><span style={{
                          fontSize: "11px", color: "black", fontWeight: "bold"
                        }}>Customer Note: </span>{items.customer_note}</span> : null}
                        {items.note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          color: "black",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}>{capitalizeAndColorBeforeColon(items.note)}</span> : null}
                        {permission.includes("note") ? <MDBadge badgeContent={`Add Note`} onClick={() => handleAddNote(items)} style={{ cursor: "pointer" }} color="warning" variant="gradient" size="sm" /> : null}
                      </div>,
                      design: (
                        user == items.design || permission.includes("design_link") ?
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {items?.design_link ? <div>
                              {items?.design_link?.front ? items?.design_link?.front == "upload" ? <MDBadge badgeContent={`Uploading`} color="warning" variant="gradient" size="sm" /> : <a key={0} target="_blank" rel="noopener noreferrer" href={items?.design_link.front}>
                                <MDBadge badgeContent={`Front`} color="light" variant="gradient" size="sm" />
                              </a> : null}
                              {items?.design_link?.back ? <a key={1} target="_blank" rel="noopener noreferrer" href={items?.design_link.back}>
                                <MDBadge badgeContent={`Back`} color="light" variant="gradient" size="sm" />
                              </a> : null}
                            </div> : null}
                            {permission.includes("design_link") && !items.tracking && !items.status_fulfil ?
                              <MDBadge badgeContent={`Edit`} color="error" variant="gradient" size="sm" onClick={() => handleAddDesign(items)} style={{ cursor: "pointer" }} />
                              : null}
                          </div> :
                          !items.design && !items.design_link && !items.tracking && !items.status_fulfil && permission.includes("design_link") ? <div>
                            <MDBadge badgeContent={`Receive`} color="alert" variant="gradient" size="sm" style={{ cursor: "pointer" }} onClick={() => handleReceiveDesign(items.id)} />
                          </div>
                            : !items.tracking && !items.status_fulfil ?
                              <div> {items?.design_link ? <div>
                                {items?.design_link?.front ? items?.design_link?.front == "upload" ? <MDBadge badgeContent={`Uploading`} color="warning" variant="gradient" size="sm" /> : <a key={0} target="_blank" rel="noopener noreferrer" href={items?.design_link.front}>
                                  <MDBadge badgeContent={`Front`} color="light" variant="gradient" size="sm" />
                                </a> : null}
                                {items?.design_link?.back ? <a key={1} target="_blank" rel="noopener noreferrer" href={items?.design_link.back}>
                                  <MDBadge badgeContent={`Back`} color="light" variant="gradient" size="sm" />
                                </a> : null}
                              </div> : <span className="caption-text" style={{ color: "orange" }}>{items?.design ? `***${items?.design.replace("@gmail.com", "").substring(items?.design.replace("@gmail.com", "").length - 7)}` : ""}</span>}</div>
                              : <div>{items?.design_link?.front ? items?.design_link?.front == "upload" ? <MDBadge badgeContent={`Uploading`} color="warning" variant="gradient" size="sm" /> : <a key={0} target="_blank" rel="noopener noreferrer" href={items?.design_link.front}>
                                <MDBadge badgeContent={`Front`} color="light" variant="gradient" size="sm" />
                              </a> : null}
                                {items?.design_link?.back ? items?.design_link?.back == "upload" ? <MDBadge badgeContent={`Uploading`} color="warning" variant="gradient" size="sm" /> : <a key={1} target="_blank" rel="noopener noreferrer" href={items?.design_link.back}>
                                  <MDBadge badgeContent={`Back`} color="light" variant="gradient" size="sm" />
                                </a> : null}</div>
                      ),
                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  padding="5px"
                >
                  <MDPagination
                    variant="gradient"
                    color="info"
                  >
                    {currentPage > 1 && data.totalPage > 1 && (
                      <MDPagination item onClick={() => handleChangePage(currentPage - 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    )}
                    {renderPagination}
                    {currentPage < data.totalPage && (
                      <MDPagination item onClick={() => handleChangePage(currentPage + 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    )}
                  </MDPagination>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Design Information</DialogTitle>
        <DialogContent>
          <DataTable width={800}
            table={{
              columns: type ? [
                { Header: "image", accessor: "image", align: "center" },
              ] : [
                { Header: "image", accessor: "image", align: "center" },
                { Header: "image1", accessor: "image1", align: "center" },
              ],
              rows: [
                {
                  image:
                    <MDBox style={{ position: "relative" }}>
                      <div className="transparent-background"><img src={itemEditor.design_link?.front} width={300} /> </div>
                      {permission.includes("design_link") && itemEditor.design_link?.front && !itemEditor.tracking ?
                        <HighlightOffIcon style={{ color: "red", position: "absolute", top: 0, right: 0 }} fontSize="large" onClick={() => handleDelete("front")} />
                        : null}
                      {permission.includes("design_link") && !itemEditor.tracking ?
                        <div style={{ textAlign: "center" }} >
                          <div {...getRootPropsFront()} style={dropzoneStyles}>
                            <input {...getInputPropsFront()} />
                            <h5 style={textStyles}>
                              Upload Front Side
                            </h5>
                          </div>
                          {imageFront && (
                            <img
                              src={`data:image/png;base64,${imageFront}`}
                              alt={`Uploaded`}
                              style={{ maxWidth: 100, maxHeight: 100 }}
                            />
                          )}
                          <div style={{ marginTop: 10, display: "flex", flexDirection: "row" }}>
                            <TextField
                              type="text"
                              placeholder="Enter image link"
                              value={frontImageLink}
                              onChange={(e) => setFrontImageLink(e.target.value)}
                              style={{ width: "80%", backgroundColor: "white", borderRadius: "0.5rem" }}
                            />
                            <MDBadge badgeContent={`LOAD`} color="primary" variant="gradient" size="sm" style={{ cursor: "pointer" }} onClick={() => handleLinkSubmit("front", frontImageLink)} />
                          </div>
                        </div>
                        : null}
                    </MDBox>,
                  image1: !type ? <MDBox style={{ position: "relative" }}>
                    <div className="transparent-background"><img src={itemEditor.design_link?.back} width={300} /></div>
                    {permission.includes("design_link") && itemEditor.design_link?.back && !itemEditor.tracking ?
                      <HighlightOffIcon style={{ color: "red", position: "absolute", top: 0, right: 0 }} fontSize="large" onClick={() => handleDelete("back")} />
                      : null}
                    {permission.includes("design_link") && !itemEditor.tracking ?
                      <div style={{ textAlign: "center" }} >
                        <div {...getRootPropsBack()} style={dropzoneStyles}>
                          <input {...getInputPropsBack()} />
                          <h5 style={textStyles}>
                            Upload Back Side
                          </h5>
                        </div>
                        {imageBack && (
                          <img
                            src={`data:image/png;base64,${imageBack}`}
                            alt={`Uploaded`}
                            style={{ maxWidth: 100, maxHeight: 100 }}
                          />
                        )}
                        <div style={{ marginTop: 10, display: "flex", flexDirection: "row" }}>
                          <TextField
                            type="text"
                            placeholder="Enter image link"
                            value={backImageLink}
                            onChange={(e) => setBackImageLink(e.target.value)}
                            style={{ width: "80%", backgroundColor: "white", borderRadius: "0.5rem" }}
                          />
                          <MDBadge badgeContent={`LOAD`} color="primary" variant="gradient" size="sm" style={{ cursor: "pointer" }} onClick={() => handleLinkSubmit("back", backImageLink)} />
                        </div>
                      </div>
                      : null}
                  </MDBox> : null
                }
              ]
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
          <DialogActions>
            <FormControlLabel
              sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
              control={<Switch checked={alert} onChange={(e) => setAlert(e.target.checked)} />}
              label="Large 2D Image"
            />
            <FormControlLabel
              sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
              control={<Switch checked={type} onChange={(e) => handleType(e.target.checked)} />}
              label="3D"
            />
            <FormControlLabel
              sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
              control={<Switch checked={exist} onChange={(e) => setExits(e.target.checked)} />}
              label="Reup Design"
            />
            <FormControlLabel
              sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
              control={<Switch checked={asyncDesign} onChange={(e) => setAsyncDesign(e.target.checked)} />}
              label="Asynchronous Design"
            />
            {imageBack || imageFront ? <MDBadge badgeContent={`Upload Design`} color="success" variant="gradient" size="lg" style={{ cursor: "pointer" }} onClick={() => handleUploadDesign()}>
            </MDBadge> : null}

          </DialogActions>
        </DialogContent>
      </Dialog>
      <NoteDialog open2={open2} handleDeleteNote={handleDeleteNote} handleClose2={handleClose2} note2={note} note={itemEditor.note} user={user.replace("@gmail.com", "")} setNote={setNote} handleChangeNote={handleChangeNote} />
      <Footer />
    </DashboardLayout >
  );
}

const dropzoneStyles = {
  border: '2px dashed green',
  borderRadius: '4px',
  padding: '10px',
  marginTop: "5px",
  textAlign: 'center',
  cursor: 'pointer',
};

const textStyles = {
  color: '#888888',
};

export default Tables;
