/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CachedIcon from '@mui/icons-material/Cached';
// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import * as XLSX from 'xlsx';

// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// EGEAD Fulfilment POD example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { styled } from '@mui/material/styles';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// EGEAD Fulfilment POD context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { updateStartDate, updateEndDate } from "features/slices";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { setSearch } from "features/slices";
import { updateSearch } from "features/slices";
import { getOrders } from "features/slices";
import moment from "moment";
import { getDesign, updateStore } from "features/slices";
import { updateUTC } from "features/slices";
import { exportOrder } from "features/slices";
import { NotificationManager } from "react-notifications";
import { updateCurrentPage } from "features/slices";
import { getDashboard } from "features/slices";
import { getStore, getOrdersSearch } from "features/slices";
import { updateOrderStatus } from "features/slices";
import { getNewOrder } from "features/slices";
import { logout } from "features/slices";
import { updateType } from "features/slices";
import bcrypt from 'bcryptjs';
import { jwtDecode } from 'jwt-decode';
import { getUser } from "features/slices";
import { reloadStore } from "features/slices";
const CustomTextField = styled(TextField)(({ theme, search }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: search ? 'red' : '', // Màu border khi có search
    },
    '&:hover fieldset': {
      borderColor: search ? 'red' : '', // Màu border khi hover
    },
    '&.Mui-focused fieldset': {
      borderColor: search ? 'red' : '', // Màu border khi focus
    },
  },
}));

function DashboardNavbar({ absolute, light, isMini }) {
  const dispatch2 = useDispatch();
  const user = useSelector((state) => state.user.user);
  const permission = useSelector((state) => state.user.permission || []);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const search = useSelector((state) => state.user.search);
  const currentPage = useSelector((state) => state.user.currentPage) || 1;
  const order_status = useSelector((state) => state.user.order_status);
  const filterStore = useSelector((state) => state.user.filterStore);
  const type = useSelector((state) => state.user.type);
  const store = useSelector((state) => state.user.store);
  const [navbarType, setNavbarType] = useState("relative");
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const handleChangeOrderStatus = async (event) => {
    await dispatch2(updateOrderStatus(event.target.value))

  };
  const handleGetOrder = async () => {
    await dispatch2(getNewOrder());

    bcrypt.genSalt(10, (err, salt) => {
      bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
        const time = moment().unix()
        const res = await dispatch2(getOrders({
          store: filterStore, order_status,
          startDate: moment(startDate).startOf("day").format("x"),
          endDate: moment(endDate).endOf("day").format("x"),
          currentPage,
          search,
          version: hash,
          type
        }))

      });
    })
    bcrypt.genSalt(10, (err, salt) => {
      bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
        const time = moment().unix()
        const res = await dispatch2(getDashboard({
          startDate: moment(startDate).startOf("day").format("x"),
          endDate: moment(endDate).endOf("day").format("x"), store: filterStore, order_status, version: hash,
        }))
      });
    })

  }
  function modifyUrlString(inputString) {
    if (inputString.includes("/cdn-cgi")) {
      const regex = /https:\/\//g;
      const matches = inputString.match(regex);
      if (!matches) {
        return inputString;
      } else if (matches.length === 1) {
        return 'https://' + inputString.split('https://')[1]
      } else if (matches.length === 2) {
        const parts = inputString.split('https://');
        return 'https://' + parts[2];
      } else {
        return inputString;
      }
    } else {
      return inputString;
    }
  }
  const handleExport = async () => {
    try {
      const newData = []
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch2(exportOrder({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            search,
            version: hash,
          }))
          newData.push(["Date", "Order ID", "Store Name", "Shield", "Basecost", "Price", "LineShip", "Tracking", "Items Name", "Size", "Custom", "Color", "SKU", "Fulfil", "Link", "Link Image", "Quantity", "Name Customer", "Address 1", "Address 2", "City", "State", "Zip Code", "Address", "Status Fulfil", "Phone", "Note", "Shipping Method", "Email", "Front Design", "Back Design", "Send message customer", "Deli", "Nhung Check", "Mai Check"])
          if (res.payload.data) {
            for (let _data of res.payload.data) {
              if (_data.size.includes(" - Custom Jersey Name") && _data.size.includes(" - Custom Jersey Number")) {
                const splitParts = _data.size.split(' - Custom Jersey Name');
                newData.push([moment(_data.date).format("DD/MM/YYYY"), _data.order_id, _data.store_name, _data.shield, _data.basecost, _data.price, _data.lineship, _data.tracking, _data.items_name, splitParts.length > 1 ? splitParts[0] : _data.size, splitParts.length > 1 ? 'Custom Jersey Name' + splitParts[1] : "", _data.color, _data.sku, _data.fulfil, _data.link, modifyUrlString(_data.images), _data.quantity, _data.customer_name, _data.address?.[0], _data.address?.[1], _data.address?.[2], _data.address?.[3], _data.address?.[4], _data.address.join(", "), _data.status_fulfil, _data.phone, _data.note, _data.shipping_method, _data.email, _data?.design_link?.front, _data?.design_link?.back, _data.is_message_customer, _data.deli, _data.nhung, _data.mai])
              } else if (_data.size.includes(" - Custom Jersey Name")) {
                const splitParts = _data.size.split(' - Custom Jersey Name');
                newData.push([moment(_data.date).format("DD/MM/YYYY"), _data.order_id, _data.store_name, _data.shield, _data.basecost, _data.price, _data.lineship, _data.tracking, _data.items_name, splitParts.length > 1 ? splitParts[0] : _data.size, splitParts.length > 1 ? 'Custom Jersey Name' + splitParts[1] : "", _data.color, _data.sku, _data.fulfil, _data.link, modifyUrlString(_data.images), _data.quantity, _data.customer_name, _data.address?.[0], _data.address?.[1], _data.address?.[2], _data.address?.[3], _data.address?.[4], _data.address.join(", "), _data.status_fulfil, _data.phone, _data.note, _data.shipping_method, _data.email, _data?.design_link?.front, _data?.design_link?.back, _data.is_message_customer, _data.deli, _data.nhung, _data.mai])
              } else if (_data.size.includes(" - Custom Jersey Number")) {
                const splitParts = _data.size.split(' - Custom Jersey Number');
                newData.push([moment(_data.date).format("DD/MM/YYYY"), _data.order_id, _data.store_name, _data.shield, _data.basecost, _data.price, _data.lineship, _data.tracking, _data.items_name, splitParts.length > 1 ? splitParts[0] : _data.size, splitParts.length > 1 ? 'Custom Jersey Number' + splitParts[1] : "", _data.color, _data.sku, _data.fulfil, _data.link, modifyUrlString(_data.images), _data.quantity, _data.customer_name, _data.address?.[0], _data.address?.[1], _data.address?.[2], _data.address?.[3], _data.address?.[4], _data.address.join(", "), _data.status_fulfil, _data.phone, _data.note, _data.shipping_method, _data.email, _data?.design_link?.front, _data?.design_link?.back, _data.is_message_customer, _data.deli, _data.nhung, _data.mai])
              } else {
                newData.push([moment(_data.date).format("DD/MM/YYYY"), _data.order_id, _data.store_name, _data.shield, _data.basecost, _data.price, _data.lineship, _data.tracking, _data.items_name, _data.size, "", _data.color, _data.sku, _data.fulfil, _data.link, modifyUrlString(_data.images), _data.quantity, _data.customer_name, _data.address?.[0], _data.address?.[1], _data.address?.[2], _data.address?.[3], _data.address?.[4], _data.address.join(", "), _data.status_fulfil, _data.phone, _data.note, _data.shipping_method, _data.email, _data?.design_link?.front, _data?.design_link?.back, _data.is_message_customer, _data.deli, _data.nhung, _data.mai])
              }
            }
            const ws = XLSX.utils.aoa_to_sheet(newData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `Export Order ${moment(startDate).format("DD/MM")} - ${moment(endDate).format("DD/MM")}.csv`);
          }
        });
      })

    } catch (error) {
      NotificationManager.error(error.message, 'Error', 1000);
    }

  }
  const handleChange = async (event) => {
    await dispatch2(updateStore(event.target.value))
    bcrypt.genSalt(10, (err, salt) => {
      bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
        const time = moment().unix()
        const res = await dispatch2(getOrders({
          store: filterStore, order_status,
          startDate: moment(startDate).startOf("day").format("x"),
          endDate: moment(endDate).endOf("day").format("x"),
          currentPage,
          search,
          version: hash,
          type
        }))

      });
    })
  };
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeDate = (type, value) => {
    if (type == "startDate") {
      dispatch2(updateStartDate(value));
    } else {
      dispatch2(updateEndDate(value));
    }
  };
  const handleToggle = async (event) => {
    dispatch2(updateType(event.target.checked))
  };
  const handleSetSearch = (value) => {
    dispatch2(updateSearch(value));
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (user != "htntmmiua@gmail.com" && user != "Thaonguyendlak3005@gmail.com") {
        if (location.pathname == "/library") {
          dispatch2(getDesign({
            search
          }));
        } else {
          // dispatch2(getOrders({
          //   store: filterStore, order_status,
          //   startDate: moment(startDate).startOf("day").format("x"),
          //   endDate: moment(endDate).endOf("day").format("x"), currentPage: 1, search, type
          // }));
          bcrypt.genSalt(10, (err, salt) => {
            bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
              const time = moment().unix()
              const res = await dispatch2(getOrders({
                store: filterStore, order_status,
                startDate: moment(startDate).startOf("day").format("x"),
                endDate: moment(endDate).endOf("day").format("x"),
                currentPage,
                search,
                version: hash,
                type
              }))

            });
          })
        }
      } else {
        dispatch2(getOrdersSearch({
          store: filterStore, order_status,
          startDate: moment(startDate).startOf("day").format("x"),
          endDate: moment(endDate).endOf("day").format("x"), currentPage: 1, search
        }));
      }
    }
  };

  useEffect(() => {
    // if (user && user != "tuanah252@gmail.com") {
    if (user) {
      const fetchOrders = async () => {
        if (user != "htntmmiua@gmail.com" && user != "Thaonguyendlak3005@gmail.com") {
          if (location.pathname !== "/error" && location.pathname !== "/dashboard") {
            bcrypt.genSalt(10, (err, salt) => {
              bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
                const time = moment().unix()
                const res = await dispatch2(getOrders({
                  store: filterStore, order_status,
                  startDate: moment(startDate).startOf("day").format("x"),
                  endDate: moment(endDate).endOf("day").format("x"),
                  currentPage,
                  search,
                  version: hash,
                  type
                }))

              });
            })
            user == "luoiquantam2607@gmail.com" ? await dispatch2(
              getUser()
            ) : null

          } else if (location.pathname == "/dashboard" || location.pathname == "/store") {
            bcrypt.genSalt(10, (err, salt) => {
              bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
                const time = moment().unix()
                const res = await dispatch2(getDashboard({
                  startDate: moment(startDate).startOf("day").format("x"),
                  endDate: moment(endDate).endOf("day").format("x"), store: filterStore, order_status, version: hash, type
                }))
              });
            })
            user == "tuanah252@gmail.com" || !permission || permission.length == 0 ? await dispatch2(reloadStore()) : await dispatch2(
              getStore()
            )
          }
        }
      };
      fetchOrders();
    } else {
      dispatch2(logout())
      navigate('/authentication/log-out');
    }
  }, [startDate, endDate, currentPage, filterStore, order_status, type]);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} >
        <MDBox
          sx={(theme) => navbarRow(theme, { isMini })}
          mb={{ xs: 1, md: 0 }}
        >
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>

          <div className="form-group">
            <MDTypography variant="h6" style={{ marginRight: 5 }}>From </MDTypography>
            <DatePicker
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              id="start-date"
              autoComplete="off"
              selected={startDate}
              className="custom-datepicker"
              onChange={(e) => handleChangeDate("startDate", e)}
            />
          </div>
          <div className="form-group">
            <MDTypography variant="h6" style={{ marginRight: 5, marginLeft: 5 }}>To </MDTypography>
            <DatePicker
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              id="start-date"
              autoComplete="off"
              selected={endDate}
              className="custom-datepicker"
              onChange={(e) => handleChangeDate("endDate", e)}
            />
          </div>

        </MDBox>
        <MDBox sx={{ display: "flex", flexDirection: "row", width: "70%", alignItems: "center" }}>
          <FormControl sx={{ margin: 1, width: "20%" }}>
            <InputLabel>Store</InputLabel>
            <Select
              search={filterStore}
              value={filterStore}
              onChange={handleChange}
              label="Store"
              sx={{ background: "white", height: 37 }}
            >
              <MenuItem value="All Store">All Store</MenuItem>
              {store.map(([store, isActive]) => (
                <MenuItem key={store} value={store}>
                  {store}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ margin: 1, width: "15%" }}>
            <InputLabel>Fulfil Status</InputLabel>
            <Select
              label="Fulfil Status"
              value={order_status}
              sx={{ height: 37, background: "white" }}
              onChange={(e) => handleChangeOrderStatus(e)}
            >
              {[{ value: "", name: "None" }, { value: "not_fulfilled", name: "Not Fulfilled" }, { value: "fulfilled", name: "Fulfilled" }, { value: "refund", name: "Refund" }, { value: "tracking", name: "No Tracking" }, { value: "basecost", name: "No Basecost" }, { value: "deli", name: "Undelivered" }].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CustomTextField
            className='inputText'
            style={{ width: "100%", background: "white" }}
            placeholder="Search"
            value={search}
            onChange={(e) => handleSetSearch(e.target.value)}
            onKeyPress={handleKeyPress}
            search={search} // Truyền giá trị search vào
          />
        </MDBox>
        <Switch checked={type} onChange={handleToggle} />
        {user != "htntmmiua@gmail.com" && user != "Thaonguyendlak3005@gmail.com" && user != "maitrieu9820@gmail.com" ?
          <MDButton sx={{ margin: 1 }} color={"dark"} onClick={handleExport}>
            Export<FileDownloadIcon
              style={{ cursor: "pointer" }}
              fontSize="large"
              color={"warning"}

            /></MDButton>
          : null}
        {user != "htntmmiua@gmail.com" && user != "Thaonguyendlak3005@gmail.com" && user != "maitrieu9820@gmail.com" ?
          <MDButton sx={{ margin: 1 }} color={"dark"} onClick={handleGetOrder}>
            Order<CachedIcon
              style={{ cursor: "pointer" }}
              fontSize="large"
              color={"warning"}

            /></MDButton>
          : null}
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/authentication/log-out">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>login</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}

      </Toolbar>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
