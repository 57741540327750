
import Grid from "@mui/material/Grid";

// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data

// Dashboard components
import { Switch } from '@mui/material';
import MixedChart from "examples/Charts/MixedChart";
import moment from "moment";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.user.dashboard);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const filterStore = useSelector((state) => state.user.filterStore);
  const order_status = useSelector((state) => state.user.order_status);
  const currentPage = useSelector((state) => state.user.currentPage);
  const [type, setType] = useState(true);
  const handleToggle = (event) => {
    setType(event.target.checked);
  };
  const labels = dashboard?.sku ? Object.keys(dashboard?.sku.total) : [];
  const data = dashboard?.sku ? Object.values(dashboard?.sku.total) : [];
  const chartData = {
    labels: labels,
    datasets: {
      label: "SKU",
      data: data
    }
  };

  const labels2 = dashboard?.sku ? Object.keys(dashboard?.statistics) : [];
  const data2 = dashboard?.sku ? Object.values(dashboard?.statistics) : [];

  // Cấu trúc dữ liệu cho biểu đồ
  const chartData2 = {
    labels: labels2,
    datasets: {
      label: "Design",
      data: data2
    }
  };


  const data4 = dashboard?.storeRevenue ? Object.values(dashboard?.storeRevenue) : [];
  const labels3 = dashboard?.storeStatistics ? Object.keys(dashboard?.storeStatistics) : [];
  const data3 = dashboard?.storeStatistics ? Object.values(dashboard?.storeStatistics) : [];
  const chartData3 = {
    labels: labels3,
    datasets: [
      {
        label: "Order",
        data: data3
      }, {
        label: "Revenue",
        data: data4
      }
    ],
  };
  const processDataByDate = (storeStatistics) => {
    const dateMap = {};

    // Loop through each store's statistics
    Object.keys(storeStatistics).forEach(storeName => {
      const dates = storeStatistics[storeName];

      // Loop through each date for the store
      Object.keys(dates).forEach(date => {
        if (!dateMap[date]) {
          dateMap[date] = { date };
        }
        dateMap[date][storeName] = {
          orders: dates[date].order,
          totalPrice: dates[date].totalPrice
        };
      });
    });

    // Convert dateMap to array of rows
    return Object.values(dateMap);
  };

  const DynamicTable = () => {
    const dates = Object.keys(dashboard?.storeStatistics[Object.keys(dashboard?.storeStatistics)[0]]); // Get the list of dates
    const stores = Object.keys(dashboard?.storeStatistics).sort();; // Get the list of stores

    return (
      <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', overflow: 'hidden', width: '100%', textAlign: "right", marginLeft: 24 }}>
        <div >
          <Switch
            checked={type}
            onChange={handleToggle}
            style={{ marginRight: '8px' }}
          />
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>* 0.9 Revenue</span>
        </div>

        <div style={{ overflow: 'auto' }}>
          <table className="google-sheets-table">
            <thead>
              <tr>
                <th >Date</th>
                {stores.map((store) => (
                  <th colSpan="5" key={store}>{store}</th> // Store name spans 5 columns
                ))}
              </tr>
              <tr >
                <th ></th>
                {stores.map((store) => (
                  <>
                    <th style={{ backgroundColor: "#ff9900" }}>Revenue</th>
                    <th style={{ backgroundColor: "#ff9900" }}>Basecost</th>
                    {/* <th style={{ backgroundColor: "#ff9900" }}>Basecost/Revenue</th> */}
                    <th style={{ backgroundColor: "#ff9900" }}>Orders</th>
                    <th style={{ backgroundColor: "#ff9900" }}>Ads</th>
                    <th style={{ backgroundColor: "#ff9900" }}>Profit</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {dates.map((date) => (
                <tr key={date}>
                  <td className="sticky-col">{date}</td>
                  {stores.map((store) => {
                    const totalPrice = dashboard?.storeStatistics[store]?.[date]?.totalPrice ? type ? dashboard?.storeStatistics[store]?.[date]?.totalPrice * 0.9 : dashboard?.storeStatistics[store]?.[date]?.totalPrice : 0;
                    const baseCost = dashboard?.storeStatistics[store]?.[date]?.basecost || 0; // Base cost is currently 0
                    const orders = dashboard?.storeStatistics[store]?.[date]?.order || 0;
                    const priceAds = 0; // Price ads is currently 0
                    const profit = totalPrice - baseCost - priceAds;

                    return (
                      <>
                        <td style={{ backgroundColor: "#d9ead3" }}>{totalPrice ? totalPrice.toFixed(2) : 0}</td>
                        <td>{baseCost ? baseCost.toFixed(2) : 0}</td>
                        {/* <td>{baseCost && totalPrice ? `${(100 / totalPrice * baseCost).toFixed(2)}%` : 0}</td> */}
                        <td style={{ color: "#1869ff" }}>{orders}</td>
                        <td>{priceAds ? priceAds.toFixed(2) : 0}</td>
                        <td>{profit.toFixed(2)}</td>
                      </>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="store"
                title="Orders"
                count={dashboard?.order}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="money"
                title="Revenue"
                count={`$${dashboard?.price}`}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="done"
                title="Basecost"
                count={`$${dashboard?.basecost}`}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Success/Cancel"
                count={`${dashboard?.order}/${dashboard?.cancel}`}
                percentage={{
                  color: "error",
                  amount: `Cancel rate ${(dashboard?.cancel / dashboard?.order * 100).toFixed(2)}%`,
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={2} mb={7}>
            {dashboard?.storeStatistics && Object.keys(dashboard?.storeStatistics).length ? Object.keys(dashboard?.storeStatistics).map((items) => {
              let chartDataMulti = dashboard?.storeStatistics[items]
              const labelsItems = chartDataMulti ? Object.keys(chartDataMulti) : [];
              let dataItems = {}
              let dataItems2 = {}
              let dataItems3 = {}
              for (let i of Object.keys(chartDataMulti)) {
                dataItems = { ...dataItems, [i]: chartDataMulti[i].order }
                dataItems2 = { ...dataItems2, [i]: chartDataMulti[i].totalPrice }
                dataItems3 = { ...dataItems3, [i]: chartDataMulti[i].basecost }
              }
              const chartDataItems = {
                labels: labelsItems,
                datasets: [
                  {
                    label: "Order",
                    chartType: "thin-bar",
                    color: "primary",
                    data: dataItems
                  }, {
                    chartType: "gradient-line",
                    color: "info",
                    label: "Revenue",
                    data: dataItems2
                  }, {
                    chartType: "thin-bar",
                    color: "warning",
                    label: "Basecost",
                    data: dataItems3
                  }
                ],
              };
              return (
                <Grid item xs={3} md={3} lg={3} key={items} textAlign={"center"}>
                  <MDBox >
                    <MixedChart
                      height="300px"
                      color="dark"
                      title={items}
                      chart={chartDataItems} />
                  </MDBox>
                </Grid>
              )
            }
            ) : null}

            {/* {Object.keys(dashboard?.storeStatistics).length ? <DynamicTable /> : null} */}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Another SKUs"
                  description={<span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    {dashboard?.sku.other.length > 0 ? dashboard?.sku.other.map((item, index) => (
                      <span className="caption-text" style={{ marginLeft: 5, fontWeight: "400" }} key={index}>
                        <span className="caption-text" style={{ fontWeight: "bold", color: "green" }}>👉 </span>
                        {item}
                      </span>
                    )) : null}
                  </span>}
                  date={`${moment(startDate).startOf("day").format("DD/MM/YYYY")} - ${moment(endDate).endOf("day").format("DD/MM/YYYY")}`}
                  chart={chartData}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Total Design"
                  // description={<span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                  //   {dashboard?.sku.other.length > 0 ? dashboard?.sku.other.map((item, index) => (
                  //     <span className="caption-text" style={{ marginLeft: 5, fontWeight: "400" }} key={index}>
                  //       <span className="caption-text" style={{ fontWeight: "bold", color: "green" }}>👉 </span>
                  //       {item}
                  //     </span>
                  //   )) : null}
                  // </span>}
                  date={`${moment(startDate).startOf("day").format("DD/MM/YYYY")} - ${moment(endDate).endOf("day").format("DD/MM/YYYY")}`}
                  chart={chartData2}
                />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
