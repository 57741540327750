/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import BorderColorIcon from '@mui/icons-material/BorderColor';
// Data
import { useState } from "react";

import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import MDBadge from "components/MDBadge";
const permissontext = {
  "Full Access": [
    "id",
    "date",
    "error_order",
    "order_id",
    "store_name",
    "shield",
    "status",
    "type",
    "basecost",
    "price",
    "lineship",
    "tracking",
    "items_name",
    "size",
    "color",
    "sku",
    "custom_name",
    "custom_number",
    "fulfil",
    "link",
    "quantity",
    "customer_name",
    "address",
    "status_fulfil",
    "phone",
    "customer_note",
    "shipping_method",
    "email",
    "is_message_customer",
    "is_deli",
    "deli",
    "mai",
    "nhung",
    "note",
    "design_link"
  ],
  "Support": [
    "is_message_customer",
    "error_order",
    "tracking",
    "mai",
    "deli",
    "note",
    "price",
    "store_name"
  ],
  "Design": [
    "note",
    "design_link"
  ],
  "Fulfilment": [
    "tracking",
    "note",
    "basecost",
    "fulfil",
    "status_fulfil",
    "design_link",
    "mai",
    "deli",
    "store_name"
  ],
  "Shield": [
    "store_name",
    "nhung",
    "note"
  ]
  , "View Only": []
}

function Feed() {
  const dispatch = useDispatch();
  let datauser = useSelector((state) => state.user.datauser);
  const [domain, setDomain] = useState('');

  function checkPermissions(items, permissontext, row) {
    for (const key in permissontext) {
      if (
        items?.permission?.length === permissontext[key].length &&
        permissontext[key].every(permission => items?.permission?.includes(permission))
      ) {
        if (row == key) {
          return <MDBadge badgeContent={key} style={{ cursor: "pointer" }} color={key == "Fulfilment" ? "light" : key == "Shield" ? "dark" : key == "Design" ? "info" : key == "Support" ? "warning" : key == "View Only" ? "error" : "success"} variant="gradient" size="md" max={9999999999999999999999999999999999999999999999999999999999} />;
        } else {
          return null
        }
      }
    }
    return null;

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "index", accessor: "index", align: "left", width: "1%" },
                      { Header: "mail", accessor: "mail", align: "left", width: "1%" },
                      { Header: "updatedAt", accessor: "updatedAt", align: "left" },
                      { Header: "Full Acess", accessor: "permission1", align: "center" },
                      { Header: "Fulfilment", accessor: "permission2", align: "center" },
                      { Header: "Design", accessor: "permission3", align: "center" },
                      { Header: "Support", accessor: "permission4", align: "center" },
                      { Header: "Shield", accessor: "permission5", align: "center" },
                      { Header: "View Only", accessor: "permission6", align: "center" },
                      { Header: "edit", accessor: "edit", align: "center" },
                    ],
                    rows: datauser && datauser.length > 0 ? [...datauser].sort((a, b) => {
                      const lengthA = a.permission?.length || 0;
                      const lengthB = b.permission?.length || 0;
                      return lengthB - lengthA;
                    }).map((items, index) => ({
                      id: items.id,
                      index: index + 1,
                      mail: items.mail.replace("@gmail.com", ""),
                      updatedAt: moment(items.updatedAt).format("HH:mm DD/MM/YYYY"),
                      permission1: checkPermissions(items, permissontext, "Full Access"),
                      permission2: checkPermissions(items, permissontext, "Fulfilment"),
                      permission3: checkPermissions(items, permissontext, "Design"),
                      permission4: checkPermissions(items, permissontext, "Support"),
                      permission5: checkPermissions(items, permissontext, "Shield"),
                      permission6: checkPermissions(items, permissontext, "View Only"),
                      edit: <BorderColorIcon style={{ color: "black", fontSize: "larger", margin: 2, cursor: "pointer" }} onClick={() => handleEdit(items)} />,

                    })
                    ) : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Feed;
