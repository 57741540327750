const teecraft = [
    {
        "category": "Unisex T-Shirt",
        "variations": [
            {
                "sku": "TCG5ASHMS1",
                "size": "M",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASHLS1",
                "size": "L",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASHXLS1",
                "size": "XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASH2XLS1",
                "size": "2XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASH3XLS1",
                "size": "3XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASH4XLS1",
                "size": "4XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASH5XLS1",
                "size": "5XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLSS1",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLMS1",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLLS1",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLXLS1",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BL2XLS1",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BL3XLS1",
                "size": "3XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BL4XLS1",
                "size": "4XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BL5XLS1",
                "size": "5XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHASS1",
                "size": "S",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHAMS1",
                "size": "M",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHALS1",
                "size": "L",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHAXLS1",
                "size": "XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHA2XLS1",
                "size": "2XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHA3XLS1",
                "size": "3XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHA4XLS1",
                "size": "4XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CHA5XLS1",
                "size": "5XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAHSS1",
                "size": "S",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAHMS1",
                "size": "M",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAHLS1",
                "size": "L",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAHXLS1",
                "size": "XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAH2XLS1",
                "size": "2XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAH3XLS1",
                "size": "3XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAH4XLS1",
                "size": "4XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAH5XLS1",
                "size": "5XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOGSS1",
                "size": "S",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOGMS1",
                "size": "M",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOGLS1",
                "size": "L",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOGXLS1",
                "size": "XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOG2XLS1",
                "size": "2XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOG3XLS1",
                "size": "3XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOG4XLS1",
                "size": "4XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5FOG5XLS1",
                "size": "5XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBUSS1",
                "size": "S",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBUMS1",
                "size": "M",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBULS1",
                "size": "L",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBUXLS1",
                "size": "XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBU2XLS1",
                "size": "2XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBU3XLS1",
                "size": "3XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBU4XLS1",
                "size": "4XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LBU5XLS1",
                "size": "5XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPISS1",
                "size": "S",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPIMS1",
                "size": "M",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPILS1",
                "size": "L",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPIXLS1",
                "size": "XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPI2XLS1",
                "size": "2XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPI3XLS1",
                "size": "3XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPI4XLS1",
                "size": "4XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LPI5XLS1",
                "size": "5XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MROSS1",
                "size": "S",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MROMS1",
                "size": "M",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MROLS1",
                "size": "L",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MROXLS1",
                "size": "XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MRO2XLS1",
                "size": "2XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MRO3XLS1",
                "size": "3XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MRO4XLS1",
                "size": "4XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MRO5XLS1",
                "size": "5XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILISS1",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILIMS1",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILILS1",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILIXLS1",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILI2XLS1",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILI3XLS1",
                "size": "3XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILI4XLS1",
                "size": "4XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MILI5XLS1",
                "size": "5XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAVSS1",
                "size": "S",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAVMS1",
                "size": "M",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAVLS1",
                "size": "L",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAVXLS1",
                "size": "XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAV2XLS1",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAV3XLS1",
                "size": "3XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAV4XLS1",
                "size": "4XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAV5XLS1",
                "size": "5XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NATSS1",
                "size": "S",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NATMS1",
                "size": "M",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NATLS1",
                "size": "L",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NATXLS1",
                "size": "XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAT2XLS1",
                "size": "2XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NAT3XLS1",
                "size": "3XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PURSS1",
                "size": "S",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PURMS1",
                "size": "M",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PURLS1",
                "size": "L",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PURXLS1",
                "size": "XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PUR2XLS1",
                "size": "2XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PUR3XLS1",
                "size": "3XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PUR4XLS1",
                "size": "4XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5PUR5XLS1",
                "size": "5XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5REDSS1",
                "size": "S",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5REDMS1",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5REDLS1",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5REDXLS1",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RED2XLS1",
                "size": "2XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RED3XLS1",
                "size": "3XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RED4XLS1",
                "size": "4XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RED5XLS1",
                "size": "5XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROYSS1",
                "size": "S",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROYMS1",
                "size": "M",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROYLS1",
                "size": "L",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROYXLS1",
                "size": "XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROY2XLS1",
                "size": "2XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROY3XLS1",
                "size": "3XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROY4XLS1",
                "size": "4XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ROY5XLS1",
                "size": "5XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SANSS1",
                "size": "S",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SANMS1",
                "size": "M",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SANLS1",
                "size": "L",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SANXLS1",
                "size": "XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAN2XLS1",
                "size": "2XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAN3XLS1",
                "size": "3XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAN4XLS1",
                "size": "4XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAN5XLS1",
                "size": "5XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPOSS1",
                "size": "S",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPOMS1",
                "size": "M",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPOLS1",
                "size": "L",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPOXLS1",
                "size": "XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPO2XLS1",
                "size": "2XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPO3XLS1",
                "size": "3XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPO4XLS1",
                "size": "4XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPO5XLS1",
                "size": "5XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHI4XLS1",
                "size": "4XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5WHI5XLS1",
                "size": "5XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIGSS1",
                "size": "S",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIGMS1",
                "size": "M",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIGLS1",
                "size": "L",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIGXLS1",
                "size": "XL",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIG2XLS1",
                "size": "2XL",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATIG3XLS1",
                "size": "3XL",
                "color": "ANTIQ IRISH GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJDSS1",
                "size": "S",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJDMS1",
                "size": "M",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJDLS1",
                "size": "L",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJDXLS1",
                "size": "XL",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJD2XLS1",
                "size": "2XL",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJD3XLS1",
                "size": "3XL",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATJD4XLS1",
                "size": "4XL",
                "color": "ANTIQU JADE DOME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATORSS1",
                "size": "S",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATORMS1",
                "size": "M",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATORLS1",
                "size": "L",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATORXLS1",
                "size": "XL",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATOR2XLS1",
                "size": "2XL",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATOR3XLS1",
                "size": "3XL",
                "color": "ANTIQUE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSASS1",
                "size": "S",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSAMS1",
                "size": "M",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSALS1",
                "size": "L",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSAXLS1",
                "size": "XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSA2XLS1",
                "size": "2XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATSA3XLS1",
                "size": "3XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCRSS1",
                "size": "S",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCRMS1",
                "size": "M",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCRLS1",
                "size": "L",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCRXLS1",
                "size": "XL",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCR2XLS1",
                "size": "2XL",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCR3XLS1",
                "size": "3XL",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCR4XLS1",
                "size": "4XL",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ATCR5XLS1",
                "size": "5XL",
                "color": "ANTQUE CHERRY RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQSS1",
                "size": "S",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQMS1",
                "size": "M",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQLS1",
                "size": "L",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQXLS1",
                "size": "XL",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQ2XLS1",
                "size": "2XL",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AQ3XLS1",
                "size": "3XL",
                "color": "AQUATIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZSS1",
                "size": "S",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZMS1",
                "size": "M",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZLS1",
                "size": "L",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZXLS1",
                "size": "XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZ2XLS1",
                "size": "2XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZ3XLS1",
                "size": "3XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZ4XLS1",
                "size": "4XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5AZ5XLS1",
                "size": "5XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BERSS1",
                "size": "S",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BERMS1",
                "size": "M",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BERLS1",
                "size": "L",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BERXLS1",
                "size": "XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BER2XLS1",
                "size": "2XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BER3XLS1",
                "size": "3XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BER4XLS1",
                "size": "4XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BER5XLS1",
                "size": "5XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBESS1",
                "size": "S",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBEMS1",
                "size": "M",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBELS1",
                "size": "L",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBEXLS1",
                "size": "XL",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBE2XLS1",
                "size": "2XL",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BLBE3XLS1",
                "size": "3XL",
                "color": "BLACKBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDUSS1",
                "size": "S",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDUMS1",
                "size": "M",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDULS1",
                "size": "L",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDUXLS1",
                "size": "XL",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDU2XLS1",
                "size": "2XL",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BDU3XLS1",
                "size": "3XL",
                "color": "BLUE DUSK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSASS1",
                "size": "S",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSAMS1",
                "size": "M",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSALS1",
                "size": "L",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSAXLS1",
                "size": "XL",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSA2XLS1",
                "size": "2XL",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSA3XLS1",
                "size": "3XL",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSA4XLS1",
                "size": "4XL",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5BSA5XLS1",
                "size": "5XL",
                "color": "BROWN SAVANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRDSS1",
                "size": "S",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRDMS1",
                "size": "M",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRDLS1",
                "size": "L",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRDXLS1",
                "size": "XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRD2XLS1",
                "size": "2XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRD3XLS1",
                "size": "3XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRD4XLS1",
                "size": "4XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRD5XLS1",
                "size": "5XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRBSS1",
                "size": "S",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRBMS1",
                "size": "M",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRBLS1",
                "size": "L",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRBXLS1",
                "size": "XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRB2XLS1",
                "size": "2XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRB3XLS1",
                "size": "3XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRB4XLS1",
                "size": "4XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRB5XLS1",
                "size": "5XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COBSS1",
                "size": "S",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COBMS1",
                "size": "M",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COBLS1",
                "size": "L",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COBXLS1",
                "size": "XL",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COB2XLS1",
                "size": "2XL",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COB3XLS1",
                "size": "3XL",
                "color": "COBALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRSSS1",
                "size": "S",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRSMS1",
                "size": "M",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRSLS1",
                "size": "L",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRSXLS1",
                "size": "XL",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRS2XLS1",
                "size": "2XL",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRS3XLS1",
                "size": "3XL",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRS4XLS1",
                "size": "4XL",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5CRS5XLS1",
                "size": "5XL",
                "color": "CORAL SILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COSSS1",
                "size": "S",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COSMS1",
                "size": "M",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COSLS1",
                "size": "L",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COSXLS1",
                "size": "XL",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COS2XLS1",
                "size": "2XL",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COS3XLS1",
                "size": "3XL",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COS4XLS1",
                "size": "4XL",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5COS5XLS1",
                "size": "5XL",
                "color": "CORNSILK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAISS1",
                "size": "S",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAIMS1",
                "size": "M",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAILS1",
                "size": "L",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAIXLS1",
                "size": "XL",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAI2XLS1",
                "size": "2XL",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAI3XLS1",
                "size": "3XL",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAI4XLS1",
                "size": "4XL",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAI5XLS1",
                "size": "5XL",
                "color": "DAISY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DACSS1",
                "size": "S",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DACMS1",
                "size": "M",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DACLS1",
                "size": "L",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DACXLS1",
                "size": "XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAC2XLS1",
                "size": "2XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAC3XLS1",
                "size": "3XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAC4XLS1",
                "size": "4XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DAC5XLS1",
                "size": "5XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DURSS1",
                "size": "S",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DURMS1",
                "size": "M",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DURLS1",
                "size": "L",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DURXLS1",
                "size": "XL",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DUR2XLS1",
                "size": "2XL",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5DUR3XLS1",
                "size": "3XL",
                "color": "DUSTY ROSE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELGSS1",
                "size": "S",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELGMS1",
                "size": "M",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELGLS1",
                "size": "L",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELGXLS1",
                "size": "XL",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELG2XLS1",
                "size": "2XL",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELG3XLS1",
                "size": "3XL",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELG4XLS1",
                "size": "4XL",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ELG5XLS1",
                "size": "5XL",
                "color": "ELECTRIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GARSS1",
                "size": "S",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GARMS1",
                "size": "M",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GARLS1",
                "size": "L",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GARXLS1",
                "size": "XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GAR2XLS1",
                "size": "2XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GAR3XLS1",
                "size": "3XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GAR4XLS1",
                "size": "4XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GAR5XLS1",
                "size": "5XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLDSS1",
                "size": "S",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLDMS1",
                "size": "M",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLDLS1",
                "size": "L",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLDXLS1",
                "size": "XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLD2XLS1",
                "size": "2XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLD3XLS1",
                "size": "3XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLD4XLS1",
                "size": "4XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GLD5XLS1",
                "size": "5XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHTSS1",
                "size": "S",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHTMS1",
                "size": "M",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHTLS1",
                "size": "L",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHTXLS1",
                "size": "XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHT2XLS1",
                "size": "2XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHT3XLS1",
                "size": "3XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHT4XLS1",
                "size": "4XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GHT5XLS1",
                "size": "5XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRVSS1",
                "size": "S",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRVMS1",
                "size": "M",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRVLS1",
                "size": "L",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRVXLS1",
                "size": "XL",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRV2XLS1",
                "size": "2XL",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRV3XLS1",
                "size": "3XL",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRV4XLS1",
                "size": "4XL",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5GRV5XLS1",
                "size": "5XL",
                "color": "GRAVEL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTNSS1",
                "size": "S",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTNMS1",
                "size": "M",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTNLS1",
                "size": "L",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTNXLS1",
                "size": "XL",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTN2XLS1",
                "size": "2XL",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTN3XLS1",
                "size": "3XL",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTN4XLS1",
                "size": "4XL",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTN5XLS1",
                "size": "5XL",
                "color": "HEATHER NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTRSS1",
                "size": "S",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTRMS1",
                "size": "M",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTRLS1",
                "size": "L",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTRXLS1",
                "size": "XL",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTR2XLS1",
                "size": "2XL",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTR3XLS1",
                "size": "3XL",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTR4XLS1",
                "size": "4XL",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTR5XLS1",
                "size": "5XL",
                "color": "HEATHER RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTSSS1",
                "size": "S",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTSMS1",
                "size": "M",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTSLS1",
                "size": "L",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTSXLS1",
                "size": "XL",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTS2XLS1",
                "size": "2XL",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTS3XLS1",
                "size": "3XL",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTS4XLS1",
                "size": "4XL",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HTS5XLS1",
                "size": "5XL",
                "color": "HEATHER SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLCSS1",
                "size": "S",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLCMS1",
                "size": "M",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLCLS1",
                "size": "L",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLCXLS1",
                "size": "XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLC2XLS1",
                "size": "2XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLC3XLS1",
                "size": "3XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLC4XLS1",
                "size": "4XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HLC5XLS1",
                "size": "5XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMGSS1",
                "size": "S",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMGMS1",
                "size": "M",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMGLS1",
                "size": "L",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMGXLS1",
                "size": "XL",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMG2XLS1",
                "size": "2XL",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMG3XLS1",
                "size": "3XL",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HMG4XLS1",
                "size": "4XL",
                "color": "HTHR MILITRY GRN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HROSS1",
                "size": "S",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HROMS1",
                "size": "M",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HROLS1",
                "size": "L",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HROXLS1",
                "size": "XL",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HRO2XLS1",
                "size": "2XL",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HRO3XLS1",
                "size": "3XL",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HRO4XLS1",
                "size": "4XL",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5HRO5XLS1",
                "size": "5XL",
                "color": "HTHR RDNT ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICGSS1",
                "size": "S",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICGMS1",
                "size": "M",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICGLS1",
                "size": "L",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICGXLS1",
                "size": "XL",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICG2XLS1",
                "size": "2XL",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ICG3XLS1",
                "size": "3XL",
                "color": "ICE GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INBSS1",
                "size": "S",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INBMS1",
                "size": "M",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INBLS1",
                "size": "L",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INBXLS1",
                "size": "XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INB2XLS1",
                "size": "2XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INB3XLS1",
                "size": "3XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INB4XLS1",
                "size": "4XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5INB5XLS1",
                "size": "5XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRGSS1",
                "size": "S",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRGMS1",
                "size": "M",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRGLS1",
                "size": "L",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRGXLS1",
                "size": "XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRG2XLS1",
                "size": "2XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRG3XLS1",
                "size": "3XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRG4XLS1",
                "size": "4XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5IRG5XLS1",
                "size": "5XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIWSS1",
                "size": "S",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIWMS1",
                "size": "M",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIWLS1",
                "size": "L",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIWXLS1",
                "size": "XL",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIW2XLS1",
                "size": "2XL",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIW3XLS1",
                "size": "3XL",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5KIW4XLS1",
                "size": "4XL",
                "color": "KIWI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LILSS1",
                "size": "S",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LILMS1",
                "size": "M",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LILLS1",
                "size": "L",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LILXLS1",
                "size": "XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LIL2XLS1",
                "size": "2XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LIL3XLS1",
                "size": "3XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LMESS1",
                "size": "S",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LMEMS1",
                "size": "M",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LMELS1",
                "size": "L",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LMEXLS1",
                "size": "XL",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LME2XLS1",
                "size": "2XL",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LME3XLS1",
                "size": "3XL",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LME4XLS1",
                "size": "4XL",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5LME5XLS1",
                "size": "5XL",
                "color": "LIME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNISS1",
                "size": "S",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNIMS1",
                "size": "M",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNILS1",
                "size": "L",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNIXLS1",
                "size": "XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNI2XLS1",
                "size": "2XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MNI3XLS1",
                "size": "3XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGRSS1",
                "size": "S",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGRMS1",
                "size": "M",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGRLS1",
                "size": "L",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGRXLS1",
                "size": "XL",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGR2XLS1",
                "size": "2XL",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGR3XLS1",
                "size": "3XL",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGR4XLS1",
                "size": "4XL",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5MGR5XLS1",
                "size": "5XL",
                "color": "MINT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEBSS1",
                "size": "S",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEBMS1",
                "size": "M",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEBLS1",
                "size": "L",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEBXLS1",
                "size": "XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEB2XLS1",
                "size": "2XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEB3XLS1",
                "size": "3XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEGSS1",
                "size": "S",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEGMS1",
                "size": "M",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEGLS1",
                "size": "L",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEGXLS1",
                "size": "XL",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEG2XLS1",
                "size": "2XL",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5NEG3XLS1",
                "size": "3XL",
                "color": "NEON GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFWSS1",
                "size": "S",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFWMS1",
                "size": "M",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFWLS1",
                "size": "L",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFWXLS1",
                "size": "XL",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFW2XLS1",
                "size": "2XL",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFW3XLS1",
                "size": "3XL",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OFW4XLS1",
                "size": "4XL",
                "color": "OFF WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLGSS1",
                "size": "S",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLGMS1",
                "size": "M",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLGLS1",
                "size": "L",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLGXLS1",
                "size": "XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLG2XLS1",
                "size": "2XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5OLG3XLS1",
                "size": "3XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORGSS1",
                "size": "S",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORGMS1",
                "size": "M",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORGLS1",
                "size": "L",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORGXLS1",
                "size": "XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORG2XLS1",
                "size": "2XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORG3XLS1",
                "size": "3XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORG4XLS1",
                "size": "4XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ORG5XLS1",
                "size": "5XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUSSS1",
                "size": "S",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUSMS1",
                "size": "M",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUSLS1",
                "size": "L",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUSXLS1",
                "size": "XL",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUS2XLS1",
                "size": "2XL",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5RUS3XLS1",
                "size": "3XL",
                "color": "RUSSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAGSS1",
                "size": "S",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAGMS1",
                "size": "M",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAGLS1",
                "size": "L",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAGXLS1",
                "size": "XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAG2XLS1",
                "size": "2XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAG3XLS1",
                "size": "3XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAG4XLS1",
                "size": "4XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAG5XLS1",
                "size": "5XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SORSS1",
                "size": "S",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SORMS1",
                "size": "M",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SORLS1",
                "size": "L",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SORXLS1",
                "size": "XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SOR2XLS1",
                "size": "2XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SOR3XLS1",
                "size": "3XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SOR4XLS1",
                "size": "4XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SOR5XLS1",
                "size": "5XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPISS1",
                "size": "S",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPIMS1",
                "size": "M",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPILS1",
                "size": "L",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPIXLS1",
                "size": "XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPI2XLS1",
                "size": "2XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SPI3XLS1",
                "size": "3XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAPSS1",
                "size": "S",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAPMS1",
                "size": "M",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAPLS1",
                "size": "L",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAPXLS1",
                "size": "XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAP2XLS1",
                "size": "2XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAP3XLS1",
                "size": "3XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAP4XLS1",
                "size": "4XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SAP5XLS1",
                "size": "5XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKYSS1",
                "size": "S",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKYMS1",
                "size": "M",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKYLS1",
                "size": "L",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKYXLS1",
                "size": "XL",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKY2XLS1",
                "size": "2XL",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKY3XLS1",
                "size": "3XL",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKY4XLS1",
                "size": "4XL",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SKY5XLS1",
                "size": "5XL",
                "color": "SKY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEOSS1",
                "size": "S",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEOMS1",
                "size": "M",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEOLS1",
                "size": "L",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEOXLS1",
                "size": "XL",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEO2XLS1",
                "size": "2XL",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEO3XLS1",
                "size": "3XL",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TEO4XLS1",
                "size": "4XL",
                "color": "TENNESSEE ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TANSS1",
                "size": "S",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TANMS1",
                "size": "M",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TANLS1",
                "size": "L",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TANXLS1",
                "size": "XL",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TAN2XLS1",
                "size": "2XL",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TAN3XLS1",
                "size": "3XL",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TAN4XLS1",
                "size": "4XL",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TAN5XLS1",
                "size": "5XL",
                "color": "TANGERINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUNSS1",
                "size": "S",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUNMS1",
                "size": "M",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUNLS1",
                "size": "L",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUNXLS1",
                "size": "XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUN2XLS1",
                "size": "2XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5SUN3XLS1",
                "size": "3XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXOSS1",
                "size": "S",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXOMS1",
                "size": "M",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXOLS1",
                "size": "L",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXOXLS1",
                "size": "XL",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXO2XLS1",
                "size": "2XL",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TXO3XLS1",
                "size": "3XL",
                "color": "TEXAS ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRBSS1",
                "size": "S",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRBMS1",
                "size": "M",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRBLS1",
                "size": "L",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRBXLS1",
                "size": "XL",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRB2XLS1",
                "size": "2XL",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRB3XLS1",
                "size": "3XL",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRB4XLS1",
                "size": "4XL",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TRB5XLS1",
                "size": "5XL",
                "color": "TROPICAL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIOSS1",
                "size": "S",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIOMS1",
                "size": "M",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIOLS1",
                "size": "L",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIOXLS1",
                "size": "XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIO2XLS1",
                "size": "2XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIO3XLS1",
                "size": "3XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIO4XLS1",
                "size": "4XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5VIO5XLS1",
                "size": "5XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUGSS1",
                "size": "S",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUGMS1",
                "size": "M",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUGLS1",
                "size": "L",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUGXLS1",
                "size": "XL",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUG2XLS1",
                "size": "2XL",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUG3XLS1",
                "size": "3XL",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUG4XLS1",
                "size": "4XL",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TUG5XLS1",
                "size": "5XL",
                "color": "TURF GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWESS1",
                "size": "S",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWEMS1",
                "size": "M",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWELS1",
                "size": "L",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWEXLS1",
                "size": "XL",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWE2XLS1",
                "size": "2XL",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5TWE3XLS1",
                "size": "3XL",
                "color": "TWEED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEHSS1",
                "size": "S",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEHMS1",
                "size": "M",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEHLS1",
                "size": "L",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEHXLS1",
                "size": "XL",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEH2XLS1",
                "size": "2XL",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEH3XLS1",
                "size": "3XL",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEH4XLS1",
                "size": "4XL",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5YEH5XLS1",
                "size": "5XL",
                "color": "YELLOW HAZE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG5ASHSS1",
                "size": "S",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-Gildan-UnisexT-Shirt",
        "sku": "Unisex-Gildan-UnisexT-Shirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex T-Shirt",
        "variations": [
            {
                "sku": "TCBC31ATHXSS1",
                "size": "XS",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATHSS1",
                "size": "S",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATHMS1",
                "size": "M",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATHLS1",
                "size": "L",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATHXLS1",
                "size": "XL",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATH2XLS1",
                "size": "2XL",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATH3XLS1",
                "size": "3XL",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLXSS1",
                "size": "XS",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSS1",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLMS1",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLLS1",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLXLS1",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BL2XLS1",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BL3XLS1",
                "size": "3XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BL4XLS1",
                "size": "4XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAGXSS1",
                "size": "XS",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAGSS1",
                "size": "S",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAGMS1",
                "size": "M",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAGLS1",
                "size": "L",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAGXLS1",
                "size": "XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAG2XLS1",
                "size": "2XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAG3XLS1",
                "size": "3XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DAG4XLS1",
                "size": "4XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NATXSS1",
                "size": "XS",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NATSS1",
                "size": "S",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NATMS1",
                "size": "M",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NATLS1",
                "size": "L",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NATXLS1",
                "size": "XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAT2XLS1",
                "size": "2XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAT3XLS1",
                "size": "3XL",
                "color": "NATURAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHIXSS1",
                "size": "XS",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31WHI4XLS1",
                "size": "4XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQUXSS1",
                "size": "XS",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQUSS1",
                "size": "S",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQUMS1",
                "size": "M",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQULS1",
                "size": "L",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQUXLS1",
                "size": "XL",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQU2XLS1",
                "size": "2XL",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQU3XLS1",
                "size": "3XL",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31AQU4XLS1",
                "size": "4XL",
                "color": "AQUA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARMXSS1",
                "size": "XS",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARMSS1",
                "size": "S",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARMMS1",
                "size": "M",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARMLS1",
                "size": "L",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARMXLS1",
                "size": "XL",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARM2XLS1",
                "size": "2XL",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARM3XLS1",
                "size": "3XL",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ARM4XLS1",
                "size": "4XL",
                "color": "ARMY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASHXSS1",
                "size": "XS",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASHSS1",
                "size": "S",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASHMS1",
                "size": "M",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASHLS1",
                "size": "L",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASHXLS1",
                "size": "XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASH2XLS1",
                "size": "2XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASH3XLS1",
                "size": "3XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASH4XLS1",
                "size": "4XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASPXSS1",
                "size": "XS",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASPSS1",
                "size": "S",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASPMS1",
                "size": "M",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASPLS1",
                "size": "L",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASPXLS1",
                "size": "XL",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASP2XLS1",
                "size": "2XL",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASP3XLS1",
                "size": "3XL",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ASP4XLS1",
                "size": "4XL",
                "color": "ASPHALT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATLXSS1",
                "size": "XS",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATLSS1",
                "size": "S",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATLMS1",
                "size": "M",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATLLS1",
                "size": "L",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATLXLS1",
                "size": "XL",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATL2XLS1",
                "size": "2XL",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATL3XLS1",
                "size": "3XL",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATL4XLS1",
                "size": "4XL",
                "color": "ATLANTIC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATUXSS1",
                "size": "XS",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATUSS1",
                "size": "S",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATUMS1",
                "size": "M",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATULS1",
                "size": "L",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATUXLS1",
                "size": "XL",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATU2XLS1",
                "size": "2XL",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATU3XLS1",
                "size": "3XL",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ATU4XLS1",
                "size": "4XL",
                "color": "AUTUMN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BABXSS1",
                "size": "XS",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BABSS1",
                "size": "S",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BABMS1",
                "size": "M",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BABLS1",
                "size": "L",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BABXLS1",
                "size": "XL",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BAB2XLS1",
                "size": "2XL",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BAB3XLS1",
                "size": "3XL",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BAB4XLS1",
                "size": "4XL",
                "color": "BABY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BERXSS1",
                "size": "XS",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BERSS1",
                "size": "S",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BERMS1",
                "size": "M",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BERLS1",
                "size": "L",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BERXLS1",
                "size": "XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BER2XLS1",
                "size": "2XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BER3XLS1",
                "size": "3XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BER4XLS1",
                "size": "4XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSXSS1",
                "size": "XS",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSSS1",
                "size": "S",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSMS1",
                "size": "M",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSLS1",
                "size": "L",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLSXLS1",
                "size": "XL",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLS2XLS1",
                "size": "2XL",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLS3XLS1",
                "size": "3XL",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BLS4XLS1",
                "size": "4XL",
                "color": "BLUE STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BROXSS1",
                "size": "XS",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BROSS1",
                "size": "S",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BROMS1",
                "size": "M",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BROLS1",
                "size": "L",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BROXLS1",
                "size": "XL",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BRO2XLS1",
                "size": "2XL",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BRO3XLS1",
                "size": "3XL",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BRO4XLS1",
                "size": "4XL",
                "color": "BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BURXSS1",
                "size": "XS",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BURSS1",
                "size": "S",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BURMS1",
                "size": "M",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BURLS1",
                "size": "L",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BURXLS1",
                "size": "XL",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BUR2XLS1",
                "size": "2XL",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BUR3XLS1",
                "size": "3XL",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31BUR4XLS1",
                "size": "4XL",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CREXSS1",
                "size": "XS",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CRESS1",
                "size": "S",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CREMS1",
                "size": "M",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CRELS1",
                "size": "L",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CREXLS1",
                "size": "XL",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CRE2XLS1",
                "size": "2XL",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CRE3XLS1",
                "size": "3XL",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CRE4XLS1",
                "size": "4XL",
                "color": "CANVAS RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CARXSS1",
                "size": "XS",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CARSS1",
                "size": "S",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CARMS1",
                "size": "M",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CARLS1",
                "size": "L",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CARXLS1",
                "size": "XL",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CAR2XLS1",
                "size": "2XL",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CAR3XLS1",
                "size": "3XL",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CAR4XLS1",
                "size": "4XL",
                "color": "CARDINAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBLXSS1",
                "size": "XS",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBLSS1",
                "size": "S",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBLMS1",
                "size": "M",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBLLS1",
                "size": "L",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBLXLS1",
                "size": "XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBL2XLS1",
                "size": "2XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBL3XLS1",
                "size": "3XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CBL4XLS1",
                "size": "4XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHPXSS1",
                "size": "XS",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHPSS1",
                "size": "S",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHPMS1",
                "size": "M",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHPLS1",
                "size": "L",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHPXLS1",
                "size": "XL",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHP2XLS1",
                "size": "2XL",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHP3XLS1",
                "size": "3XL",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHP4XLS1",
                "size": "4XL",
                "color": "CHARITY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHEXSS1",
                "size": "XS",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHESS1",
                "size": "S",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHEMS1",
                "size": "M",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHELS1",
                "size": "L",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHEXLS1",
                "size": "XL",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHE2XLS1",
                "size": "2XL",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHE3XLS1",
                "size": "3XL",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CHE4XLS1",
                "size": "4XL",
                "color": "CHESTNUT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CITXSS1",
                "size": "XS",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CITSS1",
                "size": "S",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CITMS1",
                "size": "M",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CITLS1",
                "size": "L",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CITXLS1",
                "size": "XL",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CIT2XLS1",
                "size": "2XL",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CIT3XLS1",
                "size": "3XL",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CIT4XLS1",
                "size": "4XL",
                "color": "CITRON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLAXSS1",
                "size": "XS",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLASS1",
                "size": "S",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLAMS1",
                "size": "M",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLALS1",
                "size": "L",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLAXLS1",
                "size": "XL",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLA2XLS1",
                "size": "2XL",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLA3XLS1",
                "size": "3XL",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CLA4XLS1",
                "size": "4XL",
                "color": "CLAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COBXSS1",
                "size": "XS",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COBSS1",
                "size": "S",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COBMS1",
                "size": "M",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COBLS1",
                "size": "L",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COBXLS1",
                "size": "XL",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COB2XLS1",
                "size": "2XL",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COB3XLS1",
                "size": "3XL",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COB4XLS1",
                "size": "4XL",
                "color": "COLUMBIA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COOXSS1",
                "size": "XS",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COOSS1",
                "size": "S",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COOMS1",
                "size": "M",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COOLS1",
                "size": "L",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COOXLS1",
                "size": "XL",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COO2XLS1",
                "size": "2XL",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COO3XLS1",
                "size": "3XL",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COO4XLS1",
                "size": "4XL",
                "color": "COOL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CORXSS1",
                "size": "XS",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CORSS1",
                "size": "S",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CORMS1",
                "size": "M",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CORLS1",
                "size": "L",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31CORXLS1",
                "size": "XL",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COR2XLS1",
                "size": "2XL",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COR3XLS1",
                "size": "3XL",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31COR4XLS1",
                "size": "4XL",
                "color": "CORAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGRXSS1",
                "size": "XS",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGRSS1",
                "size": "S",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGRMS1",
                "size": "M",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGRLS1",
                "size": "L",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGRXLS1",
                "size": "XL",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGR2XLS1",
                "size": "2XL",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGR3XLS1",
                "size": "3XL",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DGR4XLS1",
                "size": "4XL",
                "color": "DARK GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLAXSS1",
                "size": "XS",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLASS1",
                "size": "S",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLAMS1",
                "size": "M",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLALS1",
                "size": "L",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLAXLS1",
                "size": "XL",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLA2XLS1",
                "size": "2XL",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLA3XLS1",
                "size": "3XL",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DLA4XLS1",
                "size": "4XL",
                "color": "DARK LAVENDER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOLXSS1",
                "size": "XS",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOLSS1",
                "size": "S",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOLMS1",
                "size": "M",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOLLS1",
                "size": "L",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOLXLS1",
                "size": "XL",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOL2XLS1",
                "size": "2XL",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOL3XLS1",
                "size": "3XL",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DOL4XLS1",
                "size": "4XL",
                "color": "DARK OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTEXSS1",
                "size": "XS",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTESS1",
                "size": "S",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTEMS1",
                "size": "M",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTELS1",
                "size": "L",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTEXLS1",
                "size": "XL",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTE2XLS1",
                "size": "2XL",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTE3XLS1",
                "size": "3XL",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DTE4XLS1",
                "size": "4XL",
                "color": "DEEP TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUSXSS1",
                "size": "XS",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUSSS1",
                "size": "S",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUSMS1",
                "size": "M",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUSLS1",
                "size": "L",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUSXLS1",
                "size": "XL",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUS2XLS1",
                "size": "2XL",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUS3XLS1",
                "size": "3XL",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DUS4XLS1",
                "size": "4XL",
                "color": "DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBLXSS1",
                "size": "XS",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBLSS1",
                "size": "S",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBLMS1",
                "size": "M",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBLLS1",
                "size": "L",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBLXLS1",
                "size": "XL",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBL2XLS1",
                "size": "2XL",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBL3XLS1",
                "size": "3XL",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31DBL4XLS1",
                "size": "4XL",
                "color": "DUSTY BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELBXSS1",
                "size": "XS",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELBSS1",
                "size": "S",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELBMS1",
                "size": "M",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELBLS1",
                "size": "L",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELBXLS1",
                "size": "XL",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELB2XLS1",
                "size": "2XL",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELB3XLS1",
                "size": "3XL",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ELB4XLS1",
                "size": "4XL",
                "color": "ELECTRIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVEXSS1",
                "size": "XS",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVESS1",
                "size": "S",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVEMS1",
                "size": "M",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVELS1",
                "size": "L",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVEXLS1",
                "size": "XL",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVE2XLS1",
                "size": "2XL",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVE3XLS1",
                "size": "3XL",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31EVE4XLS1",
                "size": "4XL",
                "color": "EVERGREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FORXSS1",
                "size": "XS",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FORSS1",
                "size": "S",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FORMS1",
                "size": "M",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FORLS1",
                "size": "L",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FORXLS1",
                "size": "XL",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FOR2XLS1",
                "size": "2XL",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FOR3XLS1",
                "size": "3XL",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FOR4XLS1",
                "size": "4XL",
                "color": "FOREST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUSXSS1",
                "size": "XS",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUSSS1",
                "size": "S",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUSMS1",
                "size": "M",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUSLS1",
                "size": "L",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUSXLS1",
                "size": "XL",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUS2XLS1",
                "size": "2XL",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUS3XLS1",
                "size": "3XL",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31FUS4XLS1",
                "size": "4XL",
                "color": "FUCHSIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOLXSS1",
                "size": "XS",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOLSS1",
                "size": "S",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOLMS1",
                "size": "M",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOLLS1",
                "size": "L",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOLXLS1",
                "size": "XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOL2XLS1",
                "size": "2XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOL3XLS1",
                "size": "3XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31GOL4XLS1",
                "size": "4XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KELXSS1",
                "size": "XS",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KELSS1",
                "size": "S",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KELMS1",
                "size": "M",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KELLS1",
                "size": "L",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KELXLS1",
                "size": "XL",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KEL2XLS1",
                "size": "2XL",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KEL3XLS1",
                "size": "3XL",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31KEL4XLS1",
                "size": "4XL",
                "color": "KELLY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LABXSS1",
                "size": "XS",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LABSS1",
                "size": "S",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LABMS1",
                "size": "M",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LABLS1",
                "size": "L",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LABXLS1",
                "size": "XL",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAB2XLS1",
                "size": "2XL",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAB3XLS1",
                "size": "3XL",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAB4XLS1",
                "size": "4XL",
                "color": "LAVENDER BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LADXSS1",
                "size": "XS",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LADSS1",
                "size": "S",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LADMS1",
                "size": "M",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LADLS1",
                "size": "L",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LADXLS1",
                "size": "XL",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAD2XLS1",
                "size": "2XL",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAD3XLS1",
                "size": "3XL",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LAD4XLS1",
                "size": "4XL",
                "color": "LAVENDER DUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEAXSS1",
                "size": "XS",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEASS1",
                "size": "S",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEAMS1",
                "size": "M",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEALS1",
                "size": "L",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEAXLS1",
                "size": "XL",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEA2XLS1",
                "size": "2XL",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEA3XLS1",
                "size": "3XL",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LEA4XLS1",
                "size": "4XL",
                "color": "LEAF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBLXSS1",
                "size": "XS",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBLSS1",
                "size": "S",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBLMS1",
                "size": "M",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBLLS1",
                "size": "L",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBLXLS1",
                "size": "XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBL2XLS1",
                "size": "2XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBL3XLS1",
                "size": "3XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LBL4XLS1",
                "size": "4XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LVI3XLS1",
                "size": "3XL",
                "color": "LIGHT VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LVI4XLS1",
                "size": "4XL",
                "color": "LIGHT VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LILXSS1",
                "size": "XS",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LILSS1",
                "size": "S",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LILMS1",
                "size": "M",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LILLS1",
                "size": "L",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LILXLS1",
                "size": "XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LIL2XLS1",
                "size": "2XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LIL3XLS1",
                "size": "3XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31LIL4XLS1",
                "size": "4XL",
                "color": "LILAC",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAYXSS1",
                "size": "XS",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAYSS1",
                "size": "S",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAYMS1",
                "size": "M",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAYLS1",
                "size": "L",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAYXLS1",
                "size": "XL",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAY2XLS1",
                "size": "2XL",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAY3XLS1",
                "size": "3XL",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAY4XLS1",
                "size": "4XL",
                "color": "MAIZE YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MARXSS1",
                "size": "XS",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MARSS1",
                "size": "S",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MARMS1",
                "size": "M",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MARLS1",
                "size": "L",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MARXLS1",
                "size": "XL",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAR2XLS1",
                "size": "2XL",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAR3XLS1",
                "size": "3XL",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAR4XLS1",
                "size": "4XL",
                "color": "MARINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MROXSS1",
                "size": "XS",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MROSS1",
                "size": "S",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MROMS1",
                "size": "M",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MROLS1",
                "size": "L",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MROXLS1",
                "size": "XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MRO2XLS1",
                "size": "2XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MRO3XLS1",
                "size": "3XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MRO4XLS1",
                "size": "4XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAUXSS1",
                "size": "XS",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAUSS1",
                "size": "S",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAUMS1",
                "size": "M",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAULS1",
                "size": "L",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAUXLS1",
                "size": "XL",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAU2XLS1",
                "size": "2XL",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAU3XLS1",
                "size": "3XL",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MAU4XLS1",
                "size": "4XL",
                "color": "MAUVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLGXSS1",
                "size": "XS",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLGSS1",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLGMS1",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLGLS1",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLGXLS1",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLG2XLS1",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLG3XLS1",
                "size": "3XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MLG4XLS1",
                "size": "4XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MINXSS1",
                "size": "XS",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MINSS1",
                "size": "S",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MINMS1",
                "size": "M",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MINLS1",
                "size": "L",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MINXLS1",
                "size": "XL",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MIN2XLS1",
                "size": "2XL",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MIN3XLS1",
                "size": "3XL",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MIN4XLS1",
                "size": "4XL",
                "color": "MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUSXSS1",
                "size": "XS",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUSSS1",
                "size": "S",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUSMS1",
                "size": "M",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUSLS1",
                "size": "L",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUSXLS1",
                "size": "XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUS2XLS1",
                "size": "2XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUS3XLS1",
                "size": "3XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31MUS4XLS1",
                "size": "4XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAVXSS1",
                "size": "XS",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAVSS1",
                "size": "S",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAVMS1",
                "size": "M",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAVLS1",
                "size": "L",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAVXLS1",
                "size": "XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAV2XLS1",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAV3XLS1",
                "size": "3XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31NAV4XLS1",
                "size": "4XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCBXSS1",
                "size": "XS",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCBSS1",
                "size": "S",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCBMS1",
                "size": "M",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCBLS1",
                "size": "L",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCBXLS1",
                "size": "XL",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCB2XLS1",
                "size": "2XL",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCB3XLS1",
                "size": "3XL",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OCB4XLS1",
                "size": "4XL",
                "color": "OCEAN BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLVXSS1",
                "size": "XS",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLVSS1",
                "size": "S",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLVMS1",
                "size": "M",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLVLS1",
                "size": "L",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLVXLS1",
                "size": "XL",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLV2XLS1",
                "size": "2XL",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLV3XLS1",
                "size": "3XL",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OLV4XLS1",
                "size": "4XL",
                "color": "OLIVE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORGXSS1",
                "size": "XS",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORGSS1",
                "size": "S",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORGMS1",
                "size": "M",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORGLS1",
                "size": "L",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORGXLS1",
                "size": "XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORG2XLS1",
                "size": "2XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORG3XLS1",
                "size": "3XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORG4XLS1",
                "size": "4XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORCXSS1",
                "size": "XS",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORCSS1",
                "size": "S",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORCMS1",
                "size": "M",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORCLS1",
                "size": "L",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORCXLS1",
                "size": "XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORC2XLS1",
                "size": "2XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORC3XLS1",
                "size": "3XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ORC4XLS1",
                "size": "4XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXBXSS1",
                "size": "XS",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXBSS1",
                "size": "S",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXBMS1",
                "size": "M",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXBLS1",
                "size": "L",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXBXLS1",
                "size": "XL",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXB2XLS1",
                "size": "2XL",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXB3XLS1",
                "size": "3XL",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31OXB4XLS1",
                "size": "4XL",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEAXSS1",
                "size": "XS",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEASS1",
                "size": "S",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEAMS1",
                "size": "M",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEALS1",
                "size": "L",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEAXLS1",
                "size": "XL",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEA2XLS1",
                "size": "2XL",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEA3XLS1",
                "size": "3XL",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEA4XLS1",
                "size": "4XL",
                "color": "PEACH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEBXSS1",
                "size": "XS",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEBSS1",
                "size": "S",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEBMS1",
                "size": "M",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEBLS1",
                "size": "L",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEBXLS1",
                "size": "XL",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEB2XLS1",
                "size": "2XL",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEB3XLS1",
                "size": "3XL",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PEB4XLS1",
                "size": "4XL",
                "color": "PEBBLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PINXSS1",
                "size": "XS",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PINSS1",
                "size": "S",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PINMS1",
                "size": "M",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PINLS1",
                "size": "L",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PINXLS1",
                "size": "XL",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PIN2XLS1",
                "size": "2XL",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PIN3XLS1",
                "size": "3XL",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PIN4XLS1",
                "size": "4XL",
                "color": "PINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNKXSS1",
                "size": "XS",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNKSS1",
                "size": "S",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNKMS1",
                "size": "M",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNKLS1",
                "size": "L",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNKXLS1",
                "size": "XL",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNK2XLS1",
                "size": "2XL",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNK3XLS1",
                "size": "3XL",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31PNK4XLS1",
                "size": "4XL",
                "color": "PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POPXSS1",
                "size": "XS",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POPSS1",
                "size": "S",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POPMS1",
                "size": "M",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POPLS1",
                "size": "L",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POPXLS1",
                "size": "XL",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POP2XLS1",
                "size": "2XL",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POP3XLS1",
                "size": "3XL",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31POP4XLS1",
                "size": "4XL",
                "color": "POPPY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31REDXSS1",
                "size": "XS",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31REDSS1",
                "size": "S",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31REDMS1",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31REDLS1",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31REDXLS1",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RED2XLS1",
                "size": "2XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RED3XLS1",
                "size": "3XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RED4XLS1",
                "size": "4XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROPXSS1",
                "size": "XS",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROPSS1",
                "size": "S",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROPMS1",
                "size": "M",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROPLS1",
                "size": "L",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROPXLS1",
                "size": "XL",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROP2XLS1",
                "size": "2XL",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROP3XLS1",
                "size": "3XL",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31ROP4XLS1",
                "size": "4XL",
                "color": "ROYAL PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUSXSS1",
                "size": "XS",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUSSS1",
                "size": "S",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUSMS1",
                "size": "M",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUSLS1",
                "size": "L",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUSXLS1",
                "size": "XL",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUS2XLS1",
                "size": "2XL",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUS3XLS1",
                "size": "3XL",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31RUS4XLS1",
                "size": "4XL",
                "color": "RUST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAGXSS1",
                "size": "XS",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAGSS1",
                "size": "S",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAGMS1",
                "size": "M",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAGLS1",
                "size": "L",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAGXLS1",
                "size": "XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAG2XLS1",
                "size": "2XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAG3XLS1",
                "size": "3XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAG4XLS1",
                "size": "4XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDUXSS1",
                "size": "XS",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDUSS1",
                "size": "S",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDUMS1",
                "size": "M",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDULS1",
                "size": "L",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDUXLS1",
                "size": "XL",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDU2XLS1",
                "size": "2XL",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDU3XLS1",
                "size": "3XL",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SDU4XLS1",
                "size": "4XL",
                "color": "SAND DUNE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SILXSS1",
                "size": "XS",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SILSS1",
                "size": "S",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SILMS1",
                "size": "M",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SILLS1",
                "size": "L",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SILXLS1",
                "size": "XL",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SIL2XLS1",
                "size": "2XL",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SIL3XLS1",
                "size": "3XL",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SIL4XLS1",
                "size": "4XL",
                "color": "SILVER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLAXSS1",
                "size": "XS",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLASS1",
                "size": "S",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLAMS1",
                "size": "M",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLALS1",
                "size": "L",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLAXLS1",
                "size": "XL",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLA2XLS1",
                "size": "2XL",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLA3XLS1",
                "size": "3XL",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SLA4XLS1",
                "size": "4XL",
                "color": "SLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCRXSS1",
                "size": "XS",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCRSS1",
                "size": "S",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCRMS1",
                "size": "M",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCRLS1",
                "size": "L",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCRXLS1",
                "size": "XL",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCR2XLS1",
                "size": "2XL",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCR3XLS1",
                "size": "3XL",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SCR4XLS1",
                "size": "4XL",
                "color": "SOFT CREAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPIXSS1",
                "size": "XS",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPISS1",
                "size": "S",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPIMS1",
                "size": "M",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPILS1",
                "size": "L",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPIXLS1",
                "size": "XL",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPI2XLS1",
                "size": "2XL",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPI3XLS1",
                "size": "3XL",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPI4XLS1",
                "size": "4XL",
                "color": "SOFT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SATXSS1",
                "size": "XS",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SATSS1",
                "size": "S",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SATMS1",
                "size": "M",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SATLS1",
                "size": "L",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SATXLS1",
                "size": "XL",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAT2XLS1",
                "size": "2XL",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAT3XLS1",
                "size": "3XL",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SAT4XLS1",
                "size": "4XL",
                "color": "SOLID ATHLTC GRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPGXSS1",
                "size": "XS",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPGSS1",
                "size": "S",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPGMS1",
                "size": "M",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPGLS1",
                "size": "L",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPGXLS1",
                "size": "XL",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPG2XLS1",
                "size": "2XL",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPG3XLS1",
                "size": "3XL",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SPG4XLS1",
                "size": "4XL",
                "color": "SPRING GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STBXSS1",
                "size": "XS",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STBSS1",
                "size": "S",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STBMS1",
                "size": "M",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STBLS1",
                "size": "L",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STBXLS1",
                "size": "XL",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STB2XLS1",
                "size": "2XL",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STB3XLS1",
                "size": "3XL",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STB4XLS1",
                "size": "4XL",
                "color": "STEEL BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STOXSS1",
                "size": "XS",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STOSS1",
                "size": "S",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STOMS1",
                "size": "M",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STOLS1",
                "size": "L",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STOXLS1",
                "size": "XL",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STO2XLS1",
                "size": "2XL",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STO3XLS1",
                "size": "3XL",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STO4XLS1",
                "size": "4XL",
                "color": "STORM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STRXSS1",
                "size": "XS",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STRSS1",
                "size": "S",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STRMS1",
                "size": "M",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STRLS1",
                "size": "L",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STRXLS1",
                "size": "XL",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STR2XLS1",
                "size": "2XL",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STR3XLS1",
                "size": "3XL",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31STR4XLS1",
                "size": "4XL",
                "color": "STROBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUNXSS1",
                "size": "XS",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUNSS1",
                "size": "S",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUNMS1",
                "size": "M",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUNLS1",
                "size": "L",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUNXLS1",
                "size": "XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUN2XLS1",
                "size": "2XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUN3XLS1",
                "size": "3XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SUN4XLS1",
                "size": "4XL",
                "color": "SUNSET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYGXSS1",
                "size": "XS",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYGSS1",
                "size": "S",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYGMS1",
                "size": "M",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYGLS1",
                "size": "L",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYGXLS1",
                "size": "XL",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYG2XLS1",
                "size": "2XL",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYG3XLS1",
                "size": "3XL",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31SYG4XLS1",
                "size": "4XL",
                "color": "SYNTHETIC GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TANXSS1",
                "size": "XS",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TANSS1",
                "size": "S",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TANMS1",
                "size": "M",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TANLS1",
                "size": "L",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TANXLS1",
                "size": "XL",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TAN2XLS1",
                "size": "2XL",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TAN3XLS1",
                "size": "3XL",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TAN4XLS1",
                "size": "4XL",
                "color": "TAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEAXSS1",
                "size": "XS",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEASS1",
                "size": "S",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEAMS1",
                "size": "M",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEALS1",
                "size": "L",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEAXLS1",
                "size": "XL",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEA2XLS1",
                "size": "2XL",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEA3XLS1",
                "size": "3XL",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TEA4XLS1",
                "size": "4XL",
                "color": "TEAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNAXSS1",
                "size": "XS",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNASS1",
                "size": "S",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNAMS1",
                "size": "M",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNALS1",
                "size": "L",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNAXLS1",
                "size": "XL",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNA2XLS1",
                "size": "2XL",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNA3XLS1",
                "size": "3XL",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TNA4XLS1",
                "size": "4XL",
                "color": "TEAM NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPUXSS1",
                "size": "XS",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPUSS1",
                "size": "S",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPUMS1",
                "size": "M",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPULS1",
                "size": "L",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPUXLS1",
                "size": "XL",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPU2XLS1",
                "size": "2XL",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPU3XLS1",
                "size": "3XL",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TPU4XLS1",
                "size": "4XL",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TERXSS1",
                "size": "XS",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TERSS1",
                "size": "S",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TERMS1",
                "size": "M",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TERLS1",
                "size": "L",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TERXLS1",
                "size": "XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TER2XLS1",
                "size": "2XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TER3XLS1",
                "size": "3XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TER4XLS1",
                "size": "4XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THYXSS1",
                "size": "XS",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THYSS1",
                "size": "S",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THYMS1",
                "size": "M",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THYLS1",
                "size": "L",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THYXLS1",
                "size": "XL",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THY2XLS1",
                "size": "2XL",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THY3XLS1",
                "size": "3XL",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31THY4XLS1",
                "size": "4XL",
                "color": "THYME",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOAXSS1",
                "size": "XS",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOASS1",
                "size": "S",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOAMS1",
                "size": "M",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOALS1",
                "size": "L",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOAXLS1",
                "size": "XL",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOA2XLS1",
                "size": "2XL",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOA3XLS1",
                "size": "3XL",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TOA4XLS1",
                "size": "4XL",
                "color": "TOAST",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRRXSS1",
                "size": "XS",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRRSS1",
                "size": "S",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRRMS1",
                "size": "M",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRRLS1",
                "size": "L",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRRXLS1",
                "size": "XL",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRR2XLS1",
                "size": "2XL",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRR3XLS1",
                "size": "3XL",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TRR4XLS1",
                "size": "4XL",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TURXSS1",
                "size": "XS",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TURSS1",
                "size": "S",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TURMS1",
                "size": "M",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TURLS1",
                "size": "L",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TURXLS1",
                "size": "XL",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TUR2XLS1",
                "size": "2XL",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TUR3XLS1",
                "size": "3XL",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31TUR4XLS1",
                "size": "4XL",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBLXSS1",
                "size": "XS",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBLSS1",
                "size": "S",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBLMS1",
                "size": "M",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBLLS1",
                "size": "L",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBLXLS1",
                "size": "XL",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBL2XLS1",
                "size": "2XL",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBL3XLS1",
                "size": "3XL",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBL4XLS1",
                "size": "4XL",
                "color": "VINTAGE BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBRXSS1",
                "size": "XS",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBRSS1",
                "size": "S",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBRMS1",
                "size": "M",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBRLS1",
                "size": "L",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBRXLS1",
                "size": "XL",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBR2XLS1",
                "size": "2XL",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBR3XLS1",
                "size": "3XL",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VBR4XLS1",
                "size": "4XL",
                "color": "VINTAGE BROWN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNAXSS1",
                "size": "XS",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNASS1",
                "size": "S",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNAMS1",
                "size": "M",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNALS1",
                "size": "L",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNAXLS1",
                "size": "XL",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNA2XLS1",
                "size": "2XL",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNA3XLS1",
                "size": "3XL",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VNA4XLS1",
                "size": "4XL",
                "color": "VINTAGE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWHXSS1",
                "size": "XS",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWHSS1",
                "size": "S",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWHMS1",
                "size": "M",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWHLS1",
                "size": "L",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWHXLS1",
                "size": "XL",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWH2XLS1",
                "size": "2XL",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWH3XLS1",
                "size": "3XL",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31VWH4XLS1",
                "size": "4XL",
                "color": "VINTAGE WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YELXSS1",
                "size": "XS",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YELSS1",
                "size": "S",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YELMS1",
                "size": "M",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YELLS1",
                "size": "L",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YELXLS1",
                "size": "XL",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YEL2XLS1",
                "size": "2XL",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YEL3XLS1",
                "size": "3XL",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCBC31YEL4XLS1",
                "size": "4XL",
                "color": "YELLOW",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-BellaCanvas-UnisexT-Shirt",
        "sku": "Unisex-BellaCanvas-UnisexT-Shirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex T-Shirt",
        "variations": [
            {
                "sku": "TCC17BLSS1",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLMS1",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLLS1",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLXLS1",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BL2XLS1",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BL3XLS1",
                "size": "3XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVOSS1",
                "size": "S",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVOMS1",
                "size": "M",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVOLS1",
                "size": "L",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVOXLS1",
                "size": "XL",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVO2XLS1",
                "size": "2XL",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IVO3XLS1",
                "size": "3XL",
                "color": "IVORY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MOSSS1",
                "size": "S",
                "color": "MOSS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MOSMS1",
                "size": "M",
                "color": "MOSS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MOSLS1",
                "size": "L",
                "color": "MOSS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MOSXLS1",
                "size": "XL",
                "color": "MOSS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MOS2XLS1",
                "size": "2XL",
                "color": "MOSS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEPSS1",
                "size": "S",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEPMS1",
                "size": "M",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEPLS1",
                "size": "L",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEPXLS1",
                "size": "XL",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEP2XLS1",
                "size": "2XL",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLUSS1",
                "size": "S",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLUMS1",
                "size": "M",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLULS1",
                "size": "L",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLUXLS1",
                "size": "XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLU2XLS1",
                "size": "2XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLU3XLS1",
                "size": "3XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CRUSS1",
                "size": "S",
                "color": "CRUNCHBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CRUMS1",
                "size": "M",
                "color": "CRUNCHBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CRULS1",
                "size": "L",
                "color": "CRUNCHBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CRUXLS1",
                "size": "XL",
                "color": "CRUNCHBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CRU2XLS1",
                "size": "2XL",
                "color": "CRUNCHBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAPSS1",
                "size": "S",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAPMS1",
                "size": "M",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAPLS1",
                "size": "L",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAPXLS1",
                "size": "XL",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAP2XLS1",
                "size": "2XL",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GAP3XLS1",
                "size": "3XL",
                "color": "GRAPHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEYSS1",
                "size": "S",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEYMS1",
                "size": "M",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEYLS1",
                "size": "L",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEYXLS1",
                "size": "XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEY2XLS1",
                "size": "2XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GEY3XLS1",
                "size": "3XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAMSS1",
                "size": "S",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAMMS1",
                "size": "M",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAMLS1",
                "size": "L",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAMXLS1",
                "size": "XL",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAM2XLS1",
                "size": "2XL",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17YAM3XLS1",
                "size": "3XL",
                "color": "YAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAASS1",
                "size": "S",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAAMS1",
                "size": "M",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAALS1",
                "size": "L",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAAXLS1",
                "size": "XL",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAA2XLS1",
                "size": "2XL",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAA3XLS1",
                "size": "3XL",
                "color": "BANANA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAYSS1",
                "size": "S",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAYMS1",
                "size": "M",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAYLS1",
                "size": "L",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAYXLS1",
                "size": "XL",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAY2XLS1",
                "size": "2XL",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BAY3XLS1",
                "size": "3XL",
                "color": "BAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BERSS1",
                "size": "S",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BERMS1",
                "size": "M",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BERLS1",
                "size": "L",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BERXLS1",
                "size": "XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BER2XLS1",
                "size": "2XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BER3XLS1",
                "size": "3XL",
                "color": "BERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLOSS1",
                "size": "S",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLOMS1",
                "size": "M",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLOLS1",
                "size": "L",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLOXLS1",
                "size": "XL",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLO2XLS1",
                "size": "2XL",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BLO3XLS1",
                "size": "3XL",
                "color": "BLOSSOM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSPSS1",
                "size": "S",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSPMS1",
                "size": "M",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSPLS1",
                "size": "L",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSPXLS1",
                "size": "XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSP2XLS1",
                "size": "2XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSP3XLS1",
                "size": "3XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYSSS1",
                "size": "S",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYSMS1",
                "size": "M",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYSLS1",
                "size": "L",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYSXLS1",
                "size": "XL",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYS2XLS1",
                "size": "2XL",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BYS3XLS1",
                "size": "3XL",
                "color": "BOYSENBERRY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRISS1",
                "size": "S",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRIMS1",
                "size": "M",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRILS1",
                "size": "L",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRIXLS1",
                "size": "XL",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRI2XLS1",
                "size": "2XL",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BRI3XLS1",
                "size": "3XL",
                "color": "BRICK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BORSS1",
                "size": "S",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BORMS1",
                "size": "M",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BORLS1",
                "size": "L",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BORXLS1",
                "size": "XL",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BOR2XLS1",
                "size": "2XL",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BOR3XLS1",
                "size": "3XL",
                "color": "BRIGHT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSASS1",
                "size": "S",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSAMS1",
                "size": "M",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSALS1",
                "size": "L",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSAXLS1",
                "size": "XL",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSA2XLS1",
                "size": "2XL",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17BSA3XLS1",
                "size": "3XL",
                "color": "BRIGHT SALMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHISS1",
                "size": "S",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHIMS1",
                "size": "M",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHILS1",
                "size": "L",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHIXLS1",
                "size": "XL",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHI2XLS1",
                "size": "2XL",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CHI3XLS1",
                "size": "3XL",
                "color": "CHILI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBLSS1",
                "size": "S",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBLMS1",
                "size": "M",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBLLS1",
                "size": "L",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBLXLS1",
                "size": "XL",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBL2XLS1",
                "size": "2XL",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17CBL3XLS1",
                "size": "3XL",
                "color": "CHINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBLSS1",
                "size": "S",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBLMS1",
                "size": "M",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBLLS1",
                "size": "L",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBLXLS1",
                "size": "XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBL2XLS1",
                "size": "2XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17FBL3XLS1",
                "size": "3XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRASS1",
                "size": "S",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRAMS1",
                "size": "M",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRALS1",
                "size": "L",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRAXLS1",
                "size": "XL",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRA2XLS1",
                "size": "2XL",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GRA3XLS1",
                "size": "3XL",
                "color": "GRANITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPESS1",
                "size": "S",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPEMS1",
                "size": "M",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPELS1",
                "size": "L",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPEXLS1",
                "size": "XL",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPE2XLS1",
                "size": "2XL",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GPE3XLS1",
                "size": "3XL",
                "color": "GRAPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSSSS1",
                "size": "S",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSSMS1",
                "size": "M",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSSLS1",
                "size": "L",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSSXLS1",
                "size": "XL",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSS2XLS1",
                "size": "2XL",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17GSS3XLS1",
                "size": "3XL",
                "color": "GRASS",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEMSS1",
                "size": "S",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEMMS1",
                "size": "M",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEMLS1",
                "size": "L",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEMXLS1",
                "size": "XL",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEM2XLS1",
                "size": "2XL",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HEM3XLS1",
                "size": "3XL",
                "color": "HEMP",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYDSS1",
                "size": "S",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYDMS1",
                "size": "M",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYDLS1",
                "size": "L",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYDXLS1",
                "size": "XL",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYD2XLS1",
                "size": "2XL",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17HYD3XLS1",
                "size": "3XL",
                "color": "HYDRANGEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBLSS1",
                "size": "S",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBLMS1",
                "size": "M",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBLLS1",
                "size": "L",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBLXLS1",
                "size": "XL",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBL2XLS1",
                "size": "2XL",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IBL3XLS1",
                "size": "3XL",
                "color": "ICE BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGRSS1",
                "size": "S",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGRMS1",
                "size": "M",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGRLS1",
                "size": "L",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGRXLS1",
                "size": "XL",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGR2XLS1",
                "size": "2XL",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IGR3XLS1",
                "size": "3XL",
                "color": "ISLAND GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IRESS1",
                "size": "S",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IREMS1",
                "size": "M",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IRELS1",
                "size": "L",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IREXLS1",
                "size": "XL",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IRE2XLS1",
                "size": "2XL",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17IRE3XLS1",
                "size": "3XL",
                "color": "ISLAND REEF",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHASS1",
                "size": "S",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHAMS1",
                "size": "M",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHALS1",
                "size": "L",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHAXLS1",
                "size": "XL",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHA2XLS1",
                "size": "2XL",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17KHA3XLS1",
                "size": "3XL",
                "color": "KHAKI",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LABSS1",
                "size": "S",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LABMS1",
                "size": "M",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LABLS1",
                "size": "L",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LABXLS1",
                "size": "XL",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LAB2XLS1",
                "size": "2XL",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LAB3XLS1",
                "size": "3XL",
                "color": "LAGOON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGRSS1",
                "size": "S",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGRMS1",
                "size": "M",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGRLS1",
                "size": "L",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGRXLS1",
                "size": "XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGR2XLS1",
                "size": "2XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17LGR3XLS1",
                "size": "3XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MELSS1",
                "size": "S",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MELMS1",
                "size": "M",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MELLS1",
                "size": "L",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MELXLS1",
                "size": "XL",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MEL2XLS1",
                "size": "2XL",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MEL3XLS1",
                "size": "3XL",
                "color": "MELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MIDSS1",
                "size": "S",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MIDMS1",
                "size": "M",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MIDLS1",
                "size": "L",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MIDXLS1",
                "size": "XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MID2XLS1",
                "size": "2XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MID3XLS1",
                "size": "3XL",
                "color": "MIDNIGHT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUSSS1",
                "size": "S",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUSMS1",
                "size": "M",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUSLS1",
                "size": "L",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUSXLS1",
                "size": "XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUS2XLS1",
                "size": "2XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MUS3XLS1",
                "size": "3XL",
                "color": "MUSTARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYBSS1",
                "size": "S",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYBMS1",
                "size": "M",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYBLS1",
                "size": "L",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYBXLS1",
                "size": "XL",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYB2XLS1",
                "size": "2XL",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17MYB3XLS1",
                "size": "3XL",
                "color": "MYSTIC BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAVSS1",
                "size": "S",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAVMS1",
                "size": "M",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAVLS1",
                "size": "L",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAVXLS1",
                "size": "XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAV2XLS1",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NAV3XLS1",
                "size": "3XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBLSS1",
                "size": "S",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBLMS1",
                "size": "M",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBLLS1",
                "size": "L",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBLXLS1",
                "size": "XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBL2XLS1",
                "size": "2XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NBL3XLS1",
                "size": "3XL",
                "color": "NEON BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCASS1",
                "size": "S",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCAMS1",
                "size": "M",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCALS1",
                "size": "L",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCAXLS1",
                "size": "XL",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCA2XLS1",
                "size": "2XL",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NCA3XLS1",
                "size": "3XL",
                "color": "NEON CANTALOUPE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLESS1",
                "size": "S",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLEMS1",
                "size": "M",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLELS1",
                "size": "L",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLEXLS1",
                "size": "XL",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLE2XLS1",
                "size": "2XL",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NLE3XLS1",
                "size": "3XL",
                "color": "NEON LEMON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPISS1",
                "size": "S",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPIMS1",
                "size": "M",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPILS1",
                "size": "L",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPIXLS1",
                "size": "XL",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPI2XLS1",
                "size": "2XL",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NPI3XLS1",
                "size": "3XL",
                "color": "NEON PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NROSS1",
                "size": "S",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NROMS1",
                "size": "M",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NROLS1",
                "size": "L",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NROXLS1",
                "size": "XL",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NRO2XLS1",
                "size": "2XL",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NRO3XLS1",
                "size": "3XL",
                "color": "NEON RED ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVISS1",
                "size": "S",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVIMS1",
                "size": "M",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVILS1",
                "size": "L",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVIXLS1",
                "size": "XL",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVI2XLS1",
                "size": "2XL",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17NVI3XLS1",
                "size": "3XL",
                "color": "NEON VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORCSS1",
                "size": "S",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORCMS1",
                "size": "M",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORCLS1",
                "size": "L",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORCXLS1",
                "size": "XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORC2XLS1",
                "size": "2XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17ORC3XLS1",
                "size": "3XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAPSS1",
                "size": "S",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAPMS1",
                "size": "M",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAPLS1",
                "size": "L",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAPXLS1",
                "size": "XL",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAP2XLS1",
                "size": "2XL",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PAP3XLS1",
                "size": "3XL",
                "color": "PAPRIKA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEASS1",
                "size": "S",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEAMS1",
                "size": "M",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEALS1",
                "size": "L",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEAXLS1",
                "size": "XL",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEA2XLS1",
                "size": "2XL",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PEA3XLS1",
                "size": "3XL",
                "color": "PEACHY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PERSS1",
                "size": "S",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PERMS1",
                "size": "M",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PERLS1",
                "size": "L",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PERXLS1",
                "size": "XL",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PER2XLS1",
                "size": "2XL",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17PER3XLS1",
                "size": "3XL",
                "color": "PERIWINKLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17REDSS1",
                "size": "S",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17REDMS1",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17REDLS1",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17REDXLS1",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RED2XLS1",
                "size": "2XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RED3XLS1",
                "size": "3XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCASS1",
                "size": "S",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCAMS1",
                "size": "M",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCALS1",
                "size": "L",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCAXLS1",
                "size": "XL",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCA2XLS1",
                "size": "2XL",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17RCA3XLS1",
                "size": "3XL",
                "color": "ROYAL CARIBE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAGSS1",
                "size": "S",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAGMS1",
                "size": "M",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAGLS1",
                "size": "L",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAGXLS1",
                "size": "XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAG2XLS1",
                "size": "2XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAG3XLS1",
                "size": "3XL",
                "color": "SAGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SANSS1",
                "size": "S",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SANMS1",
                "size": "M",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SANLS1",
                "size": "L",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SANXLS1",
                "size": "XL",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAN2XLS1",
                "size": "2XL",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAN3XLS1",
                "size": "3XL",
                "color": "SANDSTONE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAPSS1",
                "size": "S",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAPMS1",
                "size": "M",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAPLS1",
                "size": "L",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAPXLS1",
                "size": "XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAP2XLS1",
                "size": "2XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SAP3XLS1",
                "size": "3XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEASS1",
                "size": "S",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEAMS1",
                "size": "M",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEALS1",
                "size": "L",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEAXLS1",
                "size": "XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEA2XLS1",
                "size": "2XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17SEA3XLS1",
                "size": "3XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TERSS1",
                "size": "S",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TERMS1",
                "size": "M",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TERLS1",
                "size": "L",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TERXLS1",
                "size": "XL",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TER2XLS1",
                "size": "2XL",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TER3XLS1",
                "size": "3XL",
                "color": "TERRACOTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBLSS1",
                "size": "S",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBLMS1",
                "size": "M",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBLLS1",
                "size": "L",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBLXLS1",
                "size": "XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBL2XLS1",
                "size": "2XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TBL3XLS1",
                "size": "3XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNASS1",
                "size": "S",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNAMS1",
                "size": "M",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNALS1",
                "size": "L",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNAXLS1",
                "size": "XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNA2XLS1",
                "size": "2XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17TNA3XLS1",
                "size": "3XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYASS1",
                "size": "S",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYAMS1",
                "size": "M",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYALS1",
                "size": "L",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYAXLS1",
                "size": "XL",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYA2XLS1",
                "size": "2XL",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VYA3XLS1",
                "size": "3XL",
                "color": "VINEYARD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIOSS1",
                "size": "S",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIOMS1",
                "size": "M",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIOLS1",
                "size": "L",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIOXLS1",
                "size": "XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIO2XLS1",
                "size": "2XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17VIO3XLS1",
                "size": "3XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDESS1",
                "size": "S",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDEMS1",
                "size": "M",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDELS1",
                "size": "L",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDEXLS1",
                "size": "XL",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDE2XLS1",
                "size": "2XL",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WDE3XLS1",
                "size": "3XL",
                "color": "WASHED DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WATSS1",
                "size": "S",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WATMS1",
                "size": "M",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WATLS1",
                "size": "L",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WATXLS1",
                "size": "XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WAT2XLS1",
                "size": "2XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WAT3XLS1",
                "size": "3XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WINSS1",
                "size": "S",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WINMS1",
                "size": "M",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WINLS1",
                "size": "L",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WINXLS1",
                "size": "XL",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WIN2XLS1",
                "size": "2XL",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC17WIN3XLS1",
                "size": "3XL",
                "color": "WINE",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-ComfortColor-UnisexT-Shirt",
        "sku": "Unisex-ComfortColor-UnisexT-Shirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex Hoodie",
        "variations": [
            {
                "sku": "TCG185HTR2XLS1",
                "size": "2XL",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HTR3XLS1",
                "size": "3XL",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INBSS1",
                "size": "S",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INBMS1",
                "size": "M",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INBLS1",
                "size": "L",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INBXLS1",
                "size": "XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INB2XLS1",
                "size": "2XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INB3XLS1",
                "size": "3XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INB4XLS1",
                "size": "4XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INB5XLS1",
                "size": "5XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INGSS1",
                "size": "S",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INGMS1",
                "size": "M",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INGLS1",
                "size": "L",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185INGXLS1",
                "size": "XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ING2XLS1",
                "size": "2XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ING3XLS1",
                "size": "3XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ING4XLS1",
                "size": "4XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ING5XLS1",
                "size": "5XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MARSS1",
                "size": "S",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MARMS1",
                "size": "M",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MARLS1",
                "size": "L",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MARXLS1",
                "size": "XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MAR2XLS1",
                "size": "2XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MAR3XLS1",
                "size": "3XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MAR4XLS1",
                "size": "4XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MAR5XLS1",
                "size": "5XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIGSS1",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIGMS1",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIGLS1",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIGXLS1",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIG2XLS1",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIG3XLS1",
                "size": "3XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIG4XLS1",
                "size": "4XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185MIG5XLS1",
                "size": "5XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLGSS1",
                "size": "S",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLGMS1",
                "size": "M",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLGLS1",
                "size": "L",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLGXLS1",
                "size": "XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLG2XLS1",
                "size": "2XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185OLG3XLS1",
                "size": "3XL",
                "color": "OLD GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORGSS1",
                "size": "S",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORGMS1",
                "size": "M",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORGLS1",
                "size": "L",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORGXLS1",
                "size": "XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORG2XLS1",
                "size": "2XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORG3XLS1",
                "size": "3XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORG4XLS1",
                "size": "4XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ORG5XLS1",
                "size": "5XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PURSS1",
                "size": "S",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PURMS1",
                "size": "M",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PURLS1",
                "size": "L",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PURXLS1",
                "size": "XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PUR2XLS1",
                "size": "2XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PUR3XLS1",
                "size": "3XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PUR4XLS1",
                "size": "4XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185PUR5XLS1",
                "size": "5XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185REDSS1",
                "size": "S",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185REDMS1",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185REDLS1",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185REDXLS1",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185RED2XLS1",
                "size": "2XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185RED3XLS1",
                "size": "3XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185RED4XLS1",
                "size": "4XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185RED5XLS1",
                "size": "5XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROYSS1",
                "size": "S",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROYMS1",
                "size": "M",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROYLS1",
                "size": "L",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROYXLS1",
                "size": "XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROY2XLS1",
                "size": "2XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROY3XLS1",
                "size": "3XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROY4XLS1",
                "size": "4XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ROY5XLS1",
                "size": "5XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SORSS1",
                "size": "S",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SORMS1",
                "size": "M",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SORLS1",
                "size": "L",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SORXLS1",
                "size": "XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SOR2XLS1",
                "size": "2XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SOR3XLS1",
                "size": "3XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SOR4XLS1",
                "size": "4XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SOR5XLS1",
                "size": "5XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAGSS1",
                "size": "S",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAGMS1",
                "size": "M",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAGLS1",
                "size": "L",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAGXLS1",
                "size": "XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAG2XLS1",
                "size": "2XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAG3XLS1",
                "size": "3XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAG4XLS1",
                "size": "4XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAG5XLS1",
                "size": "5XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPISS1",
                "size": "S",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPIMS1",
                "size": "M",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPILS1",
                "size": "L",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPIXLS1",
                "size": "XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPI2XLS1",
                "size": "2XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPI3XLS1",
                "size": "3XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAPSS1",
                "size": "S",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAPMS1",
                "size": "M",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAPLS1",
                "size": "L",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAPXLS1",
                "size": "XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAP2XLS1",
                "size": "2XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAP3XLS1",
                "size": "3XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAP4XLS1",
                "size": "4XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAP5XLS1",
                "size": "5XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIOSS1",
                "size": "S",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIOMS1",
                "size": "M",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIOLS1",
                "size": "L",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIOXLS1",
                "size": "XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIO2XLS1",
                "size": "2XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185VIO3XLS1",
                "size": "3XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASHSS1",
                "size": "S",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASHMS1",
                "size": "M",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASHLS1",
                "size": "L",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASHXLS1",
                "size": "XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASH2XLS1",
                "size": "2XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASH3XLS1",
                "size": "3XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASH4XLS1",
                "size": "4XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ASH5XLS1",
                "size": "5XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BLSS1",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BLMS1",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BLLS1",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BLXLS1",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BL2XLS1",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BL3XLS1",
                "size": "3XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BL4XLS1",
                "size": "4XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185BL5XLS1",
                "size": "5XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CARSS1",
                "size": "S",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CARMS1",
                "size": "M",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CARLS1",
                "size": "L",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CARXLS1",
                "size": "XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CAR2XLS1",
                "size": "2XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CAR3XLS1",
                "size": "3XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CAR4XLS1",
                "size": "4XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CAR5XLS1",
                "size": "5XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHASS1",
                "size": "S",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHAMS1",
                "size": "M",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHALS1",
                "size": "L",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHAXLS1",
                "size": "XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHA2XLS1",
                "size": "2XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHA3XLS1",
                "size": "3XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHA4XLS1",
                "size": "4XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHA5XLS1",
                "size": "5XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOGSS1",
                "size": "S",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOGMS1",
                "size": "M",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOGLS1",
                "size": "L",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOGXLS1",
                "size": "XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOG2XLS1",
                "size": "2XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOG3XLS1",
                "size": "3XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOG4XLS1",
                "size": "4XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185FOG5XLS1",
                "size": "5XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBUSS1",
                "size": "S",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBUMS1",
                "size": "M",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBULS1",
                "size": "L",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBUXLS1",
                "size": "XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBU2XLS1",
                "size": "2XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBU3XLS1",
                "size": "3XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBU4XLS1",
                "size": "4XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LBU5XLS1",
                "size": "5XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPISS1",
                "size": "S",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPIMS1",
                "size": "M",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPILS1",
                "size": "L",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPIXLS1",
                "size": "XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPI2XLS1",
                "size": "2XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPI3XLS1",
                "size": "3XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPI4XLS1",
                "size": "4XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185LPI5XLS1",
                "size": "5XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAVSS1",
                "size": "S",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAVMS1",
                "size": "M",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAVLS1",
                "size": "L",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAVXLS1",
                "size": "XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAV2XLS1",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAV3XLS1",
                "size": "3XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAV4XLS1",
                "size": "4XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185NAV5XLS1",
                "size": "5XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SANSS1",
                "size": "S",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SANMS1",
                "size": "M",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SANLS1",
                "size": "L",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SANXLS1",
                "size": "XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAN2XLS1",
                "size": "2XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAN3XLS1",
                "size": "3XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAN4XLS1",
                "size": "4XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SAN5XLS1",
                "size": "5XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPOSS1",
                "size": "S",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPOMS1",
                "size": "M",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPOLS1",
                "size": "L",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPOXLS1",
                "size": "XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPO2XLS1",
                "size": "2XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPO3XLS1",
                "size": "3XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPO4XLS1",
                "size": "4XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185SPO5XLS1",
                "size": "5XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHI4XLS1",
                "size": "4XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185WHI5XLS1",
                "size": "5XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHTSS1",
                "size": "S",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHTMS1",
                "size": "M",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHTLS1",
                "size": "L",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHTXLS1",
                "size": "XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHT2XLS1",
                "size": "2XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHT3XLS1",
                "size": "3XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHT4XLS1",
                "size": "4XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DHT5XLS1",
                "size": "5XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACRSS1",
                "size": "S",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACRMS1",
                "size": "M",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACRLS1",
                "size": "L",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACRXLS1",
                "size": "XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACR2XLS1",
                "size": "2XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACR3XLS1",
                "size": "3XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACR4XLS1",
                "size": "4XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ACR5XLS1",
                "size": "5XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANSSS1",
                "size": "S",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANSMS1",
                "size": "M",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANSLS1",
                "size": "L",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANSXLS1",
                "size": "XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANS2XLS1",
                "size": "2XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANS3XLS1",
                "size": "3XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANS4XLS1",
                "size": "4XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185ANS5XLS1",
                "size": "5XL",
                "color": "ANTIQUE SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZSS1",
                "size": "S",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZMS1",
                "size": "M",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZLS1",
                "size": "L",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZXLS1",
                "size": "XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZ2XLS1",
                "size": "2XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185AZ3XLS1",
                "size": "3XL",
                "color": "AZALEA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRESS1",
                "size": "S",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CREMS1",
                "size": "M",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRELS1",
                "size": "L",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CREXLS1",
                "size": "XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRE2XLS1",
                "size": "2XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRE3XLS1",
                "size": "3XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRE4XLS1",
                "size": "4XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CRE5XLS1",
                "size": "5XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHRSS1",
                "size": "S",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHRMS1",
                "size": "M",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHRLS1",
                "size": "L",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHRXLS1",
                "size": "XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHR2XLS1",
                "size": "2XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185CHR3XLS1",
                "size": "3XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCHSS1",
                "size": "S",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCHMS1",
                "size": "M",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCHLS1",
                "size": "L",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCHXLS1",
                "size": "XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCH2XLS1",
                "size": "2XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCH3XLS1",
                "size": "3XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCH4XLS1",
                "size": "4XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185DCH5XLS1",
                "size": "5XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GARSS1",
                "size": "S",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GARMS1",
                "size": "M",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GARLS1",
                "size": "L",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GARXLS1",
                "size": "XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GAR2XLS1",
                "size": "2XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GAR3XLS1",
                "size": "3XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLDSS1",
                "size": "S",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLDMS1",
                "size": "M",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLDLS1",
                "size": "L",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLDXLS1",
                "size": "XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLD2XLS1",
                "size": "2XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLD3XLS1",
                "size": "3XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLD4XLS1",
                "size": "4XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GLD5XLS1",
                "size": "5XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHTSS1",
                "size": "S",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHTMS1",
                "size": "M",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHTLS1",
                "size": "L",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHTXLS1",
                "size": "XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHT2XLS1",
                "size": "2XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHT3XLS1",
                "size": "3XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHT4XLS1",
                "size": "4XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185GHT5XLS1",
                "size": "5XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HECSS1",
                "size": "S",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HECMS1",
                "size": "M",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HECLS1",
                "size": "L",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HECXLS1",
                "size": "XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HEC2XLS1",
                "size": "2XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HEC3XLS1",
                "size": "3XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HEC4XLS1",
                "size": "4XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HEC5XLS1",
                "size": "5XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDMSS1",
                "size": "S",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDMMS1",
                "size": "M",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDMLS1",
                "size": "L",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDMXLS1",
                "size": "XL",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDM2XLS1",
                "size": "2XL",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDM3XLS1",
                "size": "3XL",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDM4XLS1",
                "size": "4XL",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDM5XLS1",
                "size": "5XL",
                "color": "HT SP DRK MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDNSS1",
                "size": "S",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDNMS1",
                "size": "M",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDNLS1",
                "size": "L",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDNXLS1",
                "size": "XL",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDN2XLS1",
                "size": "2XL",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDN3XLS1",
                "size": "3XL",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDN4XLS1",
                "size": "4XL",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDN5XLS1",
                "size": "5XL",
                "color": "HT SPRT DRK NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDGSS1",
                "size": "S",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDGMS1",
                "size": "M",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDGLS1",
                "size": "L",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDGXLS1",
                "size": "XL",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDG2XLS1",
                "size": "2XL",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDG3XLS1",
                "size": "3XL",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDG4XLS1",
                "size": "4XL",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HDG5XLS1",
                "size": "5XL",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSRSS1",
                "size": "S",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSRMS1",
                "size": "M",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSRLS1",
                "size": "L",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSRXLS1",
                "size": "XL",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSR2XLS1",
                "size": "2XL",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HSR3XLS1",
                "size": "3XL",
                "color": "HTH SPT SCRLT RD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HTRSS1",
                "size": "S",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HTRMS1",
                "size": "M",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HTRLS1",
                "size": "L",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG185HTRXLS1",
                "size": "XL",
                "color": "HTHR SPORT ROYAL",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-Gildan-Hoodie",
        "sku": "Unisex-Gildan-Hoodie",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex Sweatshirt",
        "variations": [
            {
                "sku": "TCG18ASHSS1",
                "size": "S",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASHMS1",
                "size": "M",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASHLS1",
                "size": "L",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASHXLS1",
                "size": "XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASH2XLS1",
                "size": "2XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASH3XLS1",
                "size": "3XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASH4XLS1",
                "size": "4XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ASH5XLS1",
                "size": "5XL",
                "color": "ASH",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BLSS1",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BLMS1",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BLLS1",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BLXLS1",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BL2XLS1",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BL3XLS1",
                "size": "3XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BL4XLS1",
                "size": "4XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18BL5XLS1",
                "size": "5XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAHSS1",
                "size": "S",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAHMS1",
                "size": "M",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAHLS1",
                "size": "L",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAHXLS1",
                "size": "XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAH2XLS1",
                "size": "2XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DAH3XLS1",
                "size": "3XL",
                "color": "DARK HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOGSS1",
                "size": "S",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOGMS1",
                "size": "M",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOGLS1",
                "size": "L",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOGXLS1",
                "size": "XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOG2XLS1",
                "size": "2XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOG3XLS1",
                "size": "3XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOG4XLS1",
                "size": "4XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18FOG5XLS1",
                "size": "5XL",
                "color": "FOREST GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBUSS1",
                "size": "S",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBUMS1",
                "size": "M",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBULS1",
                "size": "L",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBUXLS1",
                "size": "XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBU2XLS1",
                "size": "2XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LBU3XLS1",
                "size": "3XL",
                "color": "LIGHT BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPISS1",
                "size": "S",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPIMS1",
                "size": "M",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPILS1",
                "size": "L",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPIXLS1",
                "size": "XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPI2XLS1",
                "size": "2XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18LPI3XLS1",
                "size": "3XL",
                "color": "LIGHT PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MROSS1",
                "size": "S",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MROMS1",
                "size": "M",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MROLS1",
                "size": "L",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MROXLS1",
                "size": "XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MRO2XLS1",
                "size": "2XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MRO3XLS1",
                "size": "3XL",
                "color": "MAROON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILISS1",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILIMS1",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILILS1",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILIXLS1",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILI2XLS1",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18MILI3XLS1",
                "size": "3XL",
                "color": "MILITARY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAVSS1",
                "size": "S",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAVMS1",
                "size": "M",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAVLS1",
                "size": "L",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAVXLS1",
                "size": "XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAV2XLS1",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18NAV3XLS1",
                "size": "3XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18REDSS1",
                "size": "S",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18REDMS1",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18REDLS1",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18REDXLS1",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18RED2XLS1",
                "size": "2XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18RED3XLS1",
                "size": "3XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18RED4XLS1",
                "size": "4XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18RED5XLS1",
                "size": "5XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROYSS1",
                "size": "S",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROYMS1",
                "size": "M",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROYLS1",
                "size": "L",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROYXLS1",
                "size": "XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROY2XLS1",
                "size": "2XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROY3XLS1",
                "size": "3XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROY4XLS1",
                "size": "4XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ROY5XLS1",
                "size": "5XL",
                "color": "ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SANSS1",
                "size": "S",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SANMS1",
                "size": "M",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SANLS1",
                "size": "L",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SANXLS1",
                "size": "XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAN2XLS1",
                "size": "2XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAN3XLS1",
                "size": "3XL",
                "color": "SAND",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPOSS1",
                "size": "S",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPOMS1",
                "size": "M",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPOLS1",
                "size": "L",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPOXLS1",
                "size": "XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPO2XLS1",
                "size": "2XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPO3XLS1",
                "size": "3XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPO4XLS1",
                "size": "4XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPO5XLS1",
                "size": "5XL",
                "color": "SPORT GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHI4XLS1",
                "size": "4XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18WHI5XLS1",
                "size": "5XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACRSS1",
                "size": "S",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACRMS1",
                "size": "M",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACRLS1",
                "size": "L",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACRXLS1",
                "size": "XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACR2XLS1",
                "size": "2XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACR3XLS1",
                "size": "3XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACR4XLS1",
                "size": "4XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ACR5XLS1",
                "size": "5XL",
                "color": "ANTIQ CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRESS1",
                "size": "S",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CREMS1",
                "size": "M",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRELS1",
                "size": "L",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CREXLS1",
                "size": "XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRE2XLS1",
                "size": "2XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRE3XLS1",
                "size": "3XL",
                "color": "CARDINAL RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBUSS1",
                "size": "S",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBUMS1",
                "size": "M",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBULS1",
                "size": "L",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBUXLS1",
                "size": "XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBU2XLS1",
                "size": "2XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CBU3XLS1",
                "size": "3XL",
                "color": "CAROLINA BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHASS1",
                "size": "S",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHAMS1",
                "size": "M",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHALS1",
                "size": "L",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHAXLS1",
                "size": "XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHA2XLS1",
                "size": "2XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHA3XLS1",
                "size": "3XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHA4XLS1",
                "size": "4XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CHA5XLS1",
                "size": "5XL",
                "color": "CHARCOAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRRSS1",
                "size": "S",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRRMS1",
                "size": "M",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRRLS1",
                "size": "L",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRRXLS1",
                "size": "XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRR2XLS1",
                "size": "2XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18CRR3XLS1",
                "size": "3XL",
                "color": "CHERRY RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCHSS1",
                "size": "S",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCHMS1",
                "size": "M",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCHLS1",
                "size": "L",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCHXLS1",
                "size": "XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCH2XLS1",
                "size": "2XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCH3XLS1",
                "size": "3XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCH4XLS1",
                "size": "4XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18DCH5XLS1",
                "size": "5XL",
                "color": "DARK CHOCOLATE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GARSS1",
                "size": "S",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GARMS1",
                "size": "M",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GARLS1",
                "size": "L",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GARXLS1",
                "size": "XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GAR2XLS1",
                "size": "2XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GAR3XLS1",
                "size": "3XL",
                "color": "GARNET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLDSS1",
                "size": "S",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLDMS1",
                "size": "M",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLDLS1",
                "size": "L",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLDXLS1",
                "size": "XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLD2XLS1",
                "size": "2XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GLD3XLS1",
                "size": "3XL",
                "color": "GOLD",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHTSS1",
                "size": "S",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHTMS1",
                "size": "M",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHTLS1",
                "size": "L",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHTXLS1",
                "size": "XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHT2XLS1",
                "size": "2XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHT3XLS1",
                "size": "3XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHT4XLS1",
                "size": "4XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18GHT5XLS1",
                "size": "5XL",
                "color": "GRAPHITE HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLISS1",
                "size": "S",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLIMS1",
                "size": "M",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLILS1",
                "size": "L",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLIXLS1",
                "size": "XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLI2XLS1",
                "size": "2XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLI3XLS1",
                "size": "3XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLI4XLS1",
                "size": "4XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HLI5XLS1",
                "size": "5XL",
                "color": "HELICONIA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18HDGLS1",
                "size": "L",
                "color": "HTH SP DRK GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INBSS1",
                "size": "S",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INBMS1",
                "size": "M",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INBLS1",
                "size": "L",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INBXLS1",
                "size": "XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INB2XLS1",
                "size": "2XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18INB3XLS1",
                "size": "3XL",
                "color": "INDIGO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRGSS1",
                "size": "S",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRGMS1",
                "size": "M",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRGLS1",
                "size": "L",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRGXLS1",
                "size": "XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRG2XLS1",
                "size": "2XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRG3XLS1",
                "size": "3XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRG4XLS1",
                "size": "4XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18IRG5XLS1",
                "size": "5XL",
                "color": "IRISH GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORGSS1",
                "size": "S",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORGMS1",
                "size": "M",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORGLS1",
                "size": "L",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORGXLS1",
                "size": "XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORG2XLS1",
                "size": "2XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORG3XLS1",
                "size": "3XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORG4XLS1",
                "size": "4XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18ORG5XLS1",
                "size": "5XL",
                "color": "ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PURSS1",
                "size": "S",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PURMS1",
                "size": "M",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PURLS1",
                "size": "L",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PURXLS1",
                "size": "XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PUR2XLS1",
                "size": "2XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18PUR3XLS1",
                "size": "3XL",
                "color": "PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SORSS1",
                "size": "S",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SORMS1",
                "size": "M",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SORLS1",
                "size": "L",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SORXLS1",
                "size": "XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SOR2XLS1",
                "size": "2XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SOR3XLS1",
                "size": "3XL",
                "color": "S ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAGSS1",
                "size": "S",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAGMS1",
                "size": "M",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAGLS1",
                "size": "L",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAGXLS1",
                "size": "XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAG2XLS1",
                "size": "2XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAG3XLS1",
                "size": "3XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAG4XLS1",
                "size": "4XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAG5XLS1",
                "size": "5XL",
                "color": "SAFETY GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPISS1",
                "size": "S",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPIMS1",
                "size": "M",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPILS1",
                "size": "L",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPIXLS1",
                "size": "XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPI2XLS1",
                "size": "2XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SPI3XLS1",
                "size": "3XL",
                "color": "SAFETY PINK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAPSS1",
                "size": "S",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAPMS1",
                "size": "M",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAPLS1",
                "size": "L",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAPXLS1",
                "size": "XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAP2XLS1",
                "size": "2XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCG18SAP3XLS1",
                "size": "3XL",
                "color": "SAPPHIRE",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-Gildan-Sweatshirt",
        "sku": "Unisex-Gildan-Sweatshirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex Sweatshirt",
        "variations": [
            {
                "sku": "TCC1566TNAXLS1",
                "size": "XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJEMS1",
                "size": "M",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJELS1",
                "size": "L",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORC2XLS1",
                "size": "2XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORC3XLS1",
                "size": "3XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEPSS1",
                "size": "S",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEPMS1",
                "size": "M",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEPLS1",
                "size": "L",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEPXLS1",
                "size": "XL",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEP2XLS1",
                "size": "2XL",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566PEP3XLS1",
                "size": "3XL",
                "color": "PEPPER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEASS1",
                "size": "S",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEAMS1",
                "size": "M",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEALS1",
                "size": "L",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEAXLS1",
                "size": "XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEA2XLS1",
                "size": "2XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566SEA3XLS1",
                "size": "3XL",
                "color": "SEAFOAM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TERSS1",
                "size": "S",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TERMS1",
                "size": "M",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TERLS1",
                "size": "L",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TERXLS1",
                "size": "XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TER2XLS1",
                "size": "2XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TER3XLS1",
                "size": "3XL",
                "color": "TERRACOTTA",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOPSS1",
                "size": "S",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOPMS1",
                "size": "M",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOPLS1",
                "size": "L",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOPXLS1",
                "size": "XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOP2XLS1",
                "size": "2XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TOP3XLS1",
                "size": "3XL",
                "color": "TOPAZ BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TNASS1",
                "size": "S",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TNAMS1",
                "size": "M",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TNALS1",
                "size": "L",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJESS1",
                "size": "S",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TNA2XLS1",
                "size": "2XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566TNA3XLS1",
                "size": "3XL",
                "color": "TRUE NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIOSS1",
                "size": "S",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIOMS1",
                "size": "M",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIOLS1",
                "size": "L",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIOXLS1",
                "size": "XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIO2XLS1",
                "size": "2XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566VIO3XLS1",
                "size": "3XL",
                "color": "VIOLET",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WATSS1",
                "size": "S",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WATMS1",
                "size": "M",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WATLS1",
                "size": "L",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WATXLS1",
                "size": "XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WAT2XLS1",
                "size": "2XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WAT3XLS1",
                "size": "3XL",
                "color": "WATERMELON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHISS1",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHIMS1",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHILS1",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHIXLS1",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHI2XLS1",
                "size": "2XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566WHI3XLS1",
                "size": "3XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJEXLS1",
                "size": "XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJE2XLS1",
                "size": "2XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BJE3XLS1",
                "size": "3XL",
                "color": "BLUE JEAN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSPSS1",
                "size": "S",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSPMS1",
                "size": "M",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSPLS1",
                "size": "L",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSPXLS1",
                "size": "XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSP2XLS1",
                "size": "2XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BSP3XLS1",
                "size": "3XL",
                "color": "BLUE SPRUCE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTTSS1",
                "size": "S",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTTMS1",
                "size": "M",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTTLS1",
                "size": "L",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTTXLS1",
                "size": "XL",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTT2XLS1",
                "size": "2XL",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566BTT3XLS1",
                "size": "3XL",
                "color": "BUTTER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMISS1",
                "size": "S",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMIMS1",
                "size": "M",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMILS1",
                "size": "L",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMIXLS1",
                "size": "XL",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMI2XLS1",
                "size": "2XL",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CMI3XLS1",
                "size": "3XL",
                "color": "CHALKY MINT",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBRSS1",
                "size": "S",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBRMS1",
                "size": "M",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBRLS1",
                "size": "L",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBRXLS1",
                "size": "XL",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBR2XLS1",
                "size": "2XL",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CBR3XLS1",
                "size": "3XL",
                "color": "CHAMBRAY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRISS1",
                "size": "S",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRIMS1",
                "size": "M",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRILS1",
                "size": "L",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRIXLS1",
                "size": "XL",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRI2XLS1",
                "size": "2XL",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566CRI3XLS1",
                "size": "3XL",
                "color": "CRIMSON",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DENSS1",
                "size": "S",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DENMS1",
                "size": "M",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DENLS1",
                "size": "L",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DENXLS1",
                "size": "XL",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DEN2XLS1",
                "size": "2XL",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566DEN3XLS1",
                "size": "3XL",
                "color": "DENIM",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLBSS1",
                "size": "S",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLBMS1",
                "size": "M",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLBLS1",
                "size": "L",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLBXLS1",
                "size": "XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLB2XLS1",
                "size": "2XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566FLB3XLS1",
                "size": "3XL",
                "color": "FLO BLUE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRYSS1",
                "size": "S",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRYMS1",
                "size": "M",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRYLS1",
                "size": "L",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRYXLS1",
                "size": "XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRY2XLS1",
                "size": "2XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566GRY3XLS1",
                "size": "3XL",
                "color": "GREY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGRSS1",
                "size": "S",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGRMS1",
                "size": "M",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGRLS1",
                "size": "L",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGRXLS1",
                "size": "XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGR2XLS1",
                "size": "2XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566LGR3XLS1",
                "size": "3XL",
                "color": "LIGHT GREEN",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORCSS1",
                "size": "S",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORCMS1",
                "size": "M",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORCLS1",
                "size": "L",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "TCC1566ORCXLS1",
                "size": "XL",
                "color": "ORCHID",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex-ComfortColor-Sweatshirt",
        "sku": "Unisex-ComfortColor-Sweatshirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    }
]

export default teecraft
