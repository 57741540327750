const mango = [
    {
        "category": "Kids",
        "variations": [
            {
                "sku": "YOGS1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1B00S",
                "size": "S",
                "color": "Black",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS5R0XS",
                "size": "XS",
                "color": "Maroon",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "YOGS5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "YOGS5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "YOGS5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "YOGS5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4H0XS",
                "size": "XS",
                "color": "Sport Grey",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 228,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1W00S",
                "size": "S",
                "color": "White",
                "stock": 124,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1W00L",
                "size": "L",
                "color": "White",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2K0XS",
                "size": "XS",
                "color": "Dark Heather",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2F0XS",
                "size": "XS",
                "color": "Forest Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS8K0XS",
                "size": "XS",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS8K00S",
                "size": "S",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS8K00M",
                "size": "M",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS8K00L",
                "size": "L",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS8K0XL",
                "size": "XL",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1R00S",
                "size": "S",
                "color": "Red",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1R00M",
                "size": "M",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1R00L",
                "size": "L",
                "color": "Red",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "YOGS1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "YOGS7C0XS",
                "size": "XS",
                "color": "Royal",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "YOGS7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "YOGS7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "YOGS7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "YOGS7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4S0XS",
                "size": "XS",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4S00S",
                "size": "S",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4S00M",
                "size": "M",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4S00L",
                "size": "L",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGS4S0XL",
                "size": "XL",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth Sweatshirt | G18000B",
        "sku": "Youth-Gildan-YouthSweatshirt",
        "id": "bd436bac-9ae7-40ec-a5af-676eb2877b38"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "BCHDM700S",
                "size": "S",
                "color": "STORM",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM700M",
                "size": "M",
                "color": "STORM",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM700L",
                "size": "L",
                "color": "STORM",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM70XL",
                "size": "XL",
                "color": "STORM",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM72XL",
                "size": "2XL",
                "color": "STORM",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00S",
                "size": "S",
                "color": "ATHLETIC HEATHER",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000S",
                "size": "S",
                "color": "PEACH",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00M",
                "size": "M",
                "color": "ATHLETIC HEATHER",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000M",
                "size": "M",
                "color": "PEACH",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00L",
                "size": "L",
                "color": "ATHLETIC HEATHER",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000L",
                "size": "L",
                "color": "PEACH",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H0XL",
                "size": "XL",
                "color": "ATHLETIC HEATHER",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ00XL",
                "size": "XL",
                "color": "PEACH",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H2XL",
                "size": "2XL",
                "color": "ATHLETIC HEATHER",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ02XL",
                "size": "2XL",
                "color": "PEACH",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00S",
                "size": "S",
                "color": "ASH",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00M",
                "size": "M",
                "color": "ASH",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00L",
                "size": "L",
                "color": "ASH",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W0XL",
                "size": "XL",
                "color": "ASH",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W2XL",
                "size": "2XL",
                "color": "ASH",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400S",
                "size": "S",
                "color": "DTG BLACK",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400M",
                "size": "M",
                "color": "DTG BLACK",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400L",
                "size": "L",
                "color": "DTG BLACK",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE40XL",
                "size": "XL",
                "color": "DTG BLACK",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE42XL",
                "size": "2XL",
                "color": "DTG BLACK",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500S",
                "size": "S",
                "color": "DTG DARK GREY",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500M",
                "size": "M",
                "color": "DTG DARK GREY",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500L",
                "size": "L",
                "color": "DTG DARK GREY",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE50XL",
                "size": "XL",
                "color": "DTG DARK GREY",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE52XL",
                "size": "2XL",
                "color": "DTG DARK GREY",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00S",
                "size": "S",
                "color": "GOLD",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00M",
                "size": "M",
                "color": "GOLD",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00L",
                "size": "L",
                "color": "GOLD",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y0XL",
                "size": "XL",
                "color": "GOLD",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y2XL",
                "size": "2XL",
                "color": "GOLD",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800S",
                "size": "S",
                "color": "LILAC",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800M",
                "size": "M",
                "color": "LILAC",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800L",
                "size": "L",
                "color": "LILAC",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH80XL",
                "size": "XL",
                "color": "LILAC",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH82XL",
                "size": "2XL",
                "color": "LILAC",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000S",
                "size": "S",
                "color": "MAUVE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000M",
                "size": "M",
                "color": "MAUVE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000L",
                "size": "L",
                "color": "MAUVE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH00XL",
                "size": "XL",
                "color": "MAUVE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH02XL",
                "size": "2XL",
                "color": "MAUVE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00S",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00M",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00L",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F0XL",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F2XL",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00S",
                "size": "S",
                "color": "NAVY",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00M",
                "size": "M",
                "color": "NAVY",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00L",
                "size": "L",
                "color": "NAVY",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N0XL",
                "size": "XL",
                "color": "NAVY",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N2XL",
                "size": "2XL",
                "color": "NAVY",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000S",
                "size": "S",
                "color": "TAN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000M",
                "size": "M",
                "color": "TAN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000L",
                "size": "L",
                "color": "TAN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM00XL",
                "size": "XL",
                "color": "TAN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM02XL",
                "size": "2XL",
                "color": "TAN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900S",
                "size": "S",
                "color": "VINTAGE WHITE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900M",
                "size": "M",
                "color": "VINTAGE WHITE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900L",
                "size": "L",
                "color": "VINTAGE WHITE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN90XL",
                "size": "XL",
                "color": "VINTAGE WHITE",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN92XL",
                "size": "2XL",
                "color": "VINTAGE WHITE",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00S",
                "size": "S",
                "color": "WHITE",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00M",
                "size": "M",
                "color": "WHITE",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00L",
                "size": "L",
                "color": "WHITE",
                "stock": 16,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W0XL",
                "size": "XL",
                "color": "WHITE",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W2XL",
                "size": "2XL",
                "color": "WHITE",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00S",
                "size": "S",
                "color": "YELLOW",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00M",
                "size": "M",
                "color": "YELLOW",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00L",
                "size": "L",
                "color": "YELLOW",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y0XL",
                "size": "XL",
                "color": "YELLOW",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y2XL",
                "size": "2XL",
                "color": "YELLOW",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP52XL",
                "size": "2XL",
                "color": "HEATHER DEEP TEAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500L",
                "size": "L",
                "color": "HEATHER DEEP TEAL",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500M",
                "size": "M",
                "color": "HEATHER DEEP TEAL",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500S",
                "size": "S",
                "color": "HEATHER DEEP TEAL",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP50XL",
                "size": "XL",
                "color": "HEATHER DEEP TEAL",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP62XL",
                "size": "2XL",
                "color": "HEATHER FOREST",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP60XL",
                "size": "XL",
                "color": "HEATHER FOREST",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600L",
                "size": "L",
                "color": "HEATHER FOREST",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600M",
                "size": "M",
                "color": "HEATHER FOREST",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600S",
                "size": "S",
                "color": "HEATHER FOREST",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R2XL",
                "size": "2XL",
                "color": "RED",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R0XL",
                "size": "XL",
                "color": "RED",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00L",
                "size": "L",
                "color": "RED",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00M",
                "size": "M",
                "color": "RED",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00S",
                "size": "S",
                "color": "RED",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H2XL",
                "size": "2XL",
                "color": "DEEP HEATHER",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H0XL",
                "size": "XL",
                "color": "DEEP HEATHER",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00L",
                "size": "L",
                "color": "DEEP HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00M",
                "size": "M",
                "color": "DEEP HEATHER",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00S",
                "size": "S",
                "color": "DEEP HEATHER",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00S",
                "size": "S",
                "color": "TRUE ROYAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00M",
                "size": "M",
                "color": "TRUE ROYAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00L",
                "size": "L",
                "color": "TRUE ROYAL",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C0XL",
                "size": "XL",
                "color": "TRUE ROYAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C2XL",
                "size": "2XL",
                "color": "TRUE ROYAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00S",
                "size": "S",
                "color": "BLACK",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00M",
                "size": "M",
                "color": "BLACK",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00L",
                "size": "L",
                "color": "BLACK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B0XL",
                "size": "XL",
                "color": "BLACK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B2XL",
                "size": "2XL",
                "color": "BLACK",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00S",
                "size": "S",
                "color": "DARK GREY HEATHER",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00M",
                "size": "M",
                "color": "DARK GREY HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00L",
                "size": "L",
                "color": "DARK GREY HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K0XL",
                "size": "XL",
                "color": "DARK GREY HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K2XL",
                "size": "2XL",
                "color": "DARK GREY HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K2XL",
                "size": "2XL",
                "color": "BLACK HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K0XL",
                "size": "XL",
                "color": "BLACK HEATHER",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00L",
                "size": "L",
                "color": "BLACK HEATHER",
                "stock": 1,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00M",
                "size": "M",
                "color": "BLACK HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00S",
                "size": "S",
                "color": "BLACK HEATHER",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR90XS",
                "size": "XS",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900S",
                "size": "S",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900M",
                "size": "M",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900L",
                "size": "L",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR90XL",
                "size": "XL",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR92XL",
                "size": "2XL",
                "color": "HEATHER CHARITY PINK",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Fleece Hoodie - Bella + Canvas | 3719",
        "sku": "Unisex-BellaCanvas-Hoodie",
        "id": "9a34333d-ee99-4f31-9a66-a3cf5933e689"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "CCHDA000S",
                "size": "S",
                "color": "Blue Jean",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "CCHDA000M",
                "size": "M",
                "color": "Blue Jean",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "CCHDA000L",
                "size": "L",
                "color": "Blue Jean",
                "stock": 199,
                "status": "In Stock"
            },
            {
                "sku": "CCHDA00XL",
                "size": "XL",
                "color": "Blue Jean",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "CCHDA02XL",
                "size": "2XL",
                "color": "Blue Jean",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "CCHD8S00S",
                "size": "S",
                "color": "Crimson",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "CCHD8S00M",
                "size": "M",
                "color": "Crimson",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "CCHD8S00L",
                "size": "L",
                "color": "Crimson",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "CCHD8S0XL",
                "size": "XL",
                "color": "Crimson",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "CCHD8S2XL",
                "size": "2XL",
                "color": "Crimson",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "CCHD6L00S",
                "size": "S",
                "color": "Denim",
                "stock": 121,
                "status": "In Stock"
            },
            {
                "sku": "CCHD6L00M",
                "size": "M",
                "color": "Denim",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "CCHD6L00L",
                "size": "L",
                "color": "Denim",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCHD6L0XL",
                "size": "XL",
                "color": "Denim",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "CCHD6L2XL",
                "size": "2XL",
                "color": "Denim",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "CCHDF500S",
                "size": "S",
                "color": "Flo Blue",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "CCHDF500M",
                "size": "M",
                "color": "Flo Blue",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCHDF500L",
                "size": "L",
                "color": "Flo Blue",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "CCHDF50XL",
                "size": "XL",
                "color": "Flo Blue",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "CCHDF52XL",
                "size": "2XL",
                "color": "Flo Blue",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "CCHD9H00S",
                "size": "S",
                "color": "Grey",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCHD9H00M",
                "size": "M",
                "color": "Grey",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "CCHD9H00L",
                "size": "L",
                "color": "Grey",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "CCHD9H0XL",
                "size": "XL",
                "color": "Grey",
                "stock": 88,
                "status": "In Stock"
            },
            {
                "sku": "CCHD9H2XL",
                "size": "2XL",
                "color": "Grey",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "CCHDK300S",
                "size": "S",
                "color": "Pepper",
                "stock": 142,
                "status": "In Stock"
            },
            {
                "sku": "CCHDK300M",
                "size": "M",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCHDK300L",
                "size": "L",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCHDK30XL",
                "size": "XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCHDK32XL",
                "size": "2XL",
                "color": "Pepper",
                "stock": 107,
                "status": "In Stock"
            },
            {
                "sku": "CCHDL200S",
                "size": "S",
                "color": "Seafoam",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "CCHDL200M",
                "size": "M",
                "color": "Seafoam",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "CCHDL200L",
                "size": "L",
                "color": "Seafoam",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "CCHDL20XL",
                "size": "XL",
                "color": "Seafoam",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "CCHDL22XL",
                "size": "2XL",
                "color": "Seafoam",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCHDN600S",
                "size": "S",
                "color": "True Navy",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "CCHDN600M",
                "size": "M",
                "color": "True Navy",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCHDN600L",
                "size": "L",
                "color": "True Navy",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCHDN60XL",
                "size": "XL",
                "color": "True Navy",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "CCHDN62XL",
                "size": "2XL",
                "color": "True Navy",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCHD1W00S",
                "size": "S",
                "color": "White",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "CCHD1W00M",
                "size": "M",
                "color": "White",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "CCHD1W00L",
                "size": "L",
                "color": "White",
                "stock": 206,
                "status": "In Stock"
            },
            {
                "sku": "CCHD1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "CCHD1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "CCHDB200S",
                "size": "S",
                "color": "Blue Spruce",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCHDB200M",
                "size": "M",
                "color": "Blue Spruce",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCHDB200L",
                "size": "L",
                "color": "Blue Spruce",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCHDB20XL",
                "size": "XL",
                "color": "Blue Spruce",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCHDB22XL",
                "size": "2XL",
                "color": "Blue Spruce",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Hoodie - Comfort Color | 1567",
        "sku": "Unisex-ComfortColor-Hoodie",
        "id": "677a7981-f1b2-467d-97f9-b8ec389e694c"
    },
    // {
    //     "category": "Unisex",
    //     "variations": [
    //         {
    //             "sku": "UNVN2H3XL",
    //             "size": "3XL",
    //             "color": "Athletic Heather",
    //             "stock": 50,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H0XS",
    //             "size": "XS",
    //             "color": "Athletic Heather",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B3XL",
    //             "size": "3XL",
    //             "color": "Black",
    //             "stock": 45,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B0XS",
    //             "size": "XS",
    //             "color": "Black",
    //             "stock": 33,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K3XL",
    //             "size": "3XL",
    //             "color": "Dark Grey Heather",
    //             "stock": 106,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K0XS",
    //             "size": "XS",
    //             "color": "Dark Grey Heather",
    //             "stock": 77,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H3XL",
    //             "size": "3XL",
    //             "color": "Deep Heather",
    //             "stock": 43,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H0XS",
    //             "size": "XS",
    //             "color": "Deep Heather",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C3XL",
    //             "size": "3XL",
    //             "color": "Heather True Royal",
    //             "stock": 57,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C0XS",
    //             "size": "XS",
    //             "color": "Heather True Royal",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R2XL",
    //             "size": "2XL",
    //             "color": "Maroon",
    //             "stock": 14,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R3XL",
    //             "size": "3XL",
    //             "color": "Maroon",
    //             "stock": 9,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R00L",
    //             "size": "L",
    //             "color": "Maroon",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R00M",
    //             "size": "M",
    //             "color": "Maroon",
    //             "stock": 83,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R00S",
    //             "size": "S",
    //             "color": "Maroon",
    //             "stock": 74,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R0XL",
    //             "size": "XL",
    //             "color": "Maroon",
    //             "stock": 80,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5R0XS",
    //             "size": "XS",
    //             "color": "Maroon",
    //             "stock": 75,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N3XL",
    //             "size": "3XL",
    //             "color": "Navy",
    //             "stock": 59,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N0XS",
    //             "size": "XS",
    //             "color": "Navy",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O2XL",
    //             "size": "2XL",
    //             "color": "Orange",
    //             "stock": 67,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O3XL",
    //             "size": "3XL",
    //             "color": "Orange",
    //             "stock": 57,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O00L",
    //             "size": "L",
    //             "color": "Orange",
    //             "stock": 66,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O00M",
    //             "size": "M",
    //             "color": "Orange",
    //             "stock": 67,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O00S",
    //             "size": "S",
    //             "color": "Orange",
    //             "stock": 69,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O0XL",
    //             "size": "XL",
    //             "color": "Orange",
    //             "stock": 64,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1O0XS",
    //             "size": "XS",
    //             "color": "Orange",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R3XL",
    //             "size": "3XL",
    //             "color": "Red",
    //             "stock": 10,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R0XS",
    //             "size": "XS",
    //             "color": "Red",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D2XL",
    //             "size": "2XL",
    //             "color": "Steel Blue",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D3XL",
    //             "size": "3XL",
    //             "color": "Steel Blue",
    //             "stock": 60,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D00L",
    //             "size": "L",
    //             "color": "Steel Blue",
    //             "stock": 68,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D00M",
    //             "size": "M",
    //             "color": "Steel Blue",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D00S",
    //             "size": "S",
    //             "color": "Steel Blue",
    //             "stock": 71,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D0XL",
    //             "size": "XL",
    //             "color": "Steel Blue",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9D0XS",
    //             "size": "XS",
    //             "color": "Steel Blue",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q3XL",
    //             "size": "3XL",
    //             "color": "Team Purple",
    //             "stock": 58,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q0XS",
    //             "size": "XS",
    //             "color": "Team Purple",
    //             "stock": 8,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C3XL",
    //             "size": "3XL",
    //             "color": "True Royal",
    //             "stock": 112,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C0XS",
    //             "size": "XS",
    //             "color": "True Royal",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W3XL",
    //             "size": "3XL",
    //             "color": "White",
    //             "stock": 78,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W0XS",
    //             "size": "XS",
    //             "color": "White",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N2XL",
    //             "size": "2XL",
    //             "color": "Heather Navy",
    //             "stock": 90,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R2XL",
    //             "size": "2XL",
    //             "color": "Heather Red",
    //             "stock": 20,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R00M",
    //             "size": "M",
    //             "color": "Heather Red",
    //             "stock": 65,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R0XS",
    //             "size": "XS",
    //             "color": "Heather Red",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H2XL",
    //             "size": "2XL",
    //             "color": "Athletic Heather",
    //             "stock": 32,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H00L",
    //             "size": "L",
    //             "color": "Athletic Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H00M",
    //             "size": "M",
    //             "color": "Athletic Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H00S",
    //             "size": "S",
    //             "color": "Athletic Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2H0XL",
    //             "size": "XL",
    //             "color": "Athletic Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B2XL",
    //             "size": "2XL",
    //             "color": "Black",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B00L",
    //             "size": "L",
    //             "color": "Black",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B00M",
    //             "size": "M",
    //             "color": "Black",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B00S",
    //             "size": "S",
    //             "color": "Black",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1B0XL",
    //             "size": "XL",
    //             "color": "Black",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K2XL",
    //             "size": "2XL",
    //             "color": "Dark Grey Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K00L",
    //             "size": "L",
    //             "color": "Dark Grey Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K00M",
    //             "size": "M",
    //             "color": "Dark Grey Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K00S",
    //             "size": "S",
    //             "color": "Dark Grey Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1K0XL",
    //             "size": "XL",
    //             "color": "Dark Grey Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H2XL",
    //             "size": "2XL",
    //             "color": "Deep Heather",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H00L",
    //             "size": "L",
    //             "color": "Deep Heather",
    //             "stock": 177,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H00M",
    //             "size": "M",
    //             "color": "Deep Heather",
    //             "stock": 217,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H00S",
    //             "size": "S",
    //             "color": "Deep Heather",
    //             "stock": 152,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN6H0XL",
    //             "size": "XL",
    //             "color": "Deep Heather",
    //             "stock": 135,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N3XL",
    //             "size": "3XL",
    //             "color": "Heather Navy",
    //             "stock": 60,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N00L",
    //             "size": "L",
    //             "color": "Heather Navy",
    //             "stock": 76,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N00M",
    //             "size": "M",
    //             "color": "Heather Navy",
    //             "stock": 120,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N00S",
    //             "size": "S",
    //             "color": "Heather Navy",
    //             "stock": 79,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N0XL",
    //             "size": "XL",
    //             "color": "Heather Navy",
    //             "stock": 57,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN9N0XS",
    //             "size": "XS",
    //             "color": "Heather Navy",
    //             "stock": 72,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R3XL",
    //             "size": "3XL",
    //             "color": "Heather Red",
    //             "stock": 59,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R00L",
    //             "size": "L",
    //             "color": "Heather Red",
    //             "stock": 63,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R00S",
    //             "size": "S",
    //             "color": "Heather Red",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN2R0XL",
    //             "size": "XL",
    //             "color": "Heather Red",
    //             "stock": 63,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C2XL",
    //             "size": "2XL",
    //             "color": "Heather True Royal",
    //             "stock": 188,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C00L",
    //             "size": "L",
    //             "color": "Heather True Royal",
    //             "stock": 183,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C00M",
    //             "size": "M",
    //             "color": "Heather True Royal",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C00S",
    //             "size": "S",
    //             "color": "Heather True Royal",
    //             "stock": 82,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3C0XL",
    //             "size": "XL",
    //             "color": "Heather True Royal",
    //             "stock": 189,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F2XL",
    //             "size": "2XL",
    //             "color": "Military Green",
    //             "stock": 65,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F3XL",
    //             "size": "3XL",
    //             "color": "Military Green",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F00L",
    //             "size": "L",
    //             "color": "Military Green",
    //             "stock": 58,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F00M",
    //             "size": "M",
    //             "color": "Military Green",
    //             "stock": 77,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F00S",
    //             "size": "S",
    //             "color": "Military Green",
    //             "stock": 71,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F0XL",
    //             "size": "XL",
    //             "color": "Military Green",
    //             "stock": 70,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN3F0XS",
    //             "size": "XS",
    //             "color": "Military Green",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N2XL",
    //             "size": "2XL",
    //             "color": "Navy",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N00L",
    //             "size": "L",
    //             "color": "Navy",
    //             "stock": 157,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N00M",
    //             "size": "M",
    //             "color": "Navy",
    //             "stock": 162,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N00S",
    //             "size": "S",
    //             "color": "Navy",
    //             "stock": 176,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1N0XL",
    //             "size": "XL",
    //             "color": "Navy",
    //             "stock": 171,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R2XL",
    //             "size": "2XL",
    //             "color": "Red",
    //             "stock": 249,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R00L",
    //             "size": "L",
    //             "color": "Red",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R00M",
    //             "size": "M",
    //             "color": "Red",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R00S",
    //             "size": "S",
    //             "color": "Red",
    //             "stock": 232,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1R0XL",
    //             "size": "XL",
    //             "color": "Red",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q2XL",
    //             "size": "2XL",
    //             "color": "Team Purple",
    //             "stock": 180,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q00L",
    //             "size": "L",
    //             "color": "Team Purple",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q00M",
    //             "size": "M",
    //             "color": "Team Purple",
    //             "stock": 187,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q00S",
    //             "size": "S",
    //             "color": "Team Purple",
    //             "stock": 122,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5Q0XL",
    //             "size": "XL",
    //             "color": "Team Purple",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C2XL",
    //             "size": "2XL",
    //             "color": "True Royal",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C00L",
    //             "size": "L",
    //             "color": "True Royal",
    //             "stock": 216,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C00M",
    //             "size": "M",
    //             "color": "True Royal",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C00S",
    //             "size": "S",
    //             "color": "True Royal",
    //             "stock": 99,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN5C0XL",
    //             "size": "XL",
    //             "color": "True Royal",
    //             "stock": 144,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W2XL",
    //             "size": "2XL",
    //             "color": "White",
    //             "stock": 199,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W00L",
    //             "size": "L",
    //             "color": "White",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W00M",
    //             "size": "M",
    //             "color": "White",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W00S",
    //             "size": "S",
    //             "color": "White",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "UNVN1W0XL",
    //             "size": "XL",
    //             "color": "White",
    //             "stock": 9999,
    //             "status": "In Stock"
    //         }
    //     ],
    //     "brand": null,
    //     "printer": "US1",
    //     "name": "V-Neck T-shirt - Bella + Canvas | 3005",
    //     "sku": "Unisex-BellaCanvas-V-NeckT-Shirt",
    //     "id": "6921020a-1df6-459d-a02f-8f0bed8e1d87"
    // },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "BCHDM700S",
                "size": "S",
                "color": "Storm",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM700M",
                "size": "M",
                "color": "Storm",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM700L",
                "size": "L",
                "color": "Storm",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM70XL",
                "size": "XL",
                "color": "Storm",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM72XL",
                "size": "2XL",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000S",
                "size": "S",
                "color": "Peach",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000M",
                "size": "M",
                "color": "Peach",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ000L",
                "size": "L",
                "color": "Peach",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ00XL",
                "size": "XL",
                "color": "Peach",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHD2H2XL",
                "size": "2XL",
                "color": "Athletic Heather",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "BCHDJ02XL",
                "size": "2XL",
                "color": "Peach",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400S",
                "size": "S",
                "color": "Dtg Black",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400M",
                "size": "M",
                "color": "Dtg Black",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE400L",
                "size": "L",
                "color": "Dtg Black",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE40XL",
                "size": "XL",
                "color": "Dtg Black",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE42XL",
                "size": "2XL",
                "color": "Dtg Black",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500S",
                "size": "S",
                "color": "Dtg Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500M",
                "size": "M",
                "color": "Dtg Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE500L",
                "size": "L",
                "color": "Dtg Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE50XL",
                "size": "XL",
                "color": "Dtg Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDE52XL",
                "size": "2XL",
                "color": "Dtg Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800S",
                "size": "S",
                "color": "Lilac",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800M",
                "size": "M",
                "color": "Lilac",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH800L",
                "size": "L",
                "color": "Lilac",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH80XL",
                "size": "XL",
                "color": "Lilac",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH82XL",
                "size": "2XL",
                "color": "Lilac",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000S",
                "size": "S",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000M",
                "size": "M",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH000L",
                "size": "L",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH00XL",
                "size": "XL",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDH02XL",
                "size": "2XL",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3F2XL",
                "size": "2XL",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000S",
                "size": "S",
                "color": "Tan",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000M",
                "size": "M",
                "color": "Tan",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM000L",
                "size": "L",
                "color": "Tan",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM00XL",
                "size": "XL",
                "color": "Tan",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDM02XL",
                "size": "2XL",
                "color": "Tan",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900S",
                "size": "S",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900M",
                "size": "M",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN900L",
                "size": "L",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN90XL",
                "size": "XL",
                "color": "Vintage White",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "BCHDN92XL",
                "size": "2XL",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00S",
                "size": "S",
                "color": "White",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00M",
                "size": "M",
                "color": "White",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W00L",
                "size": "L",
                "color": "White",
                "stock": 16,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00S",
                "size": "S",
                "color": "Yellow",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00M",
                "size": "M",
                "color": "Yellow",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y00L",
                "size": "L",
                "color": "Yellow",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y0XL",
                "size": "XL",
                "color": "Yellow",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1Y2XL",
                "size": "2XL",
                "color": "Yellow",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP52XL",
                "size": "2XL",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500L",
                "size": "L",
                "color": "Heather Deep Teal",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500M",
                "size": "M",
                "color": "Heather Deep Teal",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP500S",
                "size": "S",
                "color": "Heather Deep Teal",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP50XL",
                "size": "XL",
                "color": "Heather Deep Teal",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP62XL",
                "size": "2XL",
                "color": "Heather Forest",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP60XL",
                "size": "XL",
                "color": "Heather Forest",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600L",
                "size": "L",
                "color": "Heather Forest",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600M",
                "size": "M",
                "color": "Heather Forest",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHDP600S",
                "size": "S",
                "color": "Heather Forest",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00L",
                "size": "L",
                "color": "Red",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00M",
                "size": "M",
                "color": "Red",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1R00S",
                "size": "S",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H2XL",
                "size": "2XL",
                "color": "Deep Heather",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD5C2XL",
                "size": "2XL",
                "color": "True Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00S",
                "size": "S",
                "color": "Black",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00M",
                "size": "M",
                "color": "Black",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B00L",
                "size": "L",
                "color": "Black",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD1K2XL",
                "size": "2XL",
                "color": "Dark Grey Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K2XL",
                "size": "2XL",
                "color": "Black Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K0XL",
                "size": "XL",
                "color": "Black Heather",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00L",
                "size": "L",
                "color": "Black Heather",
                "stock": 1,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00M",
                "size": "M",
                "color": "Black Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHD3K00S",
                "size": "S",
                "color": "Black Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR90XS",
                "size": "XS",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900S",
                "size": "S",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900M",
                "size": "M",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR900L",
                "size": "L",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR90XL",
                "size": "XL",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "BCHDR92XL",
                "size": "2XL",
                "color": "Heather Charity Pink",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Fleece Hoodie - Bella + Canvas | 3719",
        "sku": "Unisex-BellaCanvas-Hoodie",
        "id": "95ef4e7f-79ad-41f5-953c-0e372898ef4a"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNGH3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W3XL",
                "size": "3XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W4XL",
                "size": "4XL",
                "color": "Ash",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3W5XL",
                "size": "5XL",
                "color": "Ash",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B4XL",
                "size": "4XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1B5XL",
                "size": "5XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N2XL",
                "size": "2XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N3XL",
                "size": "3XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N4XL",
                "size": "4XL",
                "color": "Carolina Blue",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5N5XL",
                "size": "5XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M00S",
                "size": "S",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M00M",
                "size": "M",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M00L",
                "size": "L",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M0XL",
                "size": "XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M2XL",
                "size": "2XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M3XL",
                "size": "3XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M4XL",
                "size": "4XL",
                "color": "Dark Chocolate",
                "stock": 202,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1M5XL",
                "size": "5XL",
                "color": "Dark Chocolate",
                "stock": 195,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K4XL",
                "size": "4XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2K5XL",
                "size": "5XL",
                "color": "Dark Heather",
                "stock": 171,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F2XL",
                "size": "2XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F3XL",
                "size": "3XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F4XL",
                "size": "4XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2F5XL",
                "size": "5XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y4XL",
                "size": "4XL",
                "color": "Gold",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3Y5XL",
                "size": "5XL",
                "color": "Gold",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P2XL",
                "size": "2XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P3XL",
                "size": "3XL",
                "color": "Heliconia",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P4XL",
                "size": "4XL",
                "color": "Heliconia",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8P5XL",
                "size": "5XL",
                "color": "Heliconia",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G2XL",
                "size": "2XL",
                "color": "Irish Green",
                "stock": 230,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G3XL",
                "size": "3XL",
                "color": "Irish Green",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G4XL",
                "size": "4XL",
                "color": "Irish Green",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3G5XL",
                "size": "5XL",
                "color": "Irish Green",
                "stock": 194,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N4XL",
                "size": "4XL",
                "color": "Light Blue",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4N5XL",
                "size": "5XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P2XL",
                "size": "2XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P3XL",
                "size": "3XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P4XL",
                "size": "4XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5P5XL",
                "size": "5XL",
                "color": "Light Pink",
                "stock": 194,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R3XL",
                "size": "3XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R4XL",
                "size": "4XL",
                "color": "Maroon",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5R5XL",
                "size": "5XL",
                "color": "Maroon",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F2XL",
                "size": "2XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F3XL",
                "size": "3XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F4XL",
                "size": "4XL",
                "color": "Military Green",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "UNGH3F5XL",
                "size": "5XL",
                "color": "Military Green",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N4XL",
                "size": "4XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1N5XL",
                "size": "5XL",
                "color": "Navy",
                "stock": 214,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O4XL",
                "size": "4XL",
                "color": "Orange",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1O5XL",
                "size": "5XL",
                "color": "Orange",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q4XL",
                "size": "4XL",
                "color": "Purple",
                "stock": 222,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1Q5XL",
                "size": "5XL",
                "color": "Purple",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R4XL",
                "size": "4XL",
                "color": "Red",
                "stock": 111,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1R5XL",
                "size": "5XL",
                "color": "Red",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C4XL",
                "size": "4XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7C5XL",
                "size": "5XL",
                "color": "Royal",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y00S",
                "size": "S",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y00M",
                "size": "M",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y00L",
                "size": "L",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y0XL",
                "size": "XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y2XL",
                "size": "2XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y3XL",
                "size": "3XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y4XL",
                "size": "4XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4Y5XL",
                "size": "5XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H4XL",
                "size": "4XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4H5XL",
                "size": "5XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W4XL",
                "size": "4XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH1W5XL",
                "size": "5XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S00S",
                "size": "S",
                "color": "Antique Cherry Red",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S00M",
                "size": "M",
                "color": "Antique Cherry Red",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S00L",
                "size": "L",
                "color": "Antique Cherry Red",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S0XL",
                "size": "XL",
                "color": "Antique Cherry Red",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S2XL",
                "size": "2XL",
                "color": "Antique Cherry Red",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S3XL",
                "size": "3XL",
                "color": "Antique Cherry Red",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S4XL",
                "size": "4XL",
                "color": "Antique Cherry Red",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGH7S5XL",
                "size": "5XL",
                "color": "Antique Cherry Red",
                "stock": 14,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 248,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 132,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B4XL",
                "size": "4XL",
                "color": "Charcoal",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGH5B5XL",
                "size": "5XL",
                "color": "Charcoal",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S2XL",
                "size": "2XL",
                "color": "Cardinal Red",
                "stock": 102,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S3XL",
                "size": "3XL",
                "color": "Cardinal Red",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S4XL",
                "size": "4XL",
                "color": "Cardinal Red",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S5XL",
                "size": "5XL",
                "color": "Cardinal Red",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N00S",
                "size": "S",
                "color": "Indigo Blue",
                "stock": 147,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N00M",
                "size": "M",
                "color": "Indigo Blue",
                "stock": 239,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N00L",
                "size": "L",
                "color": "Indigo Blue",
                "stock": 188,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N0XL",
                "size": "XL",
                "color": "Indigo Blue",
                "stock": 203,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N2XL",
                "size": "2XL",
                "color": "Indigo Blue",
                "stock": 243,
                "status": "In Stock"
            },
            {
                "sku": "UNGH0N3XL",
                "size": "3XL",
                "color": "Indigo Blue",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K2XL",
                "size": "2XL",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K3XL",
                "size": "3XL",
                "color": "Graphite Heather",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K00L",
                "size": "L",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K00M",
                "size": "M",
                "color": "Graphite Heather",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K00S",
                "size": "S",
                "color": "Graphite Heather",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "UNGH8K0XL",
                "size": "XL",
                "color": "Graphite Heather",
                "stock": 225,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O2XL",
                "size": "2XL",
                "color": "Safety Orange",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O3XL",
                "size": "3XL",
                "color": "Safety Orange",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O4XL",
                "size": "4XL",
                "color": "Safety Orange",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O5XL",
                "size": "5XL",
                "color": "Safety Orange",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O00L",
                "size": "L",
                "color": "Safety Orange",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O00M",
                "size": "M",
                "color": "Safety Orange",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O00S",
                "size": "S",
                "color": "Safety Orange",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGH4O0XL",
                "size": "XL",
                "color": "Safety Orange",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S00L",
                "size": "L",
                "color": "Cardinal Red",
                "stock": 172,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S00M",
                "size": "M",
                "color": "Cardinal Red",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S00S",
                "size": "S",
                "color": "Cardinal Red",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "UNGH2S0XL",
                "size": "XL",
                "color": "Cardinal Red",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT500S",
                "size": "S",
                "color": "Heather Sport Dark Navy",
                "stock": 26,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT500M",
                "size": "M",
                "color": "Heather Sport Dark Navy",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT500L",
                "size": "L",
                "color": "Heather Sport Dark Navy",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT50XL",
                "size": "XL",
                "color": "Heather Sport Dark Navy",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT52XL",
                "size": "2XL",
                "color": "Heather Sport Dark Navy",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "UNGHT53XL",
                "size": "3XL",
                "color": "Heather Sport Dark Navy",
                "stock": 27,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Hoodie | G18500",
        "sku": "Unisex-Gildan-Hoodie",
        "id": "7eb6cd30-3907-40da-8953-aca28495d2c3"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNGL3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 178,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 171,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N2XL",
                "size": "2XL",
                "color": "Carolina Blue",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M00S",
                "size": "S",
                "color": "Dark Chocolate",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M00M",
                "size": "M",
                "color": "Dark Chocolate",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M00L",
                "size": "L",
                "color": "Dark Chocolate",
                "stock": 102,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M0XL",
                "size": "XL",
                "color": "Dark Chocolate",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M2XL",
                "size": "2XL",
                "color": "Dark Chocolate",
                "stock": 121,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F2XL",
                "size": "2XL",
                "color": "Forest Green",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 156,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 134,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G2XL",
                "size": "2XL",
                "color": "Irish Green",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 196,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 95,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P2XL",
                "size": "2XL",
                "color": "Light Pink",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 178,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 126,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 53,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 224,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R00S",
                "size": "S",
                "color": "Red",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R00M",
                "size": "M",
                "color": "Red",
                "stock": 191,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 107,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 170,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 226,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 233,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W3XL",
                "size": "3XL",
                "color": "Ash",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N3XL",
                "size": "3XL",
                "color": "Carolina Blue",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M3XL",
                "size": "3XL",
                "color": "Dark Chocolate",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F3XL",
                "size": "3XL",
                "color": "Forest Green",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G3XL",
                "size": "3XL",
                "color": "Irish Green",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P3XL",
                "size": "3XL",
                "color": "Light Pink",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R3XL",
                "size": "3XL",
                "color": "Maroon",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 27,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 135,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 130,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B4XL",
                "size": "4XL",
                "color": "Charcoal",
                "stock": 27,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5B5XL",
                "size": "5XL",
                "color": "Charcoal",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 124,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 160,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K5XL",
                "size": "5XL",
                "color": "Dark Heather",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N3XL",
                "size": "3XL",
                "color": "Indigo Blue",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S00S",
                "size": "S",
                "color": "Cardinal Red",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S00M",
                "size": "M",
                "color": "Cardinal Red",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S00L",
                "size": "L",
                "color": "Cardinal Red",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S0XL",
                "size": "XL",
                "color": "Cardinal Red",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S2XL",
                "size": "2XL",
                "color": "Cardinal Red",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S3XL",
                "size": "3XL",
                "color": "Cardinal Red",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S4XL",
                "size": "4XL",
                "color": "Cardinal Red",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2S5XL",
                "size": "5XL",
                "color": "Cardinal Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W4XL",
                "size": "4XL",
                "color": "Ash",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3W5XL",
                "size": "5XL",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B4XL",
                "size": "4XL",
                "color": "Black",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1B5XL",
                "size": "5XL",
                "color": "Black",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N4XL",
                "size": "4XL",
                "color": "Carolina Blue",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5N5XL",
                "size": "5XL",
                "color": "Carolina Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M4XL",
                "size": "4XL",
                "color": "Dark Chocolate",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1M5XL",
                "size": "5XL",
                "color": "Dark Chocolate",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F4XL",
                "size": "4XL",
                "color": "Forest Green",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2F5XL",
                "size": "5XL",
                "color": "Forest Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y4XL",
                "size": "4XL",
                "color": "Gold",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3Y5XL",
                "size": "5XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G4XL",
                "size": "4XL",
                "color": "Irish Green",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3G5XL",
                "size": "5XL",
                "color": "Irish Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N4XL",
                "size": "4XL",
                "color": "Light Blue",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4N5XL",
                "size": "5XL",
                "color": "Light Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P4XL",
                "size": "4XL",
                "color": "Light Pink",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5P5XL",
                "size": "5XL",
                "color": "Light Pink",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGL5R4XL",
                "size": "4XL",
                "color": "Maroon",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5R5XL",
                "size": "5XL",
                "color": "Maroon",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F2XL",
                "size": "2XL",
                "color": "Military Green",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F3XL",
                "size": "3XL",
                "color": "Military Green",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F4XL",
                "size": "4XL",
                "color": "Military Green",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F5XL",
                "size": "5XL",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNGL3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W2XL",
                "size": "2XL",
                "color": "Natural",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W3XL",
                "size": "3XL",
                "color": "Natural",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W4XL",
                "size": "4XL",
                "color": "Natural",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W5XL",
                "size": "5XL",
                "color": "Natural",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W00L",
                "size": "L",
                "color": "Natural",
                "stock": 49,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W00M",
                "size": "M",
                "color": "Natural",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W00S",
                "size": "S",
                "color": "Natural",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "UNGL5W0XL",
                "size": "XL",
                "color": "Natural",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N4XL",
                "size": "4XL",
                "color": "Navy",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1N5XL",
                "size": "5XL",
                "color": "Navy",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O4XL",
                "size": "4XL",
                "color": "Orange",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O5XL",
                "size": "5XL",
                "color": "Orange",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q4XL",
                "size": "4XL",
                "color": "Purple",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1Q5XL",
                "size": "5XL",
                "color": "Purple",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R4XL",
                "size": "4XL",
                "color": "Red",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1R5XL",
                "size": "5XL",
                "color": "Red",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C4XL",
                "size": "4XL",
                "color": "Royal",
                "stock": 14,
                "status": "In Stock"
            },
            {
                "sku": "UNGL7C5XL",
                "size": "5XL",
                "color": "Royal",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O2XL",
                "size": "2XL",
                "color": "Safety Orange",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O3XL",
                "size": "3XL",
                "color": "Safety Orange",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O4XL",
                "size": "4XL",
                "color": "Safety Orange",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O5XL",
                "size": "5XL",
                "color": "Safety Orange",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O00L",
                "size": "L",
                "color": "Safety Orange",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O00M",
                "size": "M",
                "color": "Safety Orange",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O00S",
                "size": "S",
                "color": "Safety Orange",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4O0XL",
                "size": "XL",
                "color": "Safety Orange",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y2XL",
                "size": "2XL",
                "color": "Sand",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y3XL",
                "size": "3XL",
                "color": "Sand",
                "stock": 16,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y4XL",
                "size": "4XL",
                "color": "Sand",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y5XL",
                "size": "5XL",
                "color": "Sand",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y00L",
                "size": "L",
                "color": "Sand",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y00M",
                "size": "M",
                "color": "Sand",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y00S",
                "size": "S",
                "color": "Sand",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4Y0XL",
                "size": "XL",
                "color": "Sand",
                "stock": 242,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H4XL",
                "size": "4XL",
                "color": "Sport Grey",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "UNGL4H5XL",
                "size": "5XL",
                "color": "Sport Grey",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W4XL",
                "size": "4XL",
                "color": "White",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "UNGL1W5XL",
                "size": "5XL",
                "color": "White",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K4XL",
                "size": "4XL",
                "color": "Dark Heather",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "UNGL2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N2XL",
                "size": "2XL",
                "color": "Indigo Blue",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N4XL",
                "size": "4XL",
                "color": "Indigo Blue",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N5XL",
                "size": "5XL",
                "color": "Indigo Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N00L",
                "size": "L",
                "color": "Indigo Blue",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N00M",
                "size": "M",
                "color": "Indigo Blue",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N00S",
                "size": "S",
                "color": "Indigo Blue",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "UNGL0N0XL",
                "size": "XL",
                "color": "Indigo Blue",
                "stock": 21,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Long Sleeve T-shirt | G2400",
        "sku": "Unisex-Gildan-LongSleeve",
        "id": "607c73ea-1a1c-4bcf-a0bd-667cc58e5c23"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "GDVN1B00S",
                "size": "S",
                "color": "Black",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1B00M",
                "size": "M",
                "color": "Black",
                "stock": 160,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 88,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 49,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W00S",
                "size": "S",
                "color": "White",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W00M",
                "size": "M",
                "color": "White",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W00L",
                "size": "L",
                "color": "White",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 100,
                "status": "In Stock"
            },
            {
                "sku": "GDVN4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "GDVN2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "GDVN1J00S",
                "size": "S",
                "color": "Cherry Red",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1J00M",
                "size": "M",
                "color": "Cherry Red",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1J00L",
                "size": "L",
                "color": "Cherry Red",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1J0XL",
                "size": "XL",
                "color": "Cherry Red",
                "stock": 25,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1J2XL",
                "size": "2XL",
                "color": "Cherry Red",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "GDVN1J3XL",
                "size": "3XL",
                "color": "Cherry Red",
                "stock": 36,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex V-neck T-shirt | G64V00",
        "sku": "Unisex-Gildan-V-NeckT-Shirt",
        "id": "bc50345b-5900-4222-bb23-7c2d29bafa9f"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "BCLS2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS2H2XL",
                "size": "2XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1K2XL",
                "size": "2XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3K00S",
                "size": "S",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3K00M",
                "size": "M",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3K00L",
                "size": "L",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3K0XL",
                "size": "XL",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS3K2XL",
                "size": "2XL",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6R00S",
                "size": "S",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6R00M",
                "size": "M",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6R00L",
                "size": "L",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6R0XL",
                "size": "XL",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6R2XL",
                "size": "2XL",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5K00S",
                "size": "S",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5K00M",
                "size": "M",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5K00L",
                "size": "L",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5K0XL",
                "size": "XL",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5K2XL",
                "size": "2XL",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H2XL",
                "size": "2XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLSF000S",
                "size": "S",
                "color": "Heather Brown",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLSF000M",
                "size": "M",
                "color": "Heather Brown",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLSF000L",
                "size": "L",
                "color": "Heather Brown",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLSF00XL",
                "size": "XL",
                "color": "Heather Brown",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLSF02XL",
                "size": "2XL",
                "color": "Heather Brown",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS9N2XL",
                "size": "2XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS5C2XL",
                "size": "2XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "BCLS6H0XS",
                "size": "XS",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Long Sleeve T-shirt - Bella + Canvas | 3501",
        "sku": "Unisex-BellaCanvas-LongSleeve",
        "id": "677a7981-f1b2-467d-97f9-b8ec389e694c"
    },
    {
        "category": "Women",
        "variations": [
            {
                "sku": "WORB3H0XS",
                "size": "XS",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3H00S",
                "size": "S",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3H00M",
                "size": "M",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3H00L",
                "size": "L",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3H0XL",
                "size": "XL",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3H2XL",
                "size": "2XL",
                "color": "Heather Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B0XS",
                "size": "XS",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B00S",
                "size": "S",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B00M",
                "size": "M",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B00L",
                "size": "L",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B0XL",
                "size": "XL",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2B2XL",
                "size": "2XL",
                "color": "Solid Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S0XS",
                "size": "XS",
                "color": "Solid Cardinal Red",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S00S",
                "size": "S",
                "color": "Solid Cardinal Red",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S00M",
                "size": "M",
                "color": "Solid Cardinal Red",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S00L",
                "size": "L",
                "color": "Solid Cardinal Red",
                "stock": 161,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S0XL",
                "size": "XL",
                "color": "Solid Cardinal Red",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "WORB5S2XL",
                "size": "2XL",
                "color": "Solid Cardinal Red",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P0XS",
                "size": "XS",
                "color": "Solid Hot Pink",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P00S",
                "size": "S",
                "color": "Solid Hot Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P00M",
                "size": "M",
                "color": "Solid Hot Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P00L",
                "size": "L",
                "color": "Solid Hot Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P0XL",
                "size": "XL",
                "color": "Solid Hot Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2P2XL",
                "size": "2XL",
                "color": "Solid Hot Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q0XS",
                "size": "XS",
                "color": "Solid Indigo",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q00S",
                "size": "S",
                "color": "Solid Indigo",
                "stock": 185,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q00M",
                "size": "M",
                "color": "Solid Indigo",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q00L",
                "size": "L",
                "color": "Solid Indigo",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q0XL",
                "size": "XL",
                "color": "Solid Indigo",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2Q2XL",
                "size": "2XL",
                "color": "Solid Indigo",
                "stock": 220,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F0XS",
                "size": "XS",
                "color": "Solid Kelly Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F00S",
                "size": "S",
                "color": "Solid Kelly Green",
                "stock": 208,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F00M",
                "size": "M",
                "color": "Solid Kelly Green",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F00L",
                "size": "L",
                "color": "Solid Kelly Green",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F0XL",
                "size": "XL",
                "color": "Solid Kelly Green",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "WORB6F2XL",
                "size": "2XL",
                "color": "Solid Kelly Green",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N0XS",
                "size": "XS",
                "color": "Solid Midnight Navy",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N00S",
                "size": "S",
                "color": "Solid Midnight Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N00M",
                "size": "M",
                "color": "Solid Midnight Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N00L",
                "size": "L",
                "color": "Solid Midnight Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N0XL",
                "size": "XL",
                "color": "Solid Midnight Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB3N2XL",
                "size": "2XL",
                "color": "Solid Midnight Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F0XS",
                "size": "XS",
                "color": "Solid Military Green",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F00S",
                "size": "S",
                "color": "Solid Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F00M",
                "size": "M",
                "color": "Solid Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F00L",
                "size": "L",
                "color": "Solid Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F0XL",
                "size": "XL",
                "color": "Solid Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB1F2XL",
                "size": "2XL",
                "color": "Solid Military Green",
                "stock": 239,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G0XS",
                "size": "XS",
                "color": "Solid Mint",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G00S",
                "size": "S",
                "color": "Solid Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G00M",
                "size": "M",
                "color": "Solid Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G00L",
                "size": "L",
                "color": "Solid Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G0XL",
                "size": "XL",
                "color": "Solid Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB8G2XL",
                "size": "2XL",
                "color": "Solid Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q0XS",
                "size": "XS",
                "color": "Solid Purple Rush",
                "stock": 110,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q00S",
                "size": "S",
                "color": "Solid Purple Rush",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q00M",
                "size": "M",
                "color": "Solid Purple Rush",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q00L",
                "size": "L",
                "color": "Solid Purple Rush",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q0XL",
                "size": "XL",
                "color": "Solid Purple Rush",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4Q2XL",
                "size": "2XL",
                "color": "Solid Purple Rush",
                "stock": 198,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R0XS",
                "size": "XS",
                "color": "Solid Red",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R00S",
                "size": "S",
                "color": "Solid Red",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R00M",
                "size": "M",
                "color": "Solid Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R00L",
                "size": "L",
                "color": "Solid Red",
                "stock": 239,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R0XL",
                "size": "XL",
                "color": "Solid Red",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "WORB4R2XL",
                "size": "2XL",
                "color": "Solid Red",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C0XS",
                "size": "XS",
                "color": "Solid Royal",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C00S",
                "size": "S",
                "color": "Solid Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C00M",
                "size": "M",
                "color": "Solid Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C00L",
                "size": "L",
                "color": "Solid Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C0XL",
                "size": "XL",
                "color": "Solid Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6C2XL",
                "size": "2XL",
                "color": "Solid Royal",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N0XS",
                "size": "XS",
                "color": "Solid Tahiti Blue",
                "stock": 208,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N00S",
                "size": "S",
                "color": "Solid Tahiti Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N00M",
                "size": "M",
                "color": "Solid Tahiti Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N00L",
                "size": "L",
                "color": "Solid Tahiti Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N0XL",
                "size": "XL",
                "color": "Solid Tahiti Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6N2XL",
                "size": "2XL",
                "color": "Solid Tahiti Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B0XS",
                "size": "XS",
                "color": "Solid Warm Grey",
                "stock": 186,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B00S",
                "size": "S",
                "color": "Solid Warm Grey",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B00M",
                "size": "M",
                "color": "Solid Warm Grey",
                "stock": 208,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B00L",
                "size": "L",
                "color": "Solid Warm Grey",
                "stock": 240,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B0XL",
                "size": "XL",
                "color": "Solid Warm Grey",
                "stock": 153,
                "status": "In Stock"
            },
            {
                "sku": "WORB6B2XL",
                "size": "2XL",
                "color": "Solid Warm Grey",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W0XS",
                "size": "XS",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W00S",
                "size": "S",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W00M",
                "size": "M",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W00L",
                "size": "L",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W0XL",
                "size": "XL",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WORB2W2XL",
                "size": "2XL",
                "color": "Solid White",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Women's Racerback Tank | NL1533",
        "sku": "Woman-BellaCanvas-TankTop",
        "id": "03fc9edc-2ae7-4425-8e86-860aa5e1b654"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNGS8C00S",
                "size": "S",
                "color": "Antique Sapphire",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS8C00M",
                "size": "M",
                "color": "Antique Sapphire",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS8C00L",
                "size": "L",
                "color": "Antique Sapphire",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS8C0XL",
                "size": "XL",
                "color": "Antique Sapphire",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS8C2XL",
                "size": "2XL",
                "color": "Antique Sapphire",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8C3XL",
                "size": "3XL",
                "color": "Antique Sapphire",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8C4XL",
                "size": "4XL",
                "color": "Antique Sapphire",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W3XL",
                "size": "3XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W4XL",
                "size": "4XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3W5XL",
                "size": "5XL",
                "color": "Ash",
                "stock": 189,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B4XL",
                "size": "4XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1B5XL",
                "size": "5XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K4XL",
                "size": "4XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2K5XL",
                "size": "5XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F2XL",
                "size": "2XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F3XL",
                "size": "3XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F4XL",
                "size": "4XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2F5XL",
                "size": "5XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y4XL",
                "size": "4XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3Y5XL",
                "size": "5XL",
                "color": "Gold",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P2XL",
                "size": "2XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P3XL",
                "size": "3XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P4XL",
                "size": "4XL",
                "color": "Heliconia",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8P5XL",
                "size": "5XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G2XL",
                "size": "2XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G3XL",
                "size": "3XL",
                "color": "Irish Green",
                "stock": 242,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G4XL",
                "size": "4XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3G5XL",
                "size": "5XL",
                "color": "Irish Green",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4N4XL",
                "size": "4XL",
                "color": "Light Blue",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS4N5XL",
                "size": "5XL",
                "color": "Light Blue",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P2XL",
                "size": "2XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P3XL",
                "size": "3XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5P4XL",
                "size": "4XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS5P5XL",
                "size": "5XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R3XL",
                "size": "3XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5R4XL",
                "size": "4XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS5R5XL",
                "size": "5XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N4XL",
                "size": "4XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1N5XL",
                "size": "5XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O4XL",
                "size": "4XL",
                "color": "Orange",
                "stock": 233,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1O5XL",
                "size": "5XL",
                "color": "Orange",
                "stock": 121,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1Q4XL",
                "size": "4XL",
                "color": "Purple",
                "stock": 3,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS1Q5XL",
                "size": "5XL",
                "color": "Purple",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R4XL",
                "size": "4XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1R5XL",
                "size": "5XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y00S",
                "size": "S",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y00M",
                "size": "M",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y00L",
                "size": "L",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y0XL",
                "size": "XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y2XL",
                "size": "2XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y3XL",
                "size": "3XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4Y4XL",
                "size": "4XL",
                "color": "Sand",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS4Y5XL",
                "size": "5XL",
                "color": "Sand",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H4XL",
                "size": "4XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4H5XL",
                "size": "5XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W4XL",
                "size": "4XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1W5XL",
                "size": "5XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 245,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 184,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C4XL",
                "size": "4XL",
                "color": "Royal",
                "stock": 118,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7C5XL",
                "size": "5XL",
                "color": "Royal",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 245,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B4XL",
                "size": "4XL",
                "color": "Charcoal",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5B5XL",
                "size": "5XL",
                "color": "Charcoal",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S00S",
                "size": "S",
                "color": "Antique Cherry Red",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S00M",
                "size": "M",
                "color": "Antique Cherry Red",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S00L",
                "size": "L",
                "color": "Antique Cherry Red",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S0XL",
                "size": "XL",
                "color": "Antique Cherry Red",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S2XL",
                "size": "2XL",
                "color": "Antique Cherry Red",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S3XL",
                "size": "3XL",
                "color": "Antique Cherry Red",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S4XL",
                "size": "4XL",
                "color": "Antique Cherry Red",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGS7S5XL",
                "size": "5XL",
                "color": "Antique Cherry Red",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N00S",
                "size": "S",
                "color": "Indigo Blue",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N00M",
                "size": "M",
                "color": "Indigo Blue",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N00L",
                "size": "L",
                "color": "Indigo Blue",
                "stock": 171,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N0XL",
                "size": "XL",
                "color": "Indigo Blue",
                "stock": 167,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N2XL",
                "size": "2XL",
                "color": "Indigo Blue",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N3XL",
                "size": "3XL",
                "color": "Indigo Blue",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N4XL",
                "size": "4XL",
                "color": "Indigo Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS0N5XL",
                "size": "5XL",
                "color": "Indigo Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S2XL",
                "size": "2XL",
                "color": "Cardinal Red",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S3XL",
                "size": "3XL",
                "color": "Cardinal Red",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S4XL",
                "size": "4XL",
                "color": "Cardinal Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S5XL",
                "size": "5XL",
                "color": "Cardinal Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S00L",
                "size": "L",
                "color": "Cardinal Red",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S00M",
                "size": "M",
                "color": "Cardinal Red",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S00S",
                "size": "S",
                "color": "Cardinal Red",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNGS2S0XL",
                "size": "XL",
                "color": "Cardinal Red",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNGS6P2XL",
                "size": "2XL",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS6P3XL",
                "size": "3XL",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS6P00L",
                "size": "L",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS6P00M",
                "size": "M",
                "color": "Azalea",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS6P00S",
                "size": "S",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS6P0XL",
                "size": "XL",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N2XL",
                "size": "2XL",
                "color": "Carolina Blue",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N3XL",
                "size": "3XL",
                "color": "Carolina Blue",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N4XL",
                "size": "4XL",
                "color": "Carolina Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N5XL",
                "size": "5XL",
                "color": "Carolina Blue",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 14,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J2XL",
                "size": "2XL",
                "color": "Cherry Red",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J3XL",
                "size": "3XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J4XL",
                "size": "4XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J5XL",
                "size": "5XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J00L",
                "size": "L",
                "color": "Cherry Red",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J00M",
                "size": "M",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J00S",
                "size": "S",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1J0XL",
                "size": "XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M2XL",
                "size": "2XL",
                "color": "Dark Chocolate",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M3XL",
                "size": "3XL",
                "color": "Dark Chocolate",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M4XL",
                "size": "4XL",
                "color": "Dark Chocolate",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M5XL",
                "size": "5XL",
                "color": "Dark Chocolate",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M00L",
                "size": "L",
                "color": "Dark Chocolate",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M00M",
                "size": "M",
                "color": "Dark Chocolate",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M00S",
                "size": "S",
                "color": "Dark Chocolate",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGS1M0XL",
                "size": "XL",
                "color": "Dark Chocolate",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S2XL",
                "size": "2XL",
                "color": "Garnet",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S3XL",
                "size": "3XL",
                "color": "Garnet",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S4XL",
                "size": "4XL",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S5XL",
                "size": "5XL",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S00L",
                "size": "L",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S00M",
                "size": "M",
                "color": "Garnet",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S00S",
                "size": "S",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4S0XL",
                "size": "XL",
                "color": "Garnet",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K2XL",
                "size": "2XL",
                "color": "Graphite Heather",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K3XL",
                "size": "3XL",
                "color": "Graphite Heather",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K4XL",
                "size": "4XL",
                "color": "Graphite Heather",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K5XL",
                "size": "5XL",
                "color": "Graphite Heather",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K00L",
                "size": "L",
                "color": "Graphite Heather",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K00M",
                "size": "M",
                "color": "Graphite Heather",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K00S",
                "size": "S",
                "color": "Graphite Heather",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "UNGS8K0XL",
                "size": "XL",
                "color": "Graphite Heather",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G2XL",
                "size": "2XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G3XL",
                "size": "3XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G4XL",
                "size": "4XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G5XL",
                "size": "5XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G00L",
                "size": "L",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G00M",
                "size": "M",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G00S",
                "size": "S",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS4G0XL",
                "size": "XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F2XL",
                "size": "2XL",
                "color": "Military Green",
                "stock": 89,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F3XL",
                "size": "3XL",
                "color": "Military Green",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F4XL",
                "size": "4XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F5XL",
                "size": "5XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 157,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 146,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y2XL",
                "size": "2XL",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y3XL",
                "size": "3XL",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y00L",
                "size": "L",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y00M",
                "size": "M",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y00S",
                "size": "S",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS9Y0XL",
                "size": "XL",
                "color": "Old Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ52XL",
                "size": "2XL",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ53XL",
                "size": "3XL",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ500L",
                "size": "L",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ500M",
                "size": "M",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ500S",
                "size": "S",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ50XL",
                "size": "XL",
                "color": "Orchid",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ92XL",
                "size": "2XL",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ93XL",
                "size": "3XL",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ900L",
                "size": "L",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ900M",
                "size": "M",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ900S",
                "size": "S",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSJ90XL",
                "size": "XL",
                "color": "Paprika",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V2XL",
                "size": "2XL",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V3XL",
                "size": "3XL",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V4XL",
                "size": "4XL",
                "color": "Safety Green",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V5XL",
                "size": "5XL",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V00L",
                "size": "L",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V00M",
                "size": "M",
                "color": "Safety Green",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V00S",
                "size": "S",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS5V0XL",
                "size": "XL",
                "color": "Safety Green",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D2XL",
                "size": "2XL",
                "color": "Sapphire",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D3XL",
                "size": "3XL",
                "color": "Sapphire",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D4XL",
                "size": "4XL",
                "color": "Sapphire",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D5XL",
                "size": "5XL",
                "color": "Sapphire",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGS3D00L",
                "size": "L",
                "color": "Sapphire",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D00M",
                "size": "M",
                "color": "Sapphire",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D00S",
                "size": "S",
                "color": "Sapphire",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGS3D0XL",
                "size": "XL",
                "color": "Sapphire",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN02XL",
                "size": "2XL",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN03XL",
                "size": "3XL",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN000L",
                "size": "L",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN000M",
                "size": "M",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN000S",
                "size": "S",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD00S",
                "size": "S",
                "color": "Heather Sport Royal",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD00M",
                "size": "M",
                "color": "Heather Sport Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD00L",
                "size": "L",
                "color": "Heather Sport Royal",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD0XL",
                "size": "XL",
                "color": "Heather Sport Royal",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD2XL",
                "size": "2XL",
                "color": "Heather Sport Royal",
                "stock": 16,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBD3XL",
                "size": "3XL",
                "color": "Heather Sport Royal",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE00S",
                "size": "S",
                "color": "Heather Sport Dark Maroon",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE00M",
                "size": "M",
                "color": "Heather Sport Dark Maroon",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE00L",
                "size": "L",
                "color": "Heather Sport Dark Maroon",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE0XL",
                "size": "XL",
                "color": "Heather Sport Dark Maroon",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE2XL",
                "size": "2XL",
                "color": "Heather Sport Dark Maroon",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBE3XL",
                "size": "3XL",
                "color": "Heather Sport Dark Maroon",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGST500S",
                "size": "S",
                "color": "Heather Sport Dark Navy",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGST500M",
                "size": "M",
                "color": "Heather Sport Dark Navy",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "UNGST500L",
                "size": "L",
                "color": "Heather Sport Dark Navy",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGST50XL",
                "size": "XL",
                "color": "Heather Sport Dark Navy",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "UNGST52XL",
                "size": "2XL",
                "color": "Heather Sport Dark Navy",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNGST53XL",
                "size": "3XL",
                "color": "Heather Sport Dark Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF00S",
                "size": "S",
                "color": "Heather Sport Dark Green",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF00M",
                "size": "M",
                "color": "Heather Sport Dark Green",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF00L",
                "size": "L",
                "color": "Heather Sport Dark Green",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF0XL",
                "size": "XL",
                "color": "Heather Sport Dark Green",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF2XL",
                "size": "2XL",
                "color": "Heather Sport Dark Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBF3XL",
                "size": "3XL",
                "color": "Heather Sport Dark Green",
                "stock": 26,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH00S",
                "size": "S",
                "color": "Heather Sport Scarlet Red",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH00M",
                "size": "M",
                "color": "Heather Sport Scarlet Red",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH00L",
                "size": "L",
                "color": "Heather Sport Scarlet Red",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH0XL",
                "size": "XL",
                "color": "Heather Sport Scarlet Red",
                "stock": 49,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH2XL",
                "size": "2XL",
                "color": "Heather Sport Scarlet Red",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGSBH3XL",
                "size": "3XL",
                "color": "Heather Sport Scarlet Red",
                "stock": 39,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Sweatshirt | G18000",
        "sku": "Unisex-Gildan-Sweatshirt",
        "id": "ce251b1c-e7c0-4491-81ca-350128761d07"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "GDUT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W00S",
                "size": "S",
                "color": "White",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W00M",
                "size": "M",
                "color": "White",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W00L",
                "size": "L",
                "color": "White",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "GDUT3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 154,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "GDUT4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 1,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GDUT3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Tank-Top | G2200",
        "sku": "Unisex-Gildan-TankTop",
        "id": "2df3115b-25fb-4bfd-b072-077482dfca4d"
    },
    {
        "category": "Kids",
        "variations": [
            {
                "sku": "YOGH1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 247,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 248,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 178,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 205,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 222,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "YOGH8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "YOGH8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "YOGH8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "YOGH8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 124,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 171,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 110,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 209,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 112,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 196,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 175,
                "status": "In Stock"
            },
            {
                "sku": "YOGH7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 234,
                "status": "In Stock"
            },
            {
                "sku": "YOGH7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 244,
                "status": "In Stock"
            },
            {
                "sku": "YOGH7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 126,
                "status": "In Stock"
            },
            {
                "sku": "YOGH4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5N0XS",
                "size": "XS",
                "color": "Carolina Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2K0XS",
                "size": "XS",
                "color": "Dark Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH2F0XS",
                "size": "XS",
                "color": "Forest Green",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3Y0XS",
                "size": "XS",
                "color": "Gold",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH8P0XS",
                "size": "XS",
                "color": "Heliconia",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH3G0XS",
                "size": "XS",
                "color": "Irish Green",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5P0XS",
                "size": "XS",
                "color": "Light Pink",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "YOGH5R0XS",
                "size": "XS",
                "color": "Maroon",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1O0XS",
                "size": "XS",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1Q0XS",
                "size": "XS",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "YOGH7C0XS",
                "size": "XS",
                "color": "Royal",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "YOGH4H0XS",
                "size": "XS",
                "color": "Sport Grey",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "YOGH1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 23,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth Hoodie | G18500B",
        "sku": "Youth-Gildan-YouthHoodie",
        "id": "09878b60-9c42-49dd-b642-62e75f31bc13"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNPT9C0XS",
                "size": "XS",
                "color": "Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C00S",
                "size": "S",
                "color": "Aqua",
                "stock": 231,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C00M",
                "size": "M",
                "color": "Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C00L",
                "size": "L",
                "color": "Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C0XL",
                "size": "XL",
                "color": "Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C2XL",
                "size": "2XL",
                "color": "Aqua",
                "stock": 176,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9C3XL",
                "size": "3XL",
                "color": "Aqua",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F0XS",
                "size": "XS",
                "color": "Army",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F00S",
                "size": "S",
                "color": "Army",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F00M",
                "size": "M",
                "color": "Army",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F00L",
                "size": "L",
                "color": "Army",
                "stock": 211,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F0XL",
                "size": "XL",
                "color": "Army",
                "stock": 204,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F2XL",
                "size": "2XL",
                "color": "Army",
                "stock": 186,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7F3XL",
                "size": "3XL",
                "color": "Army",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W0XS",
                "size": "XS",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3W3XL",
                "size": "3XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H0XS",
                "size": "XS",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H2XL",
                "size": "2XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2H3XL",
                "size": "3XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C0XS",
                "size": "XS",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C00S",
                "size": "S",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C00M",
                "size": "M",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C00L",
                "size": "L",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C0XL",
                "size": "XL",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C2XL",
                "size": "2XL",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4C3XL",
                "size": "3XL",
                "color": "Baby Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R0XS",
                "size": "XS",
                "color": "Berry",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R00S",
                "size": "S",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R00M",
                "size": "M",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R00L",
                "size": "L",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R0XL",
                "size": "XL",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R2XL",
                "size": "2XL",
                "color": "Berry",
                "stock": 214,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0R3XL",
                "size": "3XL",
                "color": "Berry",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K0XS",
                "size": "XS",
                "color": "Black Heather",
                "stock": 164,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K00S",
                "size": "S",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K00M",
                "size": "M",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K00L",
                "size": "L",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K0XL",
                "size": "XL",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K2XL",
                "size": "2XL",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3K3XL",
                "size": "3XL",
                "color": "Black Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S0XS",
                "size": "XS",
                "color": "Canvas Red",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S00S",
                "size": "S",
                "color": "Canvas Red",
                "stock": 235,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S00M",
                "size": "M",
                "color": "Canvas Red",
                "stock": 201,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S00L",
                "size": "L",
                "color": "Canvas Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S0XL",
                "size": "XL",
                "color": "Canvas Red",
                "stock": 233,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S2XL",
                "size": "2XL",
                "color": "Canvas Red",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1S3XL",
                "size": "3XL",
                "color": "Canvas Red",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R0XS",
                "size": "XS",
                "color": "Cardinal",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R00S",
                "size": "S",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R00M",
                "size": "M",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R00L",
                "size": "L",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R0XL",
                "size": "XL",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R2XL",
                "size": "2XL",
                "color": "Cardinal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6R3XL",
                "size": "3XL",
                "color": "Cardinal",
                "stock": 209,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P0XS",
                "size": "XS",
                "color": "Charity Pink",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P00S",
                "size": "S",
                "color": "Charity Pink",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P00M",
                "size": "M",
                "color": "Charity Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P00L",
                "size": "L",
                "color": "Charity Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P0XL",
                "size": "XL",
                "color": "Charity Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P2XL",
                "size": "2XL",
                "color": "Charity Pink",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7P3XL",
                "size": "3XL",
                "color": "Charity Pink",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K0XS",
                "size": "XS",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K2XL",
                "size": "2XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K3XL",
                "size": "3XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F0XS",
                "size": "XS",
                "color": "Dark Olive",
                "stock": 180,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F00S",
                "size": "S",
                "color": "Dark Olive",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F00M",
                "size": "M",
                "color": "Dark Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F00L",
                "size": "L",
                "color": "Dark Olive",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F0XL",
                "size": "XL",
                "color": "Dark Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F2XL",
                "size": "2XL",
                "color": "Dark Olive",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8F3XL",
                "size": "3XL",
                "color": "Dark Olive",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H0XS",
                "size": "XS",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H2XL",
                "size": "2XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H3XL",
                "size": "3XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F0XS",
                "size": "XS",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F00S",
                "size": "S",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F00M",
                "size": "M",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F00L",
                "size": "L",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F0XL",
                "size": "XL",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F2XL",
                "size": "2XL",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0F3XL",
                "size": "3XL",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y0XS",
                "size": "XS",
                "color": "Gold",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 205,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y0XS",
                "size": "XS",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y00S",
                "size": "S",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y00M",
                "size": "M",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y00L",
                "size": "L",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y0XL",
                "size": "XL",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y2XL",
                "size": "2XL",
                "color": "Heather Clay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Y3XL",
                "size": "3XL",
                "color": "Heather Clay",
                "stock": 186,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D0XS",
                "size": "XS",
                "color": "Heather Columbia Blue",
                "stock": 181,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D00S",
                "size": "S",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D00M",
                "size": "M",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D00L",
                "size": "L",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D0XL",
                "size": "XL",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D2XL",
                "size": "2XL",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2D3XL",
                "size": "3XL",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G0XS",
                "size": "XS",
                "color": "Heather Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G00S",
                "size": "S",
                "color": "Heather Green",
                "stock": 224,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G00M",
                "size": "M",
                "color": "Heather Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G00L",
                "size": "L",
                "color": "Heather Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G0XL",
                "size": "XL",
                "color": "Heather Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G2XL",
                "size": "2XL",
                "color": "Heather Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2G3XL",
                "size": "3XL",
                "color": "Heather Green",
                "stock": 166,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N0XS",
                "size": "XS",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N00S",
                "size": "S",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N00M",
                "size": "M",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N00L",
                "size": "L",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N0XL",
                "size": "XL",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N2XL",
                "size": "2XL",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7N3XL",
                "size": "3XL",
                "color": "Heather Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G0XS",
                "size": "XS",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G00S",
                "size": "S",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G00M",
                "size": "M",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G00L",
                "size": "L",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G0XL",
                "size": "XL",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G2XL",
                "size": "2XL",
                "color": "Heather Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9G3XL",
                "size": "3XL",
                "color": "Heather Kelly",
                "stock": 161,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R0XS",
                "size": "XS",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R00S",
                "size": "S",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R00M",
                "size": "M",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R00L",
                "size": "L",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R0XL",
                "size": "XL",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R2XL",
                "size": "2XL",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8R3XL",
                "size": "3XL",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G0XS",
                "size": "XS",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G00S",
                "size": "S",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G00M",
                "size": "M",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G00L",
                "size": "L",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G0XL",
                "size": "XL",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G2XL",
                "size": "2XL",
                "color": "Heather Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0G3XL",
                "size": "3XL",
                "color": "Heather Mint",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N0XS",
                "size": "XS",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N2XL",
                "size": "2XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N3XL",
                "size": "3XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F0XS",
                "size": "XS",
                "color": "Heather Olive",
                "stock": 231,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F00S",
                "size": "S",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F00M",
                "size": "M",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F00L",
                "size": "L",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F0XL",
                "size": "XL",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F2XL",
                "size": "2XL",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4F3XL",
                "size": "3XL",
                "color": "Heather Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O0XS",
                "size": "XS",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O00S",
                "size": "S",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O00M",
                "size": "M",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O00L",
                "size": "L",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O0XL",
                "size": "XL",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O2XL",
                "size": "2XL",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2O3XL",
                "size": "3XL",
                "color": "Heather Peach",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R0XS",
                "size": "XS",
                "color": "Heather Raspberry",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R00S",
                "size": "S",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R00M",
                "size": "M",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R00L",
                "size": "L",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R0XL",
                "size": "XL",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R2XL",
                "size": "2XL",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9R3XL",
                "size": "3XL",
                "color": "Heather Raspberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R0XS",
                "size": "XS",
                "color": "Heather Red",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R00S",
                "size": "S",
                "color": "Heather Red",
                "stock": 208,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R00M",
                "size": "M",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R00L",
                "size": "L",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R0XL",
                "size": "XL",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R2XL",
                "size": "2XL",
                "color": "Heather Red",
                "stock": 222,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R3XL",
                "size": "3XL",
                "color": "Heather Red",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W0XS",
                "size": "XS",
                "color": "Heather Slate",
                "stock": 245,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W00S",
                "size": "S",
                "color": "Heather Slate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W00M",
                "size": "M",
                "color": "Heather Slate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W00L",
                "size": "L",
                "color": "Heather Slate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W0XL",
                "size": "XL",
                "color": "Heather Slate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W2XL",
                "size": "2XL",
                "color": "Heather Slate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6W3XL",
                "size": "3XL",
                "color": "Heather Slate",
                "stock": 182,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q0XS",
                "size": "XS",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q00S",
                "size": "S",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q00M",
                "size": "M",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q00L",
                "size": "L",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q0XL",
                "size": "XL",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q2XL",
                "size": "2XL",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3Q3XL",
                "size": "3XL",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C0XS",
                "size": "XS",
                "color": "Heather True Royal",
                "stock": 238,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C00S",
                "size": "S",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C00M",
                "size": "M",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C00L",
                "size": "L",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C0XL",
                "size": "XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C2XL",
                "size": "2XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C3XL",
                "size": "3XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G0XS",
                "size": "XS",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G00S",
                "size": "S",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G00M",
                "size": "M",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G00L",
                "size": "L",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G0XL",
                "size": "XL",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G2XL",
                "size": "2XL",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7G3XL",
                "size": "3XL",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N0XS",
                "size": "XS",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y0XS",
                "size": "XS",
                "color": "Maize Yellow",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y00S",
                "size": "S",
                "color": "Maize Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y00M",
                "size": "M",
                "color": "Maize Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y00L",
                "size": "L",
                "color": "Maize Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y0XL",
                "size": "XL",
                "color": "Maize Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y2XL",
                "size": "2XL",
                "color": "Maize Yellow",
                "stock": 199,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8Y3XL",
                "size": "3XL",
                "color": "Maize Yellow",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R0XS",
                "size": "XS",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5R3XL",
                "size": "3XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E0XS",
                "size": "XS",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E00S",
                "size": "S",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E00M",
                "size": "M",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E00L",
                "size": "L",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E0XL",
                "size": "XL",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E2XL",
                "size": "2XL",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4E3XL",
                "size": "3XL",
                "color": "Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W0XS",
                "size": "XS",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W00S",
                "size": "S",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W00M",
                "size": "M",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W00L",
                "size": "L",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W0XL",
                "size": "XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W2XL",
                "size": "2XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5W3XL",
                "size": "3XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F0XS",
                "size": "XS",
                "color": "Olive",
                "stock": 188,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F00S",
                "size": "S",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F00M",
                "size": "M",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F00L",
                "size": "L",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F0XL",
                "size": "XL",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F2XL",
                "size": "2XL",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9F3XL",
                "size": "3XL",
                "color": "Olive",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O0XS",
                "size": "XS",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 195,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P0XS",
                "size": "XS",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P00S",
                "size": "S",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P00M",
                "size": "M",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P00L",
                "size": "L",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P0XL",
                "size": "XL",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P2XL",
                "size": "2XL",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1P3XL",
                "size": "3XL",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W0XS",
                "size": "XS",
                "color": "Silver",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W00S",
                "size": "S",
                "color": "Silver",
                "stock": 191,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W00M",
                "size": "M",
                "color": "Silver",
                "stock": 214,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W00L",
                "size": "L",
                "color": "Silver",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W0XL",
                "size": "XL",
                "color": "Silver",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W2XL",
                "size": "2XL",
                "color": "Silver",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4W3XL",
                "size": "3XL",
                "color": "Silver",
                "stock": 198,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P0XS",
                "size": "XS",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P00S",
                "size": "S",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P00M",
                "size": "M",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P00L",
                "size": "L",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P0XL",
                "size": "XL",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P2XL",
                "size": "2XL",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3P3XL",
                "size": "3XL",
                "color": "Soft Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B0XS",
                "size": "XS",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B00S",
                "size": "S",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B00M",
                "size": "M",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B00L",
                "size": "L",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B0XL",
                "size": "XL",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B2XL",
                "size": "2XL",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9B3XL",
                "size": "3XL",
                "color": "Solid Black Blend",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D0XS",
                "size": "XS",
                "color": "Teal",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D00S",
                "size": "S",
                "color": "Teal",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D00M",
                "size": "M",
                "color": "Teal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D00L",
                "size": "L",
                "color": "Teal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D0XL",
                "size": "XL",
                "color": "Teal",
                "stock": 181,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D2XL",
                "size": "2XL",
                "color": "Teal",
                "stock": 151,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8D3XL",
                "size": "3XL",
                "color": "Teal",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q0XS",
                "size": "XS",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q00S",
                "size": "S",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q00M",
                "size": "M",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q00L",
                "size": "L",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q0XL",
                "size": "XL",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q2XL",
                "size": "2XL",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5Q3XL",
                "size": "3XL",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C0XS",
                "size": "XS",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C2XL",
                "size": "2XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5C3XL",
                "size": "3XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B0XS",
                "size": "XS",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B00S",
                "size": "S",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B00M",
                "size": "M",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B00L",
                "size": "L",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B0XL",
                "size": "XL",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B2XL",
                "size": "2XL",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT8B3XL",
                "size": "3XL",
                "color": "Vintage Black",
                "stock": 235,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y0XS",
                "size": "XS",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y00S",
                "size": "S",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y00M",
                "size": "M",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y00L",
                "size": "L",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y0XL",
                "size": "XL",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y2XL",
                "size": "2XL",
                "color": "Yellow",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1Y3XL",
                "size": "3XL",
                "color": "Yellow",
                "stock": 177,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B0XS",
                "size": "XS",
                "color": "Asphalt",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B00S",
                "size": "S",
                "color": "Asphalt",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B00M",
                "size": "M",
                "color": "Asphalt",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B00L",
                "size": "L",
                "color": "Asphalt",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B0XL",
                "size": "XL",
                "color": "Asphalt",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B2XL",
                "size": "2XL",
                "color": "Asphalt",
                "stock": 230,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B3XL",
                "size": "3XL",
                "color": "Asphalt",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K0XS",
                "size": "XS",
                "color": "Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K00S",
                "size": "S",
                "color": "Dark Grey",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K00M",
                "size": "M",
                "color": "Dark Grey",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K00L",
                "size": "L",
                "color": "Dark Grey",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K0XL",
                "size": "XL",
                "color": "Dark Grey",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K2XL",
                "size": "2XL",
                "color": "Dark Grey",
                "stock": 185,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5K3XL",
                "size": "3XL",
                "color": "Dark Grey",
                "stock": 241,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W0XS",
                "size": "XS",
                "color": "Heather Prism Natural",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W00S",
                "size": "S",
                "color": "Heather Prism Natural",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W00M",
                "size": "M",
                "color": "Heather Prism Natural",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W00L",
                "size": "L",
                "color": "Heather Prism Natural",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W0XL",
                "size": "XL",
                "color": "Heather Prism Natural",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W2XL",
                "size": "2XL",
                "color": "Heather Prism Natural",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0W3XL",
                "size": "3XL",
                "color": "Heather Prism Natural",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D0XS",
                "size": "XS",
                "color": "Steel Blue",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D00S",
                "size": "S",
                "color": "Steel Blue",
                "stock": 88,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D00M",
                "size": "M",
                "color": "Steel Blue",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D00L",
                "size": "L",
                "color": "Steel Blue",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D0XL",
                "size": "XL",
                "color": "Steel Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D2XL",
                "size": "2XL",
                "color": "Steel Blue",
                "stock": 95,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9D3XL",
                "size": "3XL",
                "color": "Steel Blue",
                "stock": 9,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNPT6E0XS",
                "size": "XS",
                "color": "Heather Sea Green",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E00S",
                "size": "S",
                "color": "Heather Sea Green",
                "stock": 88,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E00M",
                "size": "M",
                "color": "Heather Sea Green",
                "stock": 176,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E00L",
                "size": "L",
                "color": "Heather Sea Green",
                "stock": 207,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E0XL",
                "size": "XL",
                "color": "Heather Sea Green",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E2XL",
                "size": "2XL",
                "color": "Heather Sea Green",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6E3XL",
                "size": "3XL",
                "color": "Heather Sea Green",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E0XS",
                "size": "XS",
                "color": "Leaf",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E00S",
                "size": "S",
                "color": "Leaf",
                "stock": 221,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E00M",
                "size": "M",
                "color": "Leaf",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E00L",
                "size": "L",
                "color": "Leaf",
                "stock": 215,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E0XL",
                "size": "XL",
                "color": "Leaf",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E2XL",
                "size": "2XL",
                "color": "Leaf",
                "stock": 88,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3E3XL",
                "size": "3XL",
                "color": "Leaf",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4B4XL",
                "size": "4XL",
                "color": "Asphalt",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1B4XL",
                "size": "4XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1K4XL",
                "size": "4XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT1W4XL",
                "size": "4XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT6H4XL",
                "size": "4XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W00S",
                "size": "S",
                "color": "Soft Cream",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W00M",
                "size": "M",
                "color": "Soft Cream",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W00L",
                "size": "L",
                "color": "Soft Cream",
                "stock": 210,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W0XL",
                "size": "XL",
                "color": "Soft Cream",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W2XL",
                "size": "2XL",
                "color": "Soft Cream",
                "stock": 113,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W3XL",
                "size": "3XL",
                "color": "Soft Cream",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNPT7W0XS",
                "size": "XS",
                "color": "Soft Cream",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M2XL",
                "size": "2XL",
                "color": "Brown",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M3XL",
                "size": "3XL",
                "color": "Brown",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M00L",
                "size": "L",
                "color": "Brown",
                "stock": 147,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M00M",
                "size": "M",
                "color": "Brown",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M00S",
                "size": "S",
                "color": "Brown",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M0XL",
                "size": "XL",
                "color": "Brown",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "UNPT4M0XS",
                "size": "XS",
                "color": "Brown",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB92XL",
                "size": "2XL",
                "color": "Burnt Orange",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB900L",
                "size": "L",
                "color": "Burnt Orange",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB900M",
                "size": "M",
                "color": "Burnt Orange",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB900S",
                "size": "S",
                "color": "Burnt Orange",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB90XL",
                "size": "XL",
                "color": "Burnt Orange",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB90XS",
                "size": "XS",
                "color": "Burnt Orange",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J2XL",
                "size": "2XL",
                "color": "Coral",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J3XL",
                "size": "3XL",
                "color": "Coral",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J00L",
                "size": "L",
                "color": "Coral",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J00M",
                "size": "M",
                "color": "Coral",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J00S",
                "size": "S",
                "color": "Coral",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J0XL",
                "size": "XL",
                "color": "Coral",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3J0XS",
                "size": "XS",
                "color": "Coral",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L2XL",
                "size": "2XL",
                "color": "Deep Teal",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L3XL",
                "size": "3XL",
                "color": "Deep Teal",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L00L",
                "size": "L",
                "color": "Deep Teal",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L00M",
                "size": "M",
                "color": "Deep Teal",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L00S",
                "size": "S",
                "color": "Deep Teal",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L0XL",
                "size": "XL",
                "color": "Deep Teal",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2L0XS",
                "size": "XS",
                "color": "Deep Teal",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I2XL",
                "size": "2XL",
                "color": "Heather Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I3XL",
                "size": "3XL",
                "color": "Heather Aqua",
                "stock": 146,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I00L",
                "size": "L",
                "color": "Heather Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I00M",
                "size": "M",
                "color": "Heather Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I00S",
                "size": "S",
                "color": "Heather Aqua",
                "stock": 201,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I0XL",
                "size": "XL",
                "color": "Heather Aqua",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5I0XS",
                "size": "XS",
                "color": "Heather Aqua",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J2XL",
                "size": "2XL",
                "color": "Heather Maroon",
                "stock": 167,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J3XL",
                "size": "3XL",
                "color": "Heather Maroon",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J00L",
                "size": "L",
                "color": "Heather Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J00M",
                "size": "M",
                "color": "Heather Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J00S",
                "size": "S",
                "color": "Heather Maroon",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J0XL",
                "size": "XL",
                "color": "Heather Maroon",
                "stock": 206,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2J0XS",
                "size": "XS",
                "color": "Heather Maroon",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "UNPT9N4XL",
                "size": "4XL",
                "color": "Heather Navy",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "UNPT2R4XL",
                "size": "4XL",
                "color": "Heather Red",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNPT3C4XL",
                "size": "4XL",
                "color": "Heather True Royal",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH82XL",
                "size": "2XL",
                "color": "Lilac",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH83XL",
                "size": "3XL",
                "color": "Lilac",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH800L",
                "size": "L",
                "color": "Lilac",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH800M",
                "size": "M",
                "color": "Lilac",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH800S",
                "size": "S",
                "color": "Lilac",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH80XL",
                "size": "XL",
                "color": "Lilac",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPTH80XS",
                "size": "XS",
                "color": "Lilac",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK12XL",
                "size": "2XL",
                "color": "Pebble",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK13XL",
                "size": "3XL",
                "color": "Pebble",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK100L",
                "size": "L",
                "color": "Pebble",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK100M",
                "size": "M",
                "color": "Pebble",
                "stock": 154,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK100S",
                "size": "S",
                "color": "Pebble",
                "stock": 150,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK10XL",
                "size": "XL",
                "color": "Pebble",
                "stock": 220,
                "status": "In Stock"
            },
            {
                "sku": "UNPTK10XS",
                "size": "XS",
                "color": "Pebble",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O2XL",
                "size": "2XL",
                "color": "Sunset",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O3XL",
                "size": "3XL",
                "color": "Sunset",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O00L",
                "size": "L",
                "color": "Sunset",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O00M",
                "size": "M",
                "color": "Sunset",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O00S",
                "size": "S",
                "color": "Sunset",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O0XL",
                "size": "XL",
                "color": "Sunset",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "UNPT5O0XS",
                "size": "XS",
                "color": "Sunset",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D2XL",
                "size": "2XL",
                "color": "Turquoise",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D3XL",
                "size": "3XL",
                "color": "Turquoise",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D4XL",
                "size": "4XL",
                "color": "Turquoise",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D00L",
                "size": "L",
                "color": "Turquoise",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D00M",
                "size": "M",
                "color": "Turquoise",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D00S",
                "size": "S",
                "color": "Turquoise",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D0XL",
                "size": "XL",
                "color": "Turquoise",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN92XL",
                "size": "2XL",
                "color": "Vintage White",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN93XL",
                "size": "3XL",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN900L",
                "size": "L",
                "color": "Vintage White",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN900M",
                "size": "M",
                "color": "Vintage White",
                "stock": 164,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN900S",
                "size": "S",
                "color": "Vintage White",
                "stock": 146,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN90XL",
                "size": "XL",
                "color": "Vintage White",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "UNPTN90XS",
                "size": "XS",
                "color": "Vintage White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS300S",
                "size": "S",
                "color": "Heather Prism Lilac",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS300M",
                "size": "M",
                "color": "Heather Prism Lilac",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS300L",
                "size": "L",
                "color": "Heather Prism Lilac",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS30XL",
                "size": "XL",
                "color": "Heather Prism Lilac",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS32XL",
                "size": "2XL",
                "color": "Heather Prism Lilac",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS33XL",
                "size": "3XL",
                "color": "Heather Prism Lilac",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNPTS30XS",
                "size": "XS",
                "color": "Heather Prism Lilac",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY00XS",
                "size": "XS",
                "color": "Oxblood Black",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY000S",
                "size": "S",
                "color": "Oxblood Black",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY000M",
                "size": "M",
                "color": "Oxblood Black",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY000L",
                "size": "L",
                "color": "Oxblood Black",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY00XL",
                "size": "XL",
                "color": "Oxblood Black",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY02XL",
                "size": "2XL",
                "color": "Oxblood Black",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNPTB93XL",
                "size": "3XL",
                "color": "BURNT ORANGE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPT0D00XS",
                "size": "XS",
                "color": "TURQUOISE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNPTY03XL",
                "size": "3XL",
                "color": "OXBLOOD BLACK",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex T-shirt - Bella + Canvas | 3001",
        "sku": "Unisex-BellaCanvas-UnisexT-Shirt",
        "id": "244a6c26-b4f8-4acf-bb6b-b20f047e2e49"
    },
    {
        "category": "Kids",
        "variations": [
            {
                "sku": "YOPT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 244,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0R00S",
                "size": "S",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0R00M",
                "size": "M",
                "color": "Berry",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0R00L",
                "size": "L",
                "color": "Berry",
                "stock": 212,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0R0XL",
                "size": "XL",
                "color": "Berry",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3K00S",
                "size": "S",
                "color": "Black Heather",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3K00M",
                "size": "M",
                "color": "Black Heather",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3K00L",
                "size": "L",
                "color": "Black Heather",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3K0XL",
                "size": "XL",
                "color": "Black Heather",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "YOPT6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "YOPT6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "YOPT6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0F00S",
                "size": "S",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0F00M",
                "size": "M",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0F00L",
                "size": "L",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT0F0XL",
                "size": "XL",
                "color": "Forest",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 147,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2D00S",
                "size": "S",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2D00M",
                "size": "M",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2D00L",
                "size": "L",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2D0XL",
                "size": "XL",
                "color": "Heather Columbia Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8R00S",
                "size": "S",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8R00M",
                "size": "M",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8R00L",
                "size": "L",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8R0XL",
                "size": "XL",
                "color": "Heather Mauve",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2R00S",
                "size": "S",
                "color": "Heather Red",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2R00M",
                "size": "M",
                "color": "Heather Red",
                "stock": 209,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2R00L",
                "size": "L",
                "color": "Heather Red",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "YOPT2R0XL",
                "size": "XL",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Q00S",
                "size": "S",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Q00M",
                "size": "M",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Q00L",
                "size": "L",
                "color": "Heather Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3Q0XL",
                "size": "XL",
                "color": "Heather Team Purple",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3C00S",
                "size": "S",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3C00M",
                "size": "M",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3C00L",
                "size": "L",
                "color": "Heather True Royal",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "YOPT3C0XL",
                "size": "XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT7G00S",
                "size": "S",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT7G00M",
                "size": "M",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT7G00L",
                "size": "L",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT7G0XL",
                "size": "XL",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 211,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5W00S",
                "size": "S",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5W00M",
                "size": "M",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5W00L",
                "size": "L",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5W0XL",
                "size": "XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1P00S",
                "size": "S",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1P00M",
                "size": "M",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1P00L",
                "size": "L",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT1P0XL",
                "size": "XL",
                "color": "Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5Q00S",
                "size": "S",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5Q00M",
                "size": "M",
                "color": "Team Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5Q00L",
                "size": "L",
                "color": "Team Purple",
                "stock": 148,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5Q0XL",
                "size": "XL",
                "color": "Team Purple",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8B00S",
                "size": "S",
                "color": "Vintage Black",
                "stock": 197,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8B00M",
                "size": "M",
                "color": "Vintage Black",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8B00L",
                "size": "L",
                "color": "Vintage Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOPT8B0XL",
                "size": "XL",
                "color": "Vintage Black",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "YOPT4B00S",
                "size": "S",
                "color": "Asphalt",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "YOPT4B00M",
                "size": "M",
                "color": "Asphalt",
                "stock": 119,
                "status": "In Stock"
            },
            {
                "sku": "YOPT4B00L",
                "size": "L",
                "color": "Asphalt",
                "stock": 112,
                "status": "In Stock"
            },
            {
                "sku": "YOPT4B0XL",
                "size": "XL",
                "color": "Asphalt",
                "stock": 181,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth T-shirt - Bella + Canvas | 3001Y",
        "sku": "Youth-BellaCanvas-YouthT-Shirt",
        "id": "3cd2eb97-5948-46b3-ad12-661fc3be3a68"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "CCSWA000S",
                "size": "S",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWA000M",
                "size": "M",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWA000L",
                "size": "L",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWA00XL",
                "size": "XL",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWA02XL",
                "size": "2XL",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWB200S",
                "size": "S",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWB200M",
                "size": "M",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWB200L",
                "size": "L",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWB20XL",
                "size": "XL",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWB22XL",
                "size": "2XL",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6Y00S",
                "size": "S",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6Y00M",
                "size": "M",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6Y00L",
                "size": "L",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6Y0XL",
                "size": "XL",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6Y2XL",
                "size": "2XL",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC300S",
                "size": "S",
                "color": "Chalky Mint",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC300M",
                "size": "M",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC300L",
                "size": "L",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC30XL",
                "size": "XL",
                "color": "Chalky Mint",
                "stock": 198,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC32XL",
                "size": "2XL",
                "color": "Chalky Mint",
                "stock": 188,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC400S",
                "size": "S",
                "color": "Chambray",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC400M",
                "size": "M",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC400L",
                "size": "L",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC40XL",
                "size": "XL",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWC42XL",
                "size": "2XL",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW8S00S",
                "size": "S",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW8S00M",
                "size": "M",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW8S00L",
                "size": "L",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW8S0XL",
                "size": "XL",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW8S2XL",
                "size": "2XL",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6L00S",
                "size": "S",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6L00M",
                "size": "M",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6L00L",
                "size": "L",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6L0XL",
                "size": "XL",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW6L2XL",
                "size": "2XL",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWF500S",
                "size": "S",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWF500M",
                "size": "M",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWF500L",
                "size": "L",
                "color": "Flo Blue",
                "stock": 235,
                "status": "In Stock"
            },
            {
                "sku": "CCSWF50XL",
                "size": "XL",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWF52XL",
                "size": "2XL",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW9H00S",
                "size": "S",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW9H00M",
                "size": "M",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW9H00L",
                "size": "L",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW9H0XL",
                "size": "XL",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW9H2XL",
                "size": "2XL",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH400S",
                "size": "S",
                "color": "Lagoon",
                "stock": 29,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCSWH400M",
                "size": "M",
                "color": "Lagoon",
                "stock": 7,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCSWH400L",
                "size": "L",
                "color": "Lagoon",
                "stock": 72,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCSWH40XL",
                "size": "XL",
                "color": "Lagoon",
                "stock": 46,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCSWH42XL",
                "size": "2XL",
                "color": "Lagoon",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH700S",
                "size": "S",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH700M",
                "size": "M",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH700L",
                "size": "L",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH70XL",
                "size": "XL",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWH72XL",
                "size": "2XL",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWJ500S",
                "size": "S",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWJ500M",
                "size": "M",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWJ500L",
                "size": "L",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWJ50XL",
                "size": "XL",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWJ52XL",
                "size": "2XL",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWK300S",
                "size": "S",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWK300M",
                "size": "M",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWK300L",
                "size": "L",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWK30XL",
                "size": "XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWK32XL",
                "size": "2XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWL200S",
                "size": "S",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWL200M",
                "size": "M",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWL200L",
                "size": "L",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWL20XL",
                "size": "XL",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWL22XL",
                "size": "2XL",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN600S",
                "size": "S",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN600M",
                "size": "M",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN600L",
                "size": "L",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN60XL",
                "size": "XL",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN62XL",
                "size": "2XL",
                "color": "True Navy",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN000S",
                "size": "S",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN000M",
                "size": "M",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN000L",
                "size": "L",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWN02XL",
                "size": "2XL",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWO300S",
                "size": "S",
                "color": "Watermelon",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "CCSWO300M",
                "size": "M",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWO300L",
                "size": "L",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWO30XL",
                "size": "XL",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSWO32XL",
                "size": "2XL",
                "color": "Watermelon",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "CCSW1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCSW1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Sweatshirt - Comfort Color | 1566",
        "sku": "Unisex-ComfortColor-Sweatshirt",
        "id": "e2bb50ee-0627-4f3c-ba83-bda0513a0e7b"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "CCBT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA000S",
                "size": "S",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL200S",
                "size": "S",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN000S",
                "size": "S",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA000M",
                "size": "M",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL200M",
                "size": "M",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN000M",
                "size": "M",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA000L",
                "size": "L",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL200L",
                "size": "L",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN000L",
                "size": "L",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA00XL",
                "size": "XL",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL20XL",
                "size": "XL",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA02XL",
                "size": "2XL",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL22XL",
                "size": "2XL",
                "color": "Seafoam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN02XL",
                "size": "2XL",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA03XL",
                "size": "3XL",
                "color": "Blue Jean",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTL23XL",
                "size": "3XL",
                "color": "Seafoam",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN03XL",
                "size": "3XL",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H2XL",
                "size": "2XL",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H3XL",
                "size": "3XL",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H00L",
                "size": "L",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H00M",
                "size": "M",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H00S",
                "size": "S",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT9H0XL",
                "size": "XL",
                "color": "Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA600S",
                "size": "S",
                "color": "Banana",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA600M",
                "size": "M",
                "color": "Banana",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA600L",
                "size": "L",
                "color": "Banana",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA60XL",
                "size": "XL",
                "color": "Banana",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA62XL",
                "size": "2XL",
                "color": "Banana",
                "stock": 228,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA63XL",
                "size": "3XL",
                "color": "Banana",
                "stock": 169,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTA700S",
                "size": "S",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA700M",
                "size": "M",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA700L",
                "size": "L",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA70XL",
                "size": "XL",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA72XL",
                "size": "2XL",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA73XL",
                "size": "3XL",
                "color": "Bay",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R00S",
                "size": "S",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R00M",
                "size": "M",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R00L",
                "size": "L",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R0XL",
                "size": "XL",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R2XL",
                "size": "2XL",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0R3XL",
                "size": "3XL",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA900S",
                "size": "S",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA900M",
                "size": "M",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA900L",
                "size": "L",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA90XL",
                "size": "XL",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA92XL",
                "size": "2XL",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTA93XL",
                "size": "3XL",
                "color": "Blossom",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB200S",
                "size": "S",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB200M",
                "size": "M",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB200L",
                "size": "L",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB20XL",
                "size": "XL",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB22XL",
                "size": "2XL",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB23XL",
                "size": "3XL",
                "color": "Blue Spruce",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB500S",
                "size": "S",
                "color": "Brick",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB500M",
                "size": "M",
                "color": "Brick",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB500L",
                "size": "L",
                "color": "Brick",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB50XL",
                "size": "XL",
                "color": "Brick",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB52XL",
                "size": "2XL",
                "color": "Brick",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB53XL",
                "size": "3XL",
                "color": "Brick",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB700S",
                "size": "S",
                "color": "Bright Salmon",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB700M",
                "size": "M",
                "color": "Bright Salmon",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB700L",
                "size": "L",
                "color": "Bright Salmon",
                "stock": 210,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB70XL",
                "size": "XL",
                "color": "Bright Salmon",
                "stock": 202,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB72XL",
                "size": "2XL",
                "color": "Bright Salmon",
                "stock": 97,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB73XL",
                "size": "3XL",
                "color": "Bright Salmon",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB900S",
                "size": "S",
                "color": "Burnt Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB900M",
                "size": "M",
                "color": "Burnt Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB900L",
                "size": "L",
                "color": "Burnt Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB90XL",
                "size": "XL",
                "color": "Burnt Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB92XL",
                "size": "2XL",
                "color": "Burnt Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTB93XL",
                "size": "3XL",
                "color": "Burnt Orange",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y00S",
                "size": "S",
                "color": "Butter",
                "stock": 232,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y00M",
                "size": "M",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y00L",
                "size": "L",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y0XL",
                "size": "XL",
                "color": "Butter",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y2XL",
                "size": "2XL",
                "color": "Butter",
                "stock": 187,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6Y3XL",
                "size": "3XL",
                "color": "Butter",
                "stock": 164,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTC300S",
                "size": "S",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC300M",
                "size": "M",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC300L",
                "size": "L",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC30XL",
                "size": "XL",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC32XL",
                "size": "2XL",
                "color": "Chalky Mint",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC33XL",
                "size": "3XL",
                "color": "Chalky Mint",
                "stock": 184,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC400S",
                "size": "S",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC400M",
                "size": "M",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC400L",
                "size": "L",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC40XL",
                "size": "XL",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC42XL",
                "size": "2XL",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC43XL",
                "size": "3XL",
                "color": "Chambray",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC700S",
                "size": "S",
                "color": "Chili",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC700M",
                "size": "M",
                "color": "Chili",
                "stock": 160,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC700L",
                "size": "L",
                "color": "Chili",
                "stock": 203,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC70XL",
                "size": "XL",
                "color": "Chili",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC72XL",
                "size": "2XL",
                "color": "Chili",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC73XL",
                "size": "3XL",
                "color": "Chili",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC900S",
                "size": "S",
                "color": "Citrus",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC900M",
                "size": "M",
                "color": "Citrus",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC900L",
                "size": "L",
                "color": "Citrus",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC90XL",
                "size": "XL",
                "color": "Citrus",
                "stock": 184,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC92XL",
                "size": "2XL",
                "color": "Citrus",
                "stock": 113,
                "status": "In Stock"
            },
            {
                "sku": "CCBTC93XL",
                "size": "3XL",
                "color": "Citrus",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S00S",
                "size": "S",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S00M",
                "size": "M",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S00L",
                "size": "L",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S0XL",
                "size": "XL",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S2XL",
                "size": "2XL",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8S3XL",
                "size": "3XL",
                "color": "Crimson",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD500S",
                "size": "S",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD500M",
                "size": "M",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD500L",
                "size": "L",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD50XL",
                "size": "XL",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD52XL",
                "size": "2XL",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD53XL",
                "size": "3XL",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD600S",
                "size": "S",
                "color": "Cumin",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTD600M",
                "size": "M",
                "color": "Cumin",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD600L",
                "size": "L",
                "color": "Cumin",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTD60XL",
                "size": "XL",
                "color": "Cumin",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTD62XL",
                "size": "2XL",
                "color": "Cumin",
                "stock": 43,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTD63XL",
                "size": "3XL",
                "color": "Cumin",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L00S",
                "size": "S",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L00M",
                "size": "M",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L00L",
                "size": "L",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L0XL",
                "size": "XL",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L2XL",
                "size": "2XL",
                "color": "Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT6L3XL",
                "size": "3XL",
                "color": "Denim",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF500S",
                "size": "S",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF500M",
                "size": "M",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF500L",
                "size": "L",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF50XL",
                "size": "XL",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF52XL",
                "size": "2XL",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF53XL",
                "size": "3XL",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF600S",
                "size": "S",
                "color": "Granite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF600M",
                "size": "M",
                "color": "Granite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF600L",
                "size": "L",
                "color": "Granite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF60XL",
                "size": "XL",
                "color": "Granite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF62XL",
                "size": "2XL",
                "color": "Granite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF63XL",
                "size": "3XL",
                "color": "Granite",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF700S",
                "size": "S",
                "color": "Grape",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF700M",
                "size": "M",
                "color": "Grape",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF700L",
                "size": "L",
                "color": "Grape",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF70XL",
                "size": "XL",
                "color": "Grape",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF72XL",
                "size": "2XL",
                "color": "Grape",
                "stock": 225,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF73XL",
                "size": "3XL",
                "color": "Grape",
                "stock": 121,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTF800S",
                "size": "S",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF800M",
                "size": "M",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF800L",
                "size": "L",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF80XL",
                "size": "XL",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF82XL",
                "size": "2XL",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTF83XL",
                "size": "3XL",
                "color": "Graphite",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8E00S",
                "size": "S",
                "color": "Grass",
                "stock": 63,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8E00M",
                "size": "M",
                "color": "Grass",
                "stock": 1,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8E00L",
                "size": "L",
                "color": "Grass",
                "stock": 14,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8E0XL",
                "size": "XL",
                "color": "Grass",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8E2XL",
                "size": "2XL",
                "color": "Grass",
                "stock": 35,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8E3XL",
                "size": "3XL",
                "color": "Grass",
                "stock": 5,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 29,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 92,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P2XL",
                "size": "2XL",
                "color": "Heliconia",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT8P3XL",
                "size": "3XL",
                "color": "Heliconia",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTG400S",
                "size": "S",
                "color": "Hemp",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG400M",
                "size": "M",
                "color": "Hemp",
                "stock": 210,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG400L",
                "size": "L",
                "color": "Hemp",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG40XL",
                "size": "XL",
                "color": "Hemp",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG42XL",
                "size": "2XL",
                "color": "Hemp",
                "stock": 228,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG43XL",
                "size": "3XL",
                "color": "Hemp",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG700S",
                "size": "S",
                "color": "Ice Blue",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG700M",
                "size": "M",
                "color": "Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG700L",
                "size": "L",
                "color": "Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG70XL",
                "size": "XL",
                "color": "Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG72XL",
                "size": "2XL",
                "color": "Ice Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG73XL",
                "size": "3XL",
                "color": "Ice Blue",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG000S",
                "size": "S",
                "color": "Island Reef",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG000M",
                "size": "M",
                "color": "Island Reef",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG000L",
                "size": "L",
                "color": "Island Reef",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG00XL",
                "size": "XL",
                "color": "Island Reef",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG02XL",
                "size": "2XL",
                "color": "Island Reef",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTG03XL",
                "size": "3XL",
                "color": "Island Reef",
                "stock": 137,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X00S",
                "size": "S",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X00M",
                "size": "M",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X00L",
                "size": "L",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X0XL",
                "size": "XL",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X2XL",
                "size": "2XL",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT0X3XL",
                "size": "3XL",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M00S",
                "size": "S",
                "color": "Khaki",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M00M",
                "size": "M",
                "color": "Khaki",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M00L",
                "size": "L",
                "color": "Khaki",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M0XL",
                "size": "XL",
                "color": "Khaki",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M2XL",
                "size": "2XL",
                "color": "Khaki",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT7M3XL",
                "size": "3XL",
                "color": "Khaki",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT4G00S",
                "size": "S",
                "color": "Kiwi",
                "stock": 29,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT4G00M",
                "size": "M",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCBT4G00L",
                "size": "L",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCBT4G0XL",
                "size": "XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCBT4G2XL",
                "size": "2XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT4G3XL",
                "size": "3XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTH500S",
                "size": "S",
                "color": "Lagoon Blue",
                "stock": 175,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH500M",
                "size": "M",
                "color": "Lagoon Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH500L",
                "size": "L",
                "color": "Lagoon Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH50XL",
                "size": "XL",
                "color": "Lagoon Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH52XL",
                "size": "2XL",
                "color": "Lagoon Blue",
                "stock": 182,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH53XL",
                "size": "3XL",
                "color": "Lagoon Blue",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH700S",
                "size": "S",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH700M",
                "size": "M",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH700L",
                "size": "L",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH70XL",
                "size": "XL",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH72XL",
                "size": "2XL",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTH73XL",
                "size": "3XL",
                "color": "Light Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI200S",
                "size": "S",
                "color": "Melon",
                "stock": 215,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI200M",
                "size": "M",
                "color": "Melon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI200L",
                "size": "L",
                "color": "Melon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI20XL",
                "size": "XL",
                "color": "Melon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI22XL",
                "size": "2XL",
                "color": "Melon",
                "stock": 169,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI23XL",
                "size": "3XL",
                "color": "Melon",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI400S",
                "size": "S",
                "color": "Midnight",
                "stock": 177,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI400M",
                "size": "M",
                "color": "Midnight",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI400L",
                "size": "L",
                "color": "Midnight",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI40XL",
                "size": "XL",
                "color": "Midnight",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI42XL",
                "size": "2XL",
                "color": "Midnight",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI43XL",
                "size": "3XL",
                "color": "Midnight",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI500S",
                "size": "S",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI500M",
                "size": "M",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI500L",
                "size": "L",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI50XL",
                "size": "XL",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI52XL",
                "size": "2XL",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI53XL",
                "size": "3XL",
                "color": "Moss",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI700S",
                "size": "S",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI700M",
                "size": "M",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI700L",
                "size": "L",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI70XL",
                "size": "XL",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI72XL",
                "size": "2XL",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI73XL",
                "size": "3XL",
                "color": "Mustard",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI800S",
                "size": "S",
                "color": "Mystic Blue",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI800M",
                "size": "M",
                "color": "Mystic Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI800L",
                "size": "L",
                "color": "Mystic Blue",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI80XL",
                "size": "XL",
                "color": "Mystic Blue",
                "stock": 232,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI82XL",
                "size": "2XL",
                "color": "Mystic Blue",
                "stock": 130,
                "status": "In Stock"
            },
            {
                "sku": "CCBTI83XL",
                "size": "3XL",
                "color": "Mystic Blue",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 135,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTJ500S",
                "size": "S",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTJ500M",
                "size": "M",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTJ500L",
                "size": "L",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTJ50XL",
                "size": "XL",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTJ52XL",
                "size": "2XL",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTJ53XL",
                "size": "3XL",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK200S",
                "size": "S",
                "color": "Peony",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK200M",
                "size": "M",
                "color": "Peony",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK200L",
                "size": "L",
                "color": "Peony",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK20XL",
                "size": "XL",
                "color": "Peony",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK22XL",
                "size": "2XL",
                "color": "Peony",
                "stock": 8,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK23XL",
                "size": "3XL",
                "color": "Peony",
                "stock": 26,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTK300S",
                "size": "S",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK300M",
                "size": "M",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK300L",
                "size": "L",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK30XL",
                "size": "XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK32XL",
                "size": "2XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK33XL",
                "size": "3XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK500S",
                "size": "S",
                "color": "Periwinkle",
                "stock": 130,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK500M",
                "size": "M",
                "color": "Periwinkle",
                "stock": 202,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK500L",
                "size": "L",
                "color": "Periwinkle",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK50XL",
                "size": "XL",
                "color": "Periwinkle",
                "stock": 175,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK52XL",
                "size": "2XL",
                "color": "Periwinkle",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK53XL",
                "size": "3XL",
                "color": "Periwinkle",
                "stock": 55,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK900S",
                "size": "S",
                "color": "Royal Caribe",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK900M",
                "size": "M",
                "color": "Royal Caribe",
                "stock": 242,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK900L",
                "size": "L",
                "color": "Royal Caribe",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK90XL",
                "size": "XL",
                "color": "Royal Caribe",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK92XL",
                "size": "2XL",
                "color": "Royal Caribe",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "CCBTK93XL",
                "size": "3XL",
                "color": "Royal Caribe",
                "stock": 46,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT2I00S",
                "size": "S",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT2I00M",
                "size": "M",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT2I00L",
                "size": "L",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT2I0XL",
                "size": "XL",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT2I2XL",
                "size": "2XL",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT2I3XL",
                "size": "3XL",
                "color": "Sage",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M00S",
                "size": "S",
                "color": "Sandstone",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M00M",
                "size": "M",
                "color": "Sandstone",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M00L",
                "size": "L",
                "color": "Sandstone",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M0XL",
                "size": "XL",
                "color": "Sandstone",
                "stock": 97,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M2XL",
                "size": "2XL",
                "color": "Sandstone",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "CCBT8M3XL",
                "size": "3XL",
                "color": "Sandstone",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN400S",
                "size": "S",
                "color": "Terracotta",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN400M",
                "size": "M",
                "color": "Terracotta",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN400L",
                "size": "L",
                "color": "Terracotta",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN40XL",
                "size": "XL",
                "color": "Terracotta",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN42XL",
                "size": "2XL",
                "color": "Terracotta",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN43XL",
                "size": "3XL",
                "color": "Terracotta",
                "stock": 172,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN500S",
                "size": "S",
                "color": "Topaz Blue",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN500M",
                "size": "M",
                "color": "Topaz Blue",
                "stock": 172,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN500L",
                "size": "L",
                "color": "Topaz Blue",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN50XL",
                "size": "XL",
                "color": "Topaz Blue",
                "stock": 180,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN52XL",
                "size": "2XL",
                "color": "Topaz Blue",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN53XL",
                "size": "3XL",
                "color": "Topaz Blue",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN600S",
                "size": "S",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN600M",
                "size": "M",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN600L",
                "size": "L",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN60XL",
                "size": "XL",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN62XL",
                "size": "2XL",
                "color": "True Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN63XL",
                "size": "3XL",
                "color": "True Navy",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN800S",
                "size": "S",
                "color": "Vineyard",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "CCBTN800M",
                "size": "M",
                "color": "Vineyard",
                "stock": 53,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTN800L",
                "size": "L",
                "color": "Vineyard",
                "stock": 38,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTN80XL",
                "size": "XL",
                "color": "Vineyard",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTN82XL",
                "size": "2XL",
                "color": "Vineyard",
                "stock": 66,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBTN83XL",
                "size": "3XL",
                "color": "Vineyard",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO200S",
                "size": "S",
                "color": "Washed Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO200M",
                "size": "M",
                "color": "Washed Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO200L",
                "size": "L",
                "color": "Washed Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO20XL",
                "size": "XL",
                "color": "Washed Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO22XL",
                "size": "2XL",
                "color": "Washed Denim",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO23XL",
                "size": "3XL",
                "color": "Washed Denim",
                "stock": 184,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO300S",
                "size": "S",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO300M",
                "size": "M",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO300L",
                "size": "L",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO30XL",
                "size": "XL",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO32XL",
                "size": "2XL",
                "color": "Watermelon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTO33XL",
                "size": "3XL",
                "color": "Watermelon",
                "stock": 9999,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCBT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBT1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP400S",
                "size": "S",
                "color": "Yam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP400M",
                "size": "M",
                "color": "Yam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP400L",
                "size": "L",
                "color": "Yam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP40XL",
                "size": "XL",
                "color": "Yam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP42XL",
                "size": "2XL",
                "color": "Yam",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCBTP43XL",
                "size": "3XL",
                "color": "Yam",
                "stock": 177,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex T-shirt - Comfort Color | 1717",
        "sku": "Unisex-ComfortColor-UnisexT-Shirt",
        "id": "9c30dfc2-43e3-4318-a4bd-3cda7fb6c458"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNTT2H0XS",
                "size": "XS",
                "color": "Athletic Heather",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "UNTT2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "UNTT2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "UNTT2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 167,
                "status": "In Stock"
            },
            {
                "sku": "UNTT2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "UNTT2H2XL",
                "size": "2XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 142,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K0XS",
                "size": "XS",
                "color": "Dark Grey",
                "stock": 218,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K00S",
                "size": "S",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K00M",
                "size": "M",
                "color": "Dark Grey",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K00L",
                "size": "L",
                "color": "Dark Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K0XL",
                "size": "XL",
                "color": "Dark Grey",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5K2XL",
                "size": "2XL",
                "color": "Dark Grey",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7G0XS",
                "size": "XS",
                "color": "Kelly",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7G00S",
                "size": "S",
                "color": "Kelly",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7G00M",
                "size": "M",
                "color": "Kelly",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT7G00L",
                "size": "L",
                "color": "Kelly",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT7G0XL",
                "size": "XL",
                "color": "Kelly",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT7G2XL",
                "size": "2XL",
                "color": "Kelly",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT3E0XS",
                "size": "XS",
                "color": "Leaf",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "UNTT3E00S",
                "size": "S",
                "color": "Leaf",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "UNTT3E00M",
                "size": "M",
                "color": "Leaf",
                "stock": 160,
                "status": "In Stock"
            },
            {
                "sku": "UNTT3E00L",
                "size": "L",
                "color": "Leaf",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT3E0XL",
                "size": "XL",
                "color": "Leaf",
                "stock": 78,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNTT3E2XL",
                "size": "2XL",
                "color": "Leaf",
                "stock": 194,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 115,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 119,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 222,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C0XS",
                "size": "XS",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 100,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5C2XL",
                "size": "2XL",
                "color": "True Royal",
                "stock": 156,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K2XL",
                "size": "2XL",
                "color": "Dark Grey Heather",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1K0XS",
                "size": "XS",
                "color": "Dark Grey Heather",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H2XL",
                "size": "2XL",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT6H0XS",
                "size": "XS",
                "color": "Deep Heather",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI02XL",
                "size": "2XL",
                "color": "Neon Blue",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI000L",
                "size": "L",
                "color": "Neon Blue",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI000M",
                "size": "M",
                "color": "Neon Blue",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI000S",
                "size": "S",
                "color": "Neon Blue",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI00XL",
                "size": "XL",
                "color": "Neon Blue",
                "stock": 95,
                "status": "In Stock"
            },
            {
                "sku": "UNTTI00XS",
                "size": "XS",
                "color": "Neon Blue",
                "stock": 95,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E2XL",
                "size": "2XL",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E00L",
                "size": "L",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E00M",
                "size": "M",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E00S",
                "size": "S",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E0XL",
                "size": "XL",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT9E0XS",
                "size": "XS",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J2XL",
                "size": "2XL",
                "color": "Neon Pink",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J00L",
                "size": "L",
                "color": "Neon Pink",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J00M",
                "size": "M",
                "color": "Neon Pink",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J00S",
                "size": "S",
                "color": "Neon Pink",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J0XL",
                "size": "XL",
                "color": "Neon Pink",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT7J0XS",
                "size": "XS",
                "color": "Neon Pink",
                "stock": 95,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z2XL",
                "size": "2XL",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z00L",
                "size": "L",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z00M",
                "size": "M",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z00S",
                "size": "S",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z0XL",
                "size": "XL",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Z0XS",
                "size": "XS",
                "color": "Neon Yellow",
                "stock": 96,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT1O0XS",
                "size": "XS",
                "color": "Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W2XL",
                "size": "2XL",
                "color": "Silver",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W00L",
                "size": "L",
                "color": "Silver",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W00M",
                "size": "M",
                "color": "Silver",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W00S",
                "size": "S",
                "color": "Silver",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W0XL",
                "size": "XL",
                "color": "Silver",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT4W0XS",
                "size": "XS",
                "color": "Silver",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q2XL",
                "size": "2XL",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q00L",
                "size": "L",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q00M",
                "size": "M",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q00S",
                "size": "S",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q0XL",
                "size": "XL",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNTT5Q0XS",
                "size": "XS",
                "color": "Team Purple",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex Tank-Top - Bella + Canvas | 3480",
        "sku": "Unisex-BellaCanvas-TankTop",
        "id": "5aa4b136-095f-41e2-a352-e657295e4801"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNGT1E00S",
                "size": "S",
                "color": "Antique Irish Green",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1E00M",
                "size": "M",
                "color": "Antique Irish Green",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1E00L",
                "size": "L",
                "color": "Antique Irish Green",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1E0XL",
                "size": "XL",
                "color": "Antique Irish Green",
                "stock": 194,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1E2XL",
                "size": "2XL",
                "color": "Antique Irish Green",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1E3XL",
                "size": "3XL",
                "color": "Antique Irish Green",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O00S",
                "size": "S",
                "color": "Antique Orange",
                "stock": 229,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O00M",
                "size": "M",
                "color": "Antique Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O00L",
                "size": "L",
                "color": "Antique Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O0XL",
                "size": "XL",
                "color": "Antique Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O2XL",
                "size": "2XL",
                "color": "Antique Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3O3XL",
                "size": "3XL",
                "color": "Antique Orange",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C00S",
                "size": "S",
                "color": "Antique Sapphire",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C00M",
                "size": "M",
                "color": "Antique Sapphire",
                "stock": 157,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C00L",
                "size": "L",
                "color": "Antique Sapphire",
                "stock": 212,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C0XL",
                "size": "XL",
                "color": "Antique Sapphire",
                "stock": 198,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C2XL",
                "size": "2XL",
                "color": "Antique Sapphire",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8C3XL",
                "size": "3XL",
                "color": "Antique Sapphire",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W2XL",
                "size": "2XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W3XL",
                "size": "3XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W4XL",
                "size": "4XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3W5XL",
                "size": "5XL",
                "color": "Ash",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P00S",
                "size": "S",
                "color": "Azalea",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P00M",
                "size": "M",
                "color": "Azalea",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P00L",
                "size": "L",
                "color": "Azalea",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P0XL",
                "size": "XL",
                "color": "Azalea",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P2XL",
                "size": "2XL",
                "color": "Azalea",
                "stock": 189,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P3XL",
                "size": "3XL",
                "color": "Azalea",
                "stock": 234,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P4XL",
                "size": "4XL",
                "color": "Azalea",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6P5XL",
                "size": "5XL",
                "color": "Azalea",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B4XL",
                "size": "4XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1B5XL",
                "size": "5XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S00S",
                "size": "S",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S00M",
                "size": "M",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S00L",
                "size": "L",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S0XL",
                "size": "XL",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S2XL",
                "size": "2XL",
                "color": "Cardinal Red",
                "stock": 247,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S3XL",
                "size": "3XL",
                "color": "Cardinal Red",
                "stock": 164,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S4XL",
                "size": "4XL",
                "color": "Cardinal Red",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2S5XL",
                "size": "5XL",
                "color": "Cardinal Red",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N2XL",
                "size": "2XL",
                "color": "Carolina Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N3XL",
                "size": "3XL",
                "color": "Carolina Blue",
                "stock": 237,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N4XL",
                "size": "4XL",
                "color": "Carolina Blue",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5N5XL",
                "size": "5XL",
                "color": "Carolina Blue",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D00S",
                "size": "S",
                "color": "Cobalt",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D00M",
                "size": "M",
                "color": "Cobalt",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D00L",
                "size": "L",
                "color": "Cobalt",
                "stock": 218,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D0XL",
                "size": "XL",
                "color": "Cobalt",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D2XL",
                "size": "2XL",
                "color": "Cobalt",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1D3XL",
                "size": "3XL",
                "color": "Cobalt",
                "stock": 53,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S00S",
                "size": "S",
                "color": "Coral Silk",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S00M",
                "size": "M",
                "color": "Coral Silk",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S00L",
                "size": "L",
                "color": "Coral Silk",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S0XL",
                "size": "XL",
                "color": "Coral Silk",
                "stock": 165,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S2XL",
                "size": "2XL",
                "color": "Coral Silk",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S3XL",
                "size": "3XL",
                "color": "Coral Silk",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S4XL",
                "size": "4XL",
                "color": "Coral Silk",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3S5XL",
                "size": "5XL",
                "color": "Coral Silk",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y00S",
                "size": "S",
                "color": "Cornsilk",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y00M",
                "size": "M",
                "color": "Cornsilk",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y00L",
                "size": "L",
                "color": "Cornsilk",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y0XL",
                "size": "XL",
                "color": "Cornsilk",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y2XL",
                "size": "2XL",
                "color": "Cornsilk",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7Y3XL",
                "size": "3XL",
                "color": "Cornsilk",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y00S",
                "size": "S",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y00M",
                "size": "M",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y00L",
                "size": "L",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y0XL",
                "size": "XL",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y2XL",
                "size": "2XL",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y3XL",
                "size": "3XL",
                "color": "Daisy",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y4XL",
                "size": "4XL",
                "color": "Daisy",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2Y5XL",
                "size": "5XL",
                "color": "Daisy",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M00S",
                "size": "S",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M00M",
                "size": "M",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M00L",
                "size": "L",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M0XL",
                "size": "XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M2XL",
                "size": "2XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M3XL",
                "size": "3XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M4XL",
                "size": "4XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1M5XL",
                "size": "5XL",
                "color": "Dark Chocolate",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K4XL",
                "size": "4XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2K5XL",
                "size": "5XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F2XL",
                "size": "2XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F3XL",
                "size": "3XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F4XL",
                "size": "4XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2F5XL",
                "size": "5XL",
                "color": "Forest Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y3XL",
                "size": "3XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y4XL",
                "size": "4XL",
                "color": "Gold",
                "stock": 202,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3Y5XL",
                "size": "5XL",
                "color": "Gold",
                "stock": 112,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K00S",
                "size": "S",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K00M",
                "size": "M",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K00L",
                "size": "L",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K0XL",
                "size": "XL",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K2XL",
                "size": "2XL",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K3XL",
                "size": "3XL",
                "color": "Graphite Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K4XL",
                "size": "4XL",
                "color": "Graphite Heather",
                "stock": 157,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8K5XL",
                "size": "5XL",
                "color": "Graphite Heather",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P2XL",
                "size": "2XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P3XL",
                "size": "3XL",
                "color": "Heliconia",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P4XL",
                "size": "4XL",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT8P5XL",
                "size": "5XL",
                "color": "Heliconia",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H00S",
                "size": "S",
                "color": "Ice Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H00M",
                "size": "M",
                "color": "Ice Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H00L",
                "size": "L",
                "color": "Ice Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H0XL",
                "size": "XL",
                "color": "Ice Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H2XL",
                "size": "2XL",
                "color": "Ice Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H3XL",
                "size": "3XL",
                "color": "Ice Grey",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H4XL",
                "size": "4XL",
                "color": "Ice Grey",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7H5XL",
                "size": "5XL",
                "color": "Ice Grey",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGT3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G2XL",
                "size": "2XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G3XL",
                "size": "3XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G4XL",
                "size": "4XL",
                "color": "Irish Green",
                "stock": 161,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G00S",
                "size": "S",
                "color": "Kiwi",
                "stock": 181,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G00M",
                "size": "M",
                "color": "Kiwi",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G00L",
                "size": "L",
                "color": "Kiwi",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G0XL",
                "size": "XL",
                "color": "Kiwi",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G2XL",
                "size": "2XL",
                "color": "Kiwi",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G3XL",
                "size": "3XL",
                "color": "Kiwi",
                "stock": 95,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGT4G4XL",
                "size": "4XL",
                "color": "Kiwi",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4G5XL",
                "size": "5XL",
                "color": "Kiwi",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGT4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N4XL",
                "size": "4XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4N5XL",
                "size": "5XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P2XL",
                "size": "2XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P3XL",
                "size": "3XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P4XL",
                "size": "4XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5P5XL",
                "size": "5XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G00S",
                "size": "S",
                "color": "Lime",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G00M",
                "size": "M",
                "color": "Lime",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G00L",
                "size": "L",
                "color": "Lime",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G0XL",
                "size": "XL",
                "color": "Lime",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G2XL",
                "size": "2XL",
                "color": "Lime",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G3XL",
                "size": "3XL",
                "color": "Lime",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6G4XL",
                "size": "4XL",
                "color": "Lime",
                "stock": 36,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGT6G5XL",
                "size": "5XL",
                "color": "Lime",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R3XL",
                "size": "3XL",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R4XL",
                "size": "4XL",
                "color": "Maroon",
                "stock": 193,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5R5XL",
                "size": "5XL",
                "color": "Maroon",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F2XL",
                "size": "2XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F3XL",
                "size": "3XL",
                "color": "Military Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F4XL",
                "size": "4XL",
                "color": "Military Green",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3F5XL",
                "size": "5XL",
                "color": "Military Green",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W00S",
                "size": "S",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W00M",
                "size": "M",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W00L",
                "size": "L",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W0XL",
                "size": "XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W2XL",
                "size": "2XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W3XL",
                "size": "3XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W4XL",
                "size": "4XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5W5XL",
                "size": "5XL",
                "color": "Natural",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N4XL",
                "size": "4XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1N5XL",
                "size": "5XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O4XL",
                "size": "4XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1O5XL",
                "size": "5XL",
                "color": "Orange",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q4XL",
                "size": "4XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1Q5XL",
                "size": "5XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R4XL",
                "size": "4XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1R5XL",
                "size": "5XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C4XL",
                "size": "4XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT7C5XL",
                "size": "5XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y00S",
                "size": "S",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y00M",
                "size": "M",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y00L",
                "size": "L",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y0XL",
                "size": "XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y2XL",
                "size": "2XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y3XL",
                "size": "3XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y4XL",
                "size": "4XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4Y5XL",
                "size": "5XL",
                "color": "Sand",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D00S",
                "size": "S",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D00M",
                "size": "M",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D00L",
                "size": "L",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D0XL",
                "size": "XL",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D2XL",
                "size": "2XL",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D3XL",
                "size": "3XL",
                "color": "Sapphire",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D4XL",
                "size": "4XL",
                "color": "Sapphire",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3D5XL",
                "size": "5XL",
                "color": "Sapphire",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H4XL",
                "size": "4XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT4H5XL",
                "size": "5XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E00S",
                "size": "S",
                "color": "Turf Green",
                "stock": 153,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E00M",
                "size": "M",
                "color": "Turf Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E00L",
                "size": "L",
                "color": "Turf Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E0XL",
                "size": "XL",
                "color": "Turf Green",
                "stock": 242,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E2XL",
                "size": "2XL",
                "color": "Turf Green",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5E3XL",
                "size": "3XL",
                "color": "Turf Green",
                "stock": 157,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K00S",
                "size": "S",
                "color": "Tweed",
                "stock": 196,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K00M",
                "size": "M",
                "color": "Tweed",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K00L",
                "size": "L",
                "color": "Tweed",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K0XL",
                "size": "XL",
                "color": "Tweed",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K2XL",
                "size": "2XL",
                "color": "Tweed",
                "stock": 184,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K3XL",
                "size": "3XL",
                "color": "Tweed",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K4XL",
                "size": "4XL",
                "color": "Tweed",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "UNGT6K5XL",
                "size": "5XL",
                "color": "Tweed",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "UNGT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W4XL",
                "size": "4XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT1W5XL",
                "size": "5XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT3G5XL",
                "size": "5XL",
                "color": "Irish Green",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N5XL",
                "size": "5XL",
                "color": "Heather Navy",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B4XL",
                "size": "4XL",
                "color": "Charcoal",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B5XL",
                "size": "5XL",
                "color": "Charcoal",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E2XL",
                "size": "2XL",
                "color": "Electric Green",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E3XL",
                "size": "3XL",
                "color": "Electric Green",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E00L",
                "size": "L",
                "color": "Electric Green",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E00M",
                "size": "M",
                "color": "Electric Green",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E00S",
                "size": "S",
                "color": "Electric Green",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "UNGT0E0XL",
                "size": "XL",
                "color": "Electric Green",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N2XL",
                "size": "2XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N3XL",
                "size": "3XL",
                "color": "Heather Navy",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N4XL",
                "size": "4XL",
                "color": "Heather Navy",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R2XL",
                "size": "2XL",
                "color": "Heather Red",
                "stock": 190,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R3XL",
                "size": "3XL",
                "color": "Heather Red",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R4XL",
                "size": "4XL",
                "color": "Heather Red",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R5XL",
                "size": "5XL",
                "color": "Heather Red",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R00L",
                "size": "L",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R00M",
                "size": "M",
                "color": "Heather Red",
                "stock": 187,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R00S",
                "size": "S",
                "color": "Heather Red",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "UNGT2R0XL",
                "size": "XL",
                "color": "Heather Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT200S",
                "size": "S",
                "color": "Tropical Blue",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT200M",
                "size": "M",
                "color": "Tropical Blue",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT200L",
                "size": "L",
                "color": "Tropical Blue",
                "stock": 121,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT20XL",
                "size": "XL",
                "color": "Tropical Blue",
                "stock": 233,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT22XL",
                "size": "2XL",
                "color": "Tropical Blue",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT23XL",
                "size": "3XL",
                "color": "Tropical Blue",
                "stock": 27,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT24XL",
                "size": "4XL",
                "color": "Tropical Blue",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "UNGTT25XL",
                "size": "5XL",
                "color": "Tropical Blue",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG00S",
                "size": "S",
                "color": "Mint Green",
                "stock": 6,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG00M",
                "size": "M",
                "color": "Mint Green",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG00L",
                "size": "L",
                "color": "Mint Green",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG0XL",
                "size": "XL",
                "color": "Mint Green",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG2XL",
                "size": "2XL",
                "color": "Mint Green",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG3XL",
                "size": "3XL",
                "color": "Mint Green",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG4XL",
                "size": "4XL",
                "color": "Mint Green",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "UNGTMG5XL",
                "size": "5XL",
                "color": "Mint Green",
                "stock": 21,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex T-shirt | G5000",
        "sku": "Unisex-Gildan-UnisexT-Shirt",
        "id": "ec777ddb-05e6-4376-bed3-eac908c85706"
    },
    {
        "category": "Women",
        "variations": [
            {
                "sku": "WOBF3J2XL",
                "size": "2XL",
                "color": "Coral",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3J00L",
                "size": "L",
                "color": "Coral",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3J00M",
                "size": "M",
                "color": "Coral",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3J00S",
                "size": "S",
                "color": "Coral",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3J0XL",
                "size": "XL",
                "color": "Coral",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5K2XL",
                "size": "2XL",
                "color": "Dark Grey",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5K00L",
                "size": "L",
                "color": "Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5K00M",
                "size": "M",
                "color": "Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5K00S",
                "size": "S",
                "color": "Dark Grey",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5K0XL",
                "size": "XL",
                "color": "Dark Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6H2XL",
                "size": "2XL",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6H00L",
                "size": "L",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6H00M",
                "size": "M",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6H00S",
                "size": "S",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6H0XL",
                "size": "XL",
                "color": "Deep Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFP52XL",
                "size": "2XL",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFP500L",
                "size": "L",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFP500M",
                "size": "M",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFP500S",
                "size": "S",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFP50XL",
                "size": "XL",
                "color": "Heather Deep Teal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8V2XL",
                "size": "2XL",
                "color": "Heather Grass Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8V00L",
                "size": "L",
                "color": "Heather Grass Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8V00M",
                "size": "M",
                "color": "Heather Grass Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8V00S",
                "size": "S",
                "color": "Heather Grass Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8V0XL",
                "size": "XL",
                "color": "Heather Grass Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2R2XL",
                "size": "2XL",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2R00L",
                "size": "L",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2R00M",
                "size": "M",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2R00S",
                "size": "S",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2R0XL",
                "size": "XL",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3E2XL",
                "size": "2XL",
                "color": "Leaf",
                "stock": 89,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3E00L",
                "size": "L",
                "color": "Leaf",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3E00M",
                "size": "M",
                "color": "Leaf",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3E00S",
                "size": "S",
                "color": "Leaf",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3E0XL",
                "size": "XL",
                "color": "Leaf",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFH02XL",
                "size": "2XL",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFH000L",
                "size": "L",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFH000M",
                "size": "M",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFH000S",
                "size": "S",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFH00XL",
                "size": "XL",
                "color": "Mauve",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9B2XL",
                "size": "2XL",
                "color": "Solid Black Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9B00L",
                "size": "L",
                "color": "Solid Black Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9B00M",
                "size": "M",
                "color": "Solid Black Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9B00S",
                "size": "S",
                "color": "Solid Black Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9B0XL",
                "size": "XL",
                "color": "Solid Black Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFM72XL",
                "size": "2XL",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFM700L",
                "size": "L",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFM700M",
                "size": "M",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFM700S",
                "size": "S",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFM70XL",
                "size": "XL",
                "color": "Storm",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3K2XL",
                "size": "2XL",
                "color": "Black Heather",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3K00L",
                "size": "L",
                "color": "Black Heather",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3K00M",
                "size": "M",
                "color": "Black Heather",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3K00S",
                "size": "S",
                "color": "Black Heather",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3K0XL",
                "size": "XL",
                "color": "Black Heather",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7F2XL",
                "size": "2XL",
                "color": "Army",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBF7F00L",
                "size": "L",
                "color": "Army",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7F00M",
                "size": "M",
                "color": "Army",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBF7F00S",
                "size": "S",
                "color": "Army",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBF7F0XL",
                "size": "XL",
                "color": "Army",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4B2XL",
                "size": "2XL",
                "color": "Asphalt",
                "stock": 167,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4B00L",
                "size": "L",
                "color": "Asphalt",
                "stock": 159,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4B00M",
                "size": "M",
                "color": "Asphalt",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4B00S",
                "size": "S",
                "color": "Asphalt",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4B0XL",
                "size": "XL",
                "color": "Asphalt",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2H2XL",
                "size": "2XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2H00L",
                "size": "L",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2H00M",
                "size": "M",
                "color": "Athletic Heather",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2H00S",
                "size": "S",
                "color": "Athletic Heather",
                "stock": 119,
                "status": "In Stock"
            },
            {
                "sku": "WOBF2H0XL",
                "size": "XL",
                "color": "Athletic Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4C2XL",
                "size": "2XL",
                "color": "Baby Blue",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4C00L",
                "size": "L",
                "color": "Baby Blue",
                "stock": 210,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4C00M",
                "size": "M",
                "color": "Baby Blue",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4C00S",
                "size": "S",
                "color": "Baby Blue",
                "stock": 128,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4C0XL",
                "size": "XL",
                "color": "Baby Blue",
                "stock": 169,
                "status": "In Stock"
            },
            {
                "sku": "WOBF0R2XL",
                "size": "2XL",
                "color": "Berry",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "WOBF0R00L",
                "size": "L",
                "color": "Berry",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "WOBF0R00M",
                "size": "M",
                "color": "Berry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF0R00S",
                "size": "S",
                "color": "Berry",
                "stock": 110,
                "status": "In Stock"
            },
            {
                "sku": "WOBF0R0XL",
                "size": "XL",
                "color": "Berry",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 209,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1B00S",
                "size": "S",
                "color": "Black",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6R2XL",
                "size": "2XL",
                "color": "Cardinal",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6R00L",
                "size": "L",
                "color": "Cardinal",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6R00M",
                "size": "M",
                "color": "Cardinal",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6R00S",
                "size": "S",
                "color": "Cardinal",
                "stock": 187,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6R0XL",
                "size": "XL",
                "color": "Cardinal",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1K2XL",
                "size": "2XL",
                "color": "Dark Grey Heather",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1K00L",
                "size": "L",
                "color": "Dark Grey Heather",
                "stock": 145,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1K00M",
                "size": "M",
                "color": "Dark Grey Heather",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1K00S",
                "size": "S",
                "color": "Dark Grey Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1K0XL",
                "size": "XL",
                "color": "Dark Grey Heather",
                "stock": 126,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3Y2XL",
                "size": "2XL",
                "color": "Gold",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 153,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5I2XL",
                "size": "2XL",
                "color": "Heather Aqua",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5I00L",
                "size": "L",
                "color": "Heather Aqua",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBF5I00M",
                "size": "M",
                "color": "Heather Aqua",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBF5I00S",
                "size": "S",
                "color": "Heather Aqua",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5I0XL",
                "size": "XL",
                "color": "Heather Aqua",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9N2XL",
                "size": "2XL",
                "color": "Heather Navy",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "WOBF9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "WOBFG22XL",
                "size": "2XL",
                "color": "Heather Purple",
                "stock": 2,
                "status": "In Stock"
            },
            {
                "sku": "WOBFG200L",
                "size": "L",
                "color": "Heather Purple",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOBFG200M",
                "size": "M",
                "color": "Heather Purple",
                "stock": 28,
                "status": "In Stock"
            },
            {
                "sku": "WOBFG200S",
                "size": "S",
                "color": "Heather Purple",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "WOBFG20XL",
                "size": "XL",
                "color": "Heather Purple",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6W2XL",
                "size": "2XL",
                "color": "Heather Slate",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6W00L",
                "size": "L",
                "color": "Heather Slate",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6W00M",
                "size": "M",
                "color": "Heather Slate",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6W00S",
                "size": "S",
                "color": "Heather Slate",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "WOBF6W0XL",
                "size": "XL",
                "color": "Heather Slate",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3C2XL",
                "size": "2XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3C00L",
                "size": "L",
                "color": "Heather True Royal",
                "stock": 227,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3C00M",
                "size": "M",
                "color": "Heather True Royal",
                "stock": 182,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3C00S",
                "size": "S",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF3C0XL",
                "size": "XL",
                "color": "Heather True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7G2XL",
                "size": "2XL",
                "color": "Kelly",
                "stock": 118,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7G00L",
                "size": "L",
                "color": "Kelly",
                "stock": 175,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7G00M",
                "size": "M",
                "color": "Kelly",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7G00S",
                "size": "S",
                "color": "Kelly",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7G0XL",
                "size": "XL",
                "color": "Kelly",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5R2XL",
                "size": "2XL",
                "color": "Maroon",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 16,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 111,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 195,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 53,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 208,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 222,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1P2XL",
                "size": "2XL",
                "color": "Pink",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1P00L",
                "size": "L",
                "color": "Pink",
                "stock": 234,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1P00M",
                "size": "M",
                "color": "Pink",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1P00S",
                "size": "S",
                "color": "Pink",
                "stock": 131,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1P0XL",
                "size": "XL",
                "color": "Pink",
                "stock": 149,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1R00M",
                "size": "M",
                "color": "Red",
                "stock": 226,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1R00S",
                "size": "S",
                "color": "Red",
                "stock": 206,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 232,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4W2XL",
                "size": "2XL",
                "color": "Silver",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4W00L",
                "size": "L",
                "color": "Silver",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4W00M",
                "size": "M",
                "color": "Silver",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4W00S",
                "size": "S",
                "color": "Silver",
                "stock": 118,
                "status": "In Stock"
            },
            {
                "sku": "WOBF4W0XL",
                "size": "XL",
                "color": "Silver",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7W2XL",
                "size": "2XL",
                "color": "Soft Cream",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7W00L",
                "size": "L",
                "color": "Soft Cream",
                "stock": 195,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7W00M",
                "size": "M",
                "color": "Soft Cream",
                "stock": 143,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7W00S",
                "size": "S",
                "color": "Soft Cream",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "WOBF7W0XL",
                "size": "XL",
                "color": "Soft Cream",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8D2XL",
                "size": "2XL",
                "color": "Teal",
                "stock": 156,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8D00L",
                "size": "L",
                "color": "Teal",
                "stock": 198,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8D00M",
                "size": "M",
                "color": "Teal",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8D00S",
                "size": "S",
                "color": "Teal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF8D0XL",
                "size": "XL",
                "color": "Teal",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5Q2XL",
                "size": "2XL",
                "color": "Team Purple",
                "stock": 165,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5Q00L",
                "size": "L",
                "color": "Team Purple",
                "stock": 212,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5Q00M",
                "size": "M",
                "color": "Team Purple",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5Q00S",
                "size": "S",
                "color": "Team Purple",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5Q0XL",
                "size": "XL",
                "color": "Team Purple",
                "stock": 169,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5C2XL",
                "size": "2XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5C00L",
                "size": "L",
                "color": "True Royal",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5C00M",
                "size": "M",
                "color": "True Royal",
                "stock": 211,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5C00S",
                "size": "S",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF5C0XL",
                "size": "XL",
                "color": "True Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1W00S",
                "size": "S",
                "color": "White",
                "stock": 214,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1Y2XL",
                "size": "2XL",
                "color": "Yellow",
                "stock": 118,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1Y00L",
                "size": "L",
                "color": "Yellow",
                "stock": 201,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1Y00M",
                "size": "M",
                "color": "Yellow",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1Y00S",
                "size": "S",
                "color": "Yellow",
                "stock": 210,
                "status": "In Stock"
            },
            {
                "sku": "WOBF1Y0XL",
                "size": "XL",
                "color": "Yellow",
                "stock": 180,
                "status": "In Stock"
            },
            {
                "sku": "WOBFB900L",
                "size": "L",
                "color": "Burnt Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFB900S",
                "size": "S",
                "color": "Burnt Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFB900M",
                "size": "M",
                "color": "Burnt Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFB90XL",
                "size": "XL",
                "color": "Burnt Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFV300S",
                "size": "S",
                "color": "Solid White Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFV300M",
                "size": "M",
                "color": "Solid White Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFV300L",
                "size": "L",
                "color": "Solid White Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFV30XL",
                "size": "XL",
                "color": "Solid White Blend",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "WOBFV32XL",
                "size": "2XL",
                "color": "Solid White Blend",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Women's Slim Fit T-Shirt - Bella + Canvas | 6004",
        "sku": "Women-BellaCanvas-WomenT-Shirt",
        "id": "d5cd893a-eb22-4d9c-a7bb-7786c7c208c6"
    },
    {
        "category": "Kids",
        "variations": [
            {
                "sku": "CCYT1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 205,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 102,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCYTA90XS",
                "size": "XS",
                "color": "Blossom",
                "stock": 130,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA900S",
                "size": "S",
                "color": "Blossom",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA900M",
                "size": "M",
                "color": "Blossom",
                "stock": 221,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA900L",
                "size": "L",
                "color": "Blossom",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA90XL",
                "size": "XL",
                "color": "Blossom",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA00XS",
                "size": "XS",
                "color": "Blue Jean",
                "stock": 195,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA000S",
                "size": "S",
                "color": "Blue Jean",
                "stock": 97,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA000M",
                "size": "M",
                "color": "Blue Jean",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA000L",
                "size": "L",
                "color": "Blue Jean",
                "stock": 98,
                "status": "In Stock"
            },
            {
                "sku": "CCYTA00XL",
                "size": "XL",
                "color": "Blue Jean",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "CCYTB70XS",
                "size": "XS",
                "color": "Bright Salmon",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCYTB700S",
                "size": "S",
                "color": "Bright Salmon",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "CCYTB700M",
                "size": "M",
                "color": "Bright Salmon",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCYTB700L",
                "size": "L",
                "color": "Bright Salmon",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCYTB70XL",
                "size": "XL",
                "color": "Bright Salmon",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6Y0XS",
                "size": "XS",
                "color": "Butter",
                "stock": 102,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6Y00S",
                "size": "S",
                "color": "Butter",
                "stock": 147,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6Y00M",
                "size": "M",
                "color": "Butter",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6Y00L",
                "size": "L",
                "color": "Butter",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6Y0XL",
                "size": "XL",
                "color": "Butter",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC30XS",
                "size": "XS",
                "color": "Chalky Mint",
                "stock": 134,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC300S",
                "size": "S",
                "color": "Chalky Mint",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC300M",
                "size": "M",
                "color": "Chalky Mint",
                "stock": 165,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCYTC300L",
                "size": "L",
                "color": "Chalky Mint",
                "stock": 113,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC30XL",
                "size": "XL",
                "color": "Chalky Mint",
                "stock": 137,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC40XS",
                "size": "XS",
                "color": "Chambray",
                "stock": 53,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC400S",
                "size": "S",
                "color": "Chambray",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC400M",
                "size": "M",
                "color": "Chambray",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC400L",
                "size": "L",
                "color": "Chambray",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "CCYTC40XL",
                "size": "XL",
                "color": "Chambray",
                "stock": 53,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8S0XS",
                "size": "XS",
                "color": "Crimson",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8S00S",
                "size": "S",
                "color": "Crimson",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8S00M",
                "size": "M",
                "color": "Crimson",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8S00L",
                "size": "L",
                "color": "Crimson",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8S0XL",
                "size": "XL",
                "color": "Crimson",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCYTD50XS",
                "size": "XS",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTD500S",
                "size": "S",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTD500M",
                "size": "M",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTD500L",
                "size": "L",
                "color": "Crunchberry",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTD50XL",
                "size": "XL",
                "color": "Crunchberry",
                "stock": 161,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6L0XS",
                "size": "XS",
                "color": "Denim",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6L00S",
                "size": "S",
                "color": "Denim",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6L00M",
                "size": "M",
                "color": "Denim",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6L00L",
                "size": "L",
                "color": "Denim",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "CCYT6L0XL",
                "size": "XL",
                "color": "Denim",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF50XS",
                "size": "XS",
                "color": "Flo Blue",
                "stock": 132,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF500S",
                "size": "S",
                "color": "Flo Blue",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF500M",
                "size": "M",
                "color": "Flo Blue",
                "stock": 239,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF500L",
                "size": "L",
                "color": "Flo Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF50XL",
                "size": "XL",
                "color": "Flo Blue",
                "stock": 247,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF60XS",
                "size": "XS",
                "color": "Granite",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF600S",
                "size": "S",
                "color": "Granite",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF600M",
                "size": "M",
                "color": "Granite",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF600L",
                "size": "L",
                "color": "Granite",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCYTF60XL",
                "size": "XL",
                "color": "Granite",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCYT9H0XS",
                "size": "XS",
                "color": "Grey",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "CCYT9H00S",
                "size": "S",
                "color": "Grey",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "CCYT9H00M",
                "size": "M",
                "color": "Grey",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "CCYT9H00L",
                "size": "L",
                "color": "Grey",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "CCYT9H0XL",
                "size": "XL",
                "color": "Grey",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8P0XS",
                "size": "XS",
                "color": "Heliconia",
                "stock": 0,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCYT8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCYT8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "CCYTG00XS",
                "size": "XS",
                "color": "Island Reef",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCYTG000S",
                "size": "S",
                "color": "Island Reef",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "CCYTG000M",
                "size": "M",
                "color": "Island Reef",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "CCYTG000L",
                "size": "L",
                "color": "Island Reef",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCYTG00XL",
                "size": "XL",
                "color": "Island Reef",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "CCYTH50XS",
                "size": "XS",
                "color": "Lagoon Blue",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "CCYTH500S",
                "size": "S",
                "color": "Lagoon Blue",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "CCYTH500M",
                "size": "M",
                "color": "Lagoon Blue",
                "stock": 127,
                "status": "In Stock"
            },
            {
                "sku": "CCYTH500L",
                "size": "L",
                "color": "Lagoon Blue",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCYTH50XL",
                "size": "XL",
                "color": "Lagoon Blue",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "CCYTI20XS",
                "size": "XS",
                "color": "Melon",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "CCYTI200S",
                "size": "S",
                "color": "Melon",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "CCYTI200M",
                "size": "M",
                "color": "Melon",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "CCYTI200L",
                "size": "L",
                "color": "Melon",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "CCYTI20XL",
                "size": "XL",
                "color": "Melon",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "CCYTJ50XS",
                "size": "XS",
                "color": "Orchid",
                "stock": 249,
                "status": "In Stock"
            },
            {
                "sku": "CCYTJ500S",
                "size": "S",
                "color": "Orchid",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "CCYTJ500M",
                "size": "M",
                "color": "Orchid",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTJ500L",
                "size": "L",
                "color": "Orchid",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "CCYTJ50XL",
                "size": "XL",
                "color": "Orchid",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK30XS",
                "size": "XS",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK300S",
                "size": "S",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK300M",
                "size": "M",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK300L",
                "size": "L",
                "color": "Pepper",
                "stock": 197,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK30XL",
                "size": "XL",
                "color": "Pepper",
                "stock": 156,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 53,
                "status": "Out Of Stock"
            },
            {
                "sku": "CCYT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK90XS",
                "size": "XS",
                "color": "Royal Caribe",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK900S",
                "size": "S",
                "color": "Royal Caribe",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK900M",
                "size": "M",
                "color": "Royal Caribe",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK900L",
                "size": "L",
                "color": "Royal Caribe",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "CCYTK90XL",
                "size": "XL",
                "color": "Royal Caribe",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN40XS",
                "size": "XS",
                "color": "Terracotta",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN400S",
                "size": "S",
                "color": "Terracotta",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN400M",
                "size": "M",
                "color": "Terracotta",
                "stock": 178,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN400L",
                "size": "L",
                "color": "Terracotta",
                "stock": 129,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN40XL",
                "size": "XL",
                "color": "Terracotta",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN50XS",
                "size": "XS",
                "color": "Topaz Blue",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN500S",
                "size": "S",
                "color": "Topaz Blue",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN500M",
                "size": "M",
                "color": "Topaz Blue",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN500L",
                "size": "L",
                "color": "Topaz Blue",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN50XL",
                "size": "XL",
                "color": "Topaz Blue",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN60XS",
                "size": "XS",
                "color": "True Navy",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN600S",
                "size": "S",
                "color": "True Navy",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN600M",
                "size": "M",
                "color": "True Navy",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN600L",
                "size": "L",
                "color": "True Navy",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN60XL",
                "size": "XL",
                "color": "True Navy",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN00XS",
                "size": "XS",
                "color": "Violet",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN000S",
                "size": "S",
                "color": "Violet",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN000M",
                "size": "M",
                "color": "Violet",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN000L",
                "size": "L",
                "color": "Violet",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "CCYTN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO20XS",
                "size": "XS",
                "color": "Washed Denim",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO200S",
                "size": "S",
                "color": "Washed Denim",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO200M",
                "size": "M",
                "color": "Washed Denim",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO200L",
                "size": "L",
                "color": "Washed Denim",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO20XL",
                "size": "XL",
                "color": "Washed Denim",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO30XS",
                "size": "XS",
                "color": "Watermelon",
                "stock": 104,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO300S",
                "size": "S",
                "color": "Watermelon",
                "stock": 168,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO300M",
                "size": "M",
                "color": "Watermelon",
                "stock": 174,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO300L",
                "size": "L",
                "color": "Watermelon",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "CCYTO30XL",
                "size": "XL",
                "color": "Watermelon",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCYT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth T-shirt - Comfort Colors | 9018",
        "sku": "Youth-ComfortColor-YouthT-Shirt",
        "id": "65dc9c11-cf0c-42f1-a323-8caacdeec2b4"
    },
    {
        "category": "Kids",
        "variations": [
            {
                "sku": "YOGT3W0XS",
                "size": "XS",
                "color": "Ash",
                "stock": 133,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3W00S",
                "size": "S",
                "color": "Ash",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3W00M",
                "size": "M",
                "color": "Ash",
                "stock": 224,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3W00L",
                "size": "L",
                "color": "Ash",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3W0XL",
                "size": "XL",
                "color": "Ash",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2S0XS",
                "size": "XS",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2S00S",
                "size": "S",
                "color": "Cardinal Red",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2S00M",
                "size": "M",
                "color": "Cardinal Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2S00L",
                "size": "L",
                "color": "Cardinal Red",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2S0XL",
                "size": "XL",
                "color": "Cardinal Red",
                "stock": 125,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2Y0XS",
                "size": "XS",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2Y00S",
                "size": "S",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2Y00M",
                "size": "M",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2Y00L",
                "size": "L",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2Y0XL",
                "size": "XL",
                "color": "Daisy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2K0XS",
                "size": "XS",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3Y0XS",
                "size": "XS",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3Y00S",
                "size": "S",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3Y00M",
                "size": "M",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3Y00L",
                "size": "L",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3Y0XL",
                "size": "XL",
                "color": "Gold",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8P0XS",
                "size": "XS",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 248,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3G0XS",
                "size": "XS",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4N0XS",
                "size": "XS",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5P0XS",
                "size": "XS",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6G0XS",
                "size": "XS",
                "color": "Lime",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6G00S",
                "size": "S",
                "color": "Lime",
                "stock": 213,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6G00M",
                "size": "M",
                "color": "Lime",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6G00L",
                "size": "L",
                "color": "Lime",
                "stock": 177,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6G0XL",
                "size": "XL",
                "color": "Lime",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5R0XS",
                "size": "XS",
                "color": "Maroon",
                "stock": 92,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5R00S",
                "size": "S",
                "color": "Maroon",
                "stock": 219,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5R00M",
                "size": "M",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5R00L",
                "size": "L",
                "color": "Maroon",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5R0XL",
                "size": "XL",
                "color": "Maroon",
                "stock": 138,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1O0XS",
                "size": "XS",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT7C0XS",
                "size": "XS",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3D0XS",
                "size": "XS",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3D00S",
                "size": "S",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3D00M",
                "size": "M",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3D00L",
                "size": "L",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3D0XL",
                "size": "XL",
                "color": "Sapphire",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4H0XS",
                "size": "XS",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6P00L",
                "size": "L",
                "color": "Azalea",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6P00M",
                "size": "M",
                "color": "Azalea",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6P00S",
                "size": "S",
                "color": "Azalea",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6P0XL",
                "size": "XL",
                "color": "Azalea",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "YOGT6P0XS",
                "size": "XS",
                "color": "Azalea",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5N00L",
                "size": "L",
                "color": "Carolina Blue",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5N00M",
                "size": "M",
                "color": "Carolina Blue",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5N00S",
                "size": "S",
                "color": "Carolina Blue",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5N0XL",
                "size": "XL",
                "color": "Carolina Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5N0XS",
                "size": "XS",
                "color": "Carolina Blue",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1D00L",
                "size": "L",
                "color": "Cobalt",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1D00M",
                "size": "M",
                "color": "Cobalt",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1D00S",
                "size": "S",
                "color": "Cobalt",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1D0XL",
                "size": "XL",
                "color": "Cobalt",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1D0XS",
                "size": "XS",
                "color": "Cobalt",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3S00L",
                "size": "L",
                "color": "Coral Silk",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3S00M",
                "size": "M",
                "color": "Coral Silk",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3S00S",
                "size": "S",
                "color": "Coral Silk",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3S0XL",
                "size": "XL",
                "color": "Coral Silk",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3S0XS",
                "size": "XS",
                "color": "Coral Silk",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1M00L",
                "size": "L",
                "color": "Dark Chocolate",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1M00M",
                "size": "M",
                "color": "Dark Chocolate",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1M00S",
                "size": "S",
                "color": "Dark Chocolate",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1M0XL",
                "size": "XL",
                "color": "Dark Chocolate",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1M0XS",
                "size": "XS",
                "color": "Dark Chocolate",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0E00L",
                "size": "L",
                "color": "Electric Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0E00M",
                "size": "M",
                "color": "Electric Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0E00S",
                "size": "S",
                "color": "Electric Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0E0XL",
                "size": "XL",
                "color": "Electric Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0E0XS",
                "size": "XS",
                "color": "Electric Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2F00L",
                "size": "L",
                "color": "Forest Green",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2F00M",
                "size": "M",
                "color": "Forest Green",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2F00S",
                "size": "S",
                "color": "Forest Green",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2F0XL",
                "size": "XL",
                "color": "Forest Green",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2F0XS",
                "size": "XS",
                "color": "Forest Green",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4S00L",
                "size": "L",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4S00M",
                "size": "M",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4S00S",
                "size": "S",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4S0XL",
                "size": "XL",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4S0XS",
                "size": "XS",
                "color": "Garnet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8K00L",
                "size": "L",
                "color": "Graphite Heather",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8K00M",
                "size": "M",
                "color": "Graphite Heather",
                "stock": 24,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8K00S",
                "size": "S",
                "color": "Graphite Heather",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8K0XL",
                "size": "XL",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT8K0XS",
                "size": "XS",
                "color": "Graphite Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9N00L",
                "size": "L",
                "color": "Heather Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9N00M",
                "size": "M",
                "color": "Heather Navy",
                "stock": 4,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9N00S",
                "size": "S",
                "color": "Heather Navy",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9N0XL",
                "size": "XL",
                "color": "Heather Navy",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9N0XS",
                "size": "XS",
                "color": "Heather Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2R00L",
                "size": "L",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2R00M",
                "size": "M",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2R00S",
                "size": "S",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2R0XL",
                "size": "XL",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT2R0XS",
                "size": "XS",
                "color": "Heather Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0N00L",
                "size": "L",
                "color": "Indigo Blue",
                "stock": 19,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0N00M",
                "size": "M",
                "color": "Indigo Blue",
                "stock": 148,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0N00S",
                "size": "S",
                "color": "Indigo Blue",
                "stock": 91,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0N0XL",
                "size": "XL",
                "color": "Indigo Blue",
                "stock": 21,
                "status": "In Stock"
            },
            {
                "sku": "YOGT0N0XS",
                "size": "XS",
                "color": "Indigo Blue",
                "stock": 142,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4G00L",
                "size": "L",
                "color": "Kiwi",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4G00M",
                "size": "M",
                "color": "Kiwi",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4G00S",
                "size": "S",
                "color": "Kiwi",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4G0XL",
                "size": "XL",
                "color": "Kiwi",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4G0XS",
                "size": "XS",
                "color": "Kiwi",
                "stock": 7,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3F00L",
                "size": "L",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3F00M",
                "size": "M",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3F00S",
                "size": "S",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3F0XL",
                "size": "XL",
                "color": "Military Green",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "YOGT3F0XS",
                "size": "XS",
                "color": "Military Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5W00L",
                "size": "L",
                "color": "Natural",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5W00M",
                "size": "M",
                "color": "Natural",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5W00S",
                "size": "S",
                "color": "Natural",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5W0XL",
                "size": "XL",
                "color": "Natural",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5W0XS",
                "size": "XS",
                "color": "Natural",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTI000L",
                "size": "L",
                "color": "Neon Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTI000M",
                "size": "M",
                "color": "Neon Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTI000S",
                "size": "S",
                "color": "Neon Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTI00XL",
                "size": "XL",
                "color": "Neon Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTI00XS",
                "size": "XS",
                "color": "Neon Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9E00L",
                "size": "L",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9E00M",
                "size": "M",
                "color": "Neon Green",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9E00S",
                "size": "S",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9E0XL",
                "size": "XL",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9E0XS",
                "size": "XS",
                "color": "Neon Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 176,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 204,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1Q0XS",
                "size": "XS",
                "color": "Purple",
                "stock": 132,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5V00L",
                "size": "L",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5V00M",
                "size": "M",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5V00S",
                "size": "S",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5V0XL",
                "size": "XL",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5V0XS",
                "size": "XS",
                "color": "Safety Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4O00L",
                "size": "L",
                "color": "Safety Orange",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4O00M",
                "size": "M",
                "color": "Safety Orange",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4O00S",
                "size": "S",
                "color": "Safety Orange",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4O0XL",
                "size": "XL",
                "color": "Safety Orange",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4O0XS",
                "size": "XS",
                "color": "Safety Orange",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4Y00L",
                "size": "L",
                "color": "Sand",
                "stock": 148,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4Y00M",
                "size": "M",
                "color": "Sand",
                "stock": 136,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4Y00S",
                "size": "S",
                "color": "Sand",
                "stock": 114,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4Y0XL",
                "size": "XL",
                "color": "Sand",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4Y0XS",
                "size": "XS",
                "color": "Sand",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4D00L",
                "size": "L",
                "color": "Sky",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4D00M",
                "size": "M",
                "color": "Sky",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4D00S",
                "size": "S",
                "color": "Sky",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4D0XL",
                "size": "XL",
                "color": "Sky",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT4D0XS",
                "size": "XS",
                "color": "Sky",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTN000L",
                "size": "L",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTN000M",
                "size": "M",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTN000S",
                "size": "S",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTN00XS",
                "size": "XS",
                "color": "Violet",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 139,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "YOGT5B0XS",
                "size": "XS",
                "color": "Charcoal",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "YOGTT20XS",
                "size": "XS",
                "color": "Tropical Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTT200S",
                "size": "S",
                "color": "Tropical Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTT200M",
                "size": "M",
                "color": "Tropical Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTT200L",
                "size": "L",
                "color": "Tropical Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTT20XL",
                "size": "XL",
                "color": "Tropical Blue",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9Q0XS",
                "size": "XS",
                "color": "Heather Sapphire",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9Q00S",
                "size": "S",
                "color": "Heather Sapphire",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9Q00M",
                "size": "M",
                "color": "Heather Sapphire",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9Q00L",
                "size": "L",
                "color": "Heather Sapphire",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT9Q0XL",
                "size": "XL",
                "color": "Heather Sapphire",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "YOGTMG0XS",
                "size": "XS",
                "color": "Mint Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTMG00S",
                "size": "S",
                "color": "Mint Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTMG00M",
                "size": "M",
                "color": "Mint Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTMG00L",
                "size": "L",
                "color": "Mint Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTMG0XL",
                "size": "XL",
                "color": "Mint Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTX00XS",
                "size": "XS",
                "color": "Tennessee Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTX000S",
                "size": "S",
                "color": "Tennessee Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTX000M",
                "size": "M",
                "color": "Tennessee Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTX000L",
                "size": "L",
                "color": "Tennessee Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTX00XL",
                "size": "XL",
                "color": "Tennessee Orange",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTAI0XS",
                "size": "XS",
                "color": "Texas Orange",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "YOGTAI00S",
                "size": "S",
                "color": "Texas Orange",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "YOGTAI00M",
                "size": "M",
                "color": "Texas Orange",
                "stock": 94,
                "status": "In Stock"
            },
            {
                "sku": "YOGTAI00L",
                "size": "L",
                "color": "Texas Orange",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "YOGTAI0XL",
                "size": "XL",
                "color": "Texas Orange",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1H0XS",
                "size": "XS",
                "color": "Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1H00S",
                "size": "S",
                "color": "Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1H00M",
                "size": "M",
                "color": "Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1H00L",
                "size": "L",
                "color": "Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGT1H0XL",
                "size": "XL",
                "color": "Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTCB0XS",
                "size": "XS",
                "color": "Yellow Haze",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTCB00S",
                "size": "S",
                "color": "Yellow Haze",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTCB00M",
                "size": "M",
                "color": "Yellow Haze",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTCB00L",
                "size": "L",
                "color": "Yellow Haze",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTCB0XL",
                "size": "XL",
                "color": "Yellow Haze",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTU90XS",
                "size": "XS",
                "color": "Safety Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTU900S",
                "size": "S",
                "color": "Safety Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTU900M",
                "size": "M",
                "color": "Safety Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTU900L",
                "size": "L",
                "color": "Safety Pink",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOGTU90XL",
                "size": "XL",
                "color": "Safety Pink",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth T-shirt | G5000B",
        "sku": "Youth-Gildan-YouthT-Shirt",
        "id": "d3128967-2850-4988-8643-ddf247a6adc1"
    },
    // {
    //     "category": "Kids",
    //     "variations": [
    //         {
    //             "sku": "BCYT1W00S",
    //             "size": "S",
    //             "color": "White",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1W00M",
    //             "size": "M",
    //             "color": "White",
    //             "stock": 9,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1W00L",
    //             "size": "L",
    //             "color": "White",
    //             "stock": 23,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT2H00S",
    //             "size": "S",
    //             "color": "Athletic Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT2H00M",
    //             "size": "M",
    //             "color": "Athletic Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT2H00L",
    //             "size": "L",
    //             "color": "Athletic Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1B00S",
    //             "size": "S",
    //             "color": "Black",
    //             "stock": 10,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1B00M",
    //             "size": "M",
    //             "color": "Black",
    //             "stock": 10,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1B00L",
    //             "size": "L",
    //             "color": "Black",
    //             "stock": 8,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1K00S",
    //             "size": "S",
    //             "color": "Dark Grey Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1K00M",
    //             "size": "M",
    //             "color": "Dark Grey Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT1K00L",
    //             "size": "L",
    //             "color": "Dark Grey Heather",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYTI000S",
    //             "size": "S",
    //             "color": "Neon Blue",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYTI000M",
    //             "size": "M",
    //             "color": "Neon Blue",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYTI000L",
    //             "size": "L",
    //             "color": "Neon Blue",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT9E00S",
    //             "size": "S",
    //             "color": "Neon Green",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT9E00M",
    //             "size": "M",
    //             "color": "Neon Green",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT9E00L",
    //             "size": "L",
    //             "color": "Neon Green",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT7J00S",
    //             "size": "S",
    //             "color": "Neon Pink",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT7J00M",
    //             "size": "M",
    //             "color": "Neon Pink",
    //             "stock": 0,
    //             "status": "In Stock"
    //         },
    //         {
    //             "sku": "BCYT7J00L",
    //             "size": "L",
    //             "color": "Neon Pink",
    //             "stock": 0,
    //             "status": "In Stock"
    //         }
    //     ],
    //     "brand": null,
    //     "printer": "US1",
    //     "name": "Youth Tank - Top - Bella + Canvas | 3480Y",
    //     "sku": "Youth-BellaCanvas-V-NeckT-Shirt",
    //     "id": "1f620e05-ebe9-4cc1-892a-7374407b9281"
    // },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "YOLS1N0XS",
                "size": "XS",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 179,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 148,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 100,
                "status": "In Stock"
            },
            {
                "sku": "YOLS4H0XS",
                "size": "XS",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 148,
                "status": "In Stock"
            },
            {
                "sku": "YOLS4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 89,
                "status": "In Stock"
            },
            {
                "sku": "YOLS4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "YOLS4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1B0XS",
                "size": "XS",
                "color": "Black",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1B00S",
                "size": "S",
                "color": "Black",
                "stock": 22,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1B00M",
                "size": "M",
                "color": "Black",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1B00L",
                "size": "L",
                "color": "Black",
                "stock": 15,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "YOLS3G0XS",
                "size": "XS",
                "color": "Irish Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 13,
                "status": "In Stock"
            },
            {
                "sku": "YOLS3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1Q0XS",
                "size": "XS",
                "color": "Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1R0XS",
                "size": "XS",
                "color": "Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1R00S",
                "size": "S",
                "color": "Red",
                "stock": 3,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1R00M",
                "size": "M",
                "color": "Red",
                "stock": 137,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1R00L",
                "size": "L",
                "color": "Red",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "YOLS7C0XS",
                "size": "XS",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "YOLS7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOLS7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "YOLS7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "YOLS7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1W0XS",
                "size": "XS",
                "color": "White",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1W00S",
                "size": "S",
                "color": "White",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1W00M",
                "size": "M",
                "color": "White",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1W00L",
                "size": "L",
                "color": "White",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "YOLS1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 11,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Youth Long Sleeve T-Shirt | G5400B",
        "sku": "Youth-Gildan-YouthLongSleeve",
        "id": "5afb4678-69b7-4508-b504-cc39a6de7f8d"
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "UNVN2H3XL",
                "size": "3XL",
                "color": "ATHLETIC HEATHER",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H0XS",
                "size": "XS",
                "color": "ATHLETIC HEATHER",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B3XL",
                "size": "3XL",
                "color": "BLACK",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B0XS",
                "size": "XS",
                "color": "BLACK",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K3XL",
                "size": "3XL",
                "color": "DARK GREY HEATHER",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K0XS",
                "size": "XS",
                "color": "DARK GREY HEATHER",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H3XL",
                "size": "3XL",
                "color": "DEEP HEATHER",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H0XS",
                "size": "XS",
                "color": "DEEP HEATHER",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C3XL",
                "size": "3XL",
                "color": "HEATHER TRUE ROYAL",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C0XS",
                "size": "XS",
                "color": "HEATHER TRUE ROYAL",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R2XL",
                "size": "2XL",
                "color": "MAROON",
                "stock": 14,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R3XL",
                "size": "3XL",
                "color": "MAROON",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R00L",
                "size": "L",
                "color": "MAROON",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R00M",
                "size": "M",
                "color": "MAROON",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R00S",
                "size": "S",
                "color": "MAROON",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R0XL",
                "size": "XL",
                "color": "MAROON",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5R0XS",
                "size": "XS",
                "color": "MAROON",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N3XL",
                "size": "3XL",
                "color": "NAVY",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N0XS",
                "size": "XS",
                "color": "NAVY",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O2XL",
                "size": "2XL",
                "color": "ORANGE",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O3XL",
                "size": "3XL",
                "color": "ORANGE",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O00L",
                "size": "L",
                "color": "ORANGE",
                "stock": 66,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O00M",
                "size": "M",
                "color": "ORANGE",
                "stock": 67,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O00S",
                "size": "S",
                "color": "ORANGE",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O0XL",
                "size": "XL",
                "color": "ORANGE",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1O0XS",
                "size": "XS",
                "color": "ORANGE",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R3XL",
                "size": "3XL",
                "color": "RED",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R0XS",
                "size": "XS",
                "color": "RED",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D2XL",
                "size": "2XL",
                "color": "STEEL BLUE",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D3XL",
                "size": "3XL",
                "color": "STEEL BLUE",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D00L",
                "size": "L",
                "color": "STEEL BLUE",
                "stock": 68,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D00M",
                "size": "M",
                "color": "STEEL BLUE",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D00S",
                "size": "S",
                "color": "STEEL BLUE",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D0XL",
                "size": "XL",
                "color": "STEEL BLUE",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9D0XS",
                "size": "XS",
                "color": "STEEL BLUE",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q3XL",
                "size": "3XL",
                "color": "TEAM PURPLE",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q0XS",
                "size": "XS",
                "color": "TEAM PURPLE",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C3XL",
                "size": "3XL",
                "color": "TRUE ROYAL",
                "stock": 112,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C0XS",
                "size": "XS",
                "color": "TRUE ROYAL",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W3XL",
                "size": "3XL",
                "color": "WHITE",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W0XS",
                "size": "XS",
                "color": "WHITE",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N2XL",
                "size": "2XL",
                "color": "HEATHER NAVY",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R2XL",
                "size": "2XL",
                "color": "HEATHER RED",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R00M",
                "size": "M",
                "color": "HEATHER RED",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R0XS",
                "size": "XS",
                "color": "HEATHER RED",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H2XL",
                "size": "2XL",
                "color": "ATHLETIC HEATHER",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H00L",
                "size": "L",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H00M",
                "size": "M",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H00S",
                "size": "S",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2H0XL",
                "size": "XL",
                "color": "ATHLETIC HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B2XL",
                "size": "2XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B00L",
                "size": "L",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B00M",
                "size": "M",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B00S",
                "size": "S",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1B0XL",
                "size": "XL",
                "color": "BLACK",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K2XL",
                "size": "2XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K00L",
                "size": "L",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K00M",
                "size": "M",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K00S",
                "size": "S",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1K0XL",
                "size": "XL",
                "color": "DARK GREY HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H2XL",
                "size": "2XL",
                "color": "DEEP HEATHER",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H00L",
                "size": "L",
                "color": "DEEP HEATHER",
                "stock": 177,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H00M",
                "size": "M",
                "color": "DEEP HEATHER",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H00S",
                "size": "S",
                "color": "DEEP HEATHER",
                "stock": 152,
                "status": "In Stock"
            },
            {
                "sku": "UNVN6H0XL",
                "size": "XL",
                "color": "DEEP HEATHER",
                "stock": 135,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N3XL",
                "size": "3XL",
                "color": "HEATHER NAVY",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N00L",
                "size": "L",
                "color": "HEATHER NAVY",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N00M",
                "size": "M",
                "color": "HEATHER NAVY",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N00S",
                "size": "S",
                "color": "HEATHER NAVY",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N0XL",
                "size": "XL",
                "color": "HEATHER NAVY",
                "stock": 57,
                "status": "In Stock"
            },
            {
                "sku": "UNVN9N0XS",
                "size": "XS",
                "color": "HEATHER NAVY",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R3XL",
                "size": "3XL",
                "color": "HEATHER RED",
                "stock": 59,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R00L",
                "size": "L",
                "color": "HEATHER RED",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R00S",
                "size": "S",
                "color": "HEATHER RED",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN2R0XL",
                "size": "XL",
                "color": "HEATHER RED",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C2XL",
                "size": "2XL",
                "color": "HEATHER TRUE ROYAL",
                "stock": 188,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C00L",
                "size": "L",
                "color": "HEATHER TRUE ROYAL",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C00M",
                "size": "M",
                "color": "HEATHER TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C00S",
                "size": "S",
                "color": "HEATHER TRUE ROYAL",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3C0XL",
                "size": "XL",
                "color": "HEATHER TRUE ROYAL",
                "stock": 189,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F2XL",
                "size": "2XL",
                "color": "MILITARY GREEN",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F3XL",
                "size": "3XL",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F00L",
                "size": "L",
                "color": "MILITARY GREEN",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F00M",
                "size": "M",
                "color": "MILITARY GREEN",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F00S",
                "size": "S",
                "color": "MILITARY GREEN",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F0XL",
                "size": "XL",
                "color": "MILITARY GREEN",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "UNVN3F0XS",
                "size": "XS",
                "color": "MILITARY GREEN",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N2XL",
                "size": "2XL",
                "color": "NAVY",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N00L",
                "size": "L",
                "color": "NAVY",
                "stock": 157,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N00M",
                "size": "M",
                "color": "NAVY",
                "stock": 162,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N00S",
                "size": "S",
                "color": "NAVY",
                "stock": 176,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1N0XL",
                "size": "XL",
                "color": "NAVY",
                "stock": 171,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R2XL",
                "size": "2XL",
                "color": "RED",
                "stock": 249,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R00L",
                "size": "L",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R00M",
                "size": "M",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R00S",
                "size": "S",
                "color": "RED",
                "stock": 232,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1R0XL",
                "size": "XL",
                "color": "RED",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q2XL",
                "size": "2XL",
                "color": "TEAM PURPLE",
                "stock": 180,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q00L",
                "size": "L",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q00M",
                "size": "M",
                "color": "TEAM PURPLE",
                "stock": 187,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q00S",
                "size": "S",
                "color": "TEAM PURPLE",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5Q0XL",
                "size": "XL",
                "color": "TEAM PURPLE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C2XL",
                "size": "2XL",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C00L",
                "size": "L",
                "color": "TRUE ROYAL",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C00M",
                "size": "M",
                "color": "TRUE ROYAL",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C00S",
                "size": "S",
                "color": "TRUE ROYAL",
                "stock": 99,
                "status": "In Stock"
            },
            {
                "sku": "UNVN5C0XL",
                "size": "XL",
                "color": "TRUE ROYAL",
                "stock": 144,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W2XL",
                "size": "2XL",
                "color": "WHITE",
                "stock": 199,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W00L",
                "size": "L",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W00M",
                "size": "M",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W00S",
                "size": "S",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "UNVN1W0XL",
                "size": "XL",
                "color": "WHITE",
                "stock": 9999,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Unisex V-Neck T-shirt - Bella + Canvas | 3005",
        "sku": "Unisex-BellaCanvas-V-NeckT-Shirt",
        "id": "85582f9c-aed3-4200-b095-1de374a2b313"
        ////
    },
    {
        "category": "Unisex",
        "variations": [
            {
                "sku": "CCLS9H00S",
                "size": "S",
                "color": "Grey",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLS9H00M",
                "size": "M",
                "color": "Grey",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "CCLS9H00L",
                "size": "L",
                "color": "Grey",
                "stock": 113,
                "status": "In Stock"
            },
            {
                "sku": "CCLS9H0XL",
                "size": "XL",
                "color": "Grey",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "CCLS9H2XL",
                "size": "2XL",
                "color": "Grey",
                "stock": 135,
                "status": "In Stock"
            },
            {
                "sku": "CCLS9H3XL",
                "size": "3XL",
                "color": "Grey",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL200S",
                "size": "S",
                "color": "Seafoam",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W00S",
                "size": "S",
                "color": "White",
                "stock": 140,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL200M",
                "size": "M",
                "color": "Seafoam",
                "stock": 121,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL200L",
                "size": "L",
                "color": "Seafoam",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL20XL",
                "size": "XL",
                "color": "Seafoam",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL22XL",
                "size": "2XL",
                "color": "Seafoam",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 187,
                "status": "In Stock"
            },
            {
                "sku": "CCLSL23XL",
                "size": "3XL",
                "color": "Seafoam",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 18,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK300S",
                "size": "S",
                "color": "Pepper",
                "stock": 153,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK300M",
                "size": "M",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK300L",
                "size": "L",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK30XL",
                "size": "XL",
                "color": "Pepper",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK32XL",
                "size": "2XL",
                "color": "Pepper",
                "stock": 177,
                "status": "In Stock"
            },
            {
                "sku": "CCLSK33XL",
                "size": "3XL",
                "color": "Pepper",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R00S",
                "size": "S",
                "color": "Berry",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R00M",
                "size": "M",
                "color": "Berry",
                "stock": 196,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R00L",
                "size": "L",
                "color": "Berry",
                "stock": 118,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R0XL",
                "size": "XL",
                "color": "Berry",
                "stock": 101,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R2XL",
                "size": "2XL",
                "color": "Berry",
                "stock": 90,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0R3XL",
                "size": "3XL",
                "color": "Berry",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B00S",
                "size": "S",
                "color": "Black",
                "stock": 146,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 188,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA900S",
                "size": "S",
                "color": "Blossom",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA900M",
                "size": "M",
                "color": "Blossom",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA900L",
                "size": "L",
                "color": "Blossom",
                "stock": 113,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA90XL",
                "size": "XL",
                "color": "Blossom",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA92XL",
                "size": "2XL",
                "color": "Blossom",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA93XL",
                "size": "3XL",
                "color": "Blossom",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA000S",
                "size": "S",
                "color": "Blue Jean",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA000M",
                "size": "M",
                "color": "Blue Jean",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA000L",
                "size": "L",
                "color": "Blue Jean",
                "stock": 103,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA00XL",
                "size": "XL",
                "color": "Blue Jean",
                "stock": 151,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA02XL",
                "size": "2XL",
                "color": "Blue Jean",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCLSA03XL",
                "size": "3XL",
                "color": "Blue Jean",
                "stock": 63,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB200S",
                "size": "S",
                "color": "Blue Spruce",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB200M",
                "size": "M",
                "color": "Blue Spruce",
                "stock": 158,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB200L",
                "size": "L",
                "color": "Blue Spruce",
                "stock": 151,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB20XL",
                "size": "XL",
                "color": "Blue Spruce",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB22XL",
                "size": "2XL",
                "color": "Blue Spruce",
                "stock": 83,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB23XL",
                "size": "3XL",
                "color": "Blue Spruce",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB500S",
                "size": "S",
                "color": "Brick",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB500M",
                "size": "M",
                "color": "Brick",
                "stock": 161,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB500L",
                "size": "L",
                "color": "Brick",
                "stock": 152,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB50XL",
                "size": "XL",
                "color": "Brick",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB52XL",
                "size": "2XL",
                "color": "Brick",
                "stock": 85,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB53XL",
                "size": "3XL",
                "color": "Brick",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB700S",
                "size": "S",
                "color": "Bright Salmon",
                "stock": 46,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB700M",
                "size": "M",
                "color": "Bright Salmon",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB700L",
                "size": "L",
                "color": "Bright Salmon",
                "stock": 26,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB70XL",
                "size": "XL",
                "color": "Bright Salmon",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB72XL",
                "size": "2XL",
                "color": "Bright Salmon",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCLSB73XL",
                "size": "3XL",
                "color": "Bright Salmon",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y00S",
                "size": "S",
                "color": "Butter",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y00M",
                "size": "M",
                "color": "Butter",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y00L",
                "size": "L",
                "color": "Butter",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y0XL",
                "size": "XL",
                "color": "Butter",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y2XL",
                "size": "2XL",
                "color": "Butter",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLS6Y3XL",
                "size": "3XL",
                "color": "Butter",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC300S",
                "size": "S",
                "color": "Chalky Mint",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC300M",
                "size": "M",
                "color": "Chalky Mint",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC300L",
                "size": "L",
                "color": "Chalky Mint",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC30XL",
                "size": "XL",
                "color": "Chalky Mint",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC32XL",
                "size": "2XL",
                "color": "Chalky Mint",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC33XL",
                "size": "3XL",
                "color": "Chalky Mint",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC400S",
                "size": "S",
                "color": "Chambray",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC400M",
                "size": "M",
                "color": "Chambray",
                "stock": 105,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC400L",
                "size": "L",
                "color": "Chambray",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC40XL",
                "size": "XL",
                "color": "Chambray",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC42XL",
                "size": "2XL",
                "color": "Chambray",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "CCLSC43XL",
                "size": "3XL",
                "color": "Chambray",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S00S",
                "size": "S",
                "color": "Crimson",
                "stock": 48,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S00M",
                "size": "M",
                "color": "Crimson",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S00L",
                "size": "L",
                "color": "Crimson",
                "stock": 116,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S0XL",
                "size": "XL",
                "color": "Crimson",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S2XL",
                "size": "2XL",
                "color": "Crimson",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8S3XL",
                "size": "3XL",
                "color": "Crimson",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD500S",
                "size": "S",
                "color": "Crunchberry",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD500M",
                "size": "M",
                "color": "Crunchberry",
                "stock": 78,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD500L",
                "size": "L",
                "color": "Crunchberry",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD50XL",
                "size": "XL",
                "color": "Crunchberry",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD52XL",
                "size": "2XL",
                "color": "Crunchberry",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSD53XL",
                "size": "3XL",
                "color": "Crunchberry",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF500S",
                "size": "S",
                "color": "Flo Blue",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF500M",
                "size": "M",
                "color": "Flo Blue",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF500L",
                "size": "L",
                "color": "Flo Blue",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF50XL",
                "size": "XL",
                "color": "Flo Blue",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF52XL",
                "size": "2XL",
                "color": "Flo Blue",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF53XL",
                "size": "3XL",
                "color": "Flo Blue",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF600S",
                "size": "S",
                "color": "Granite",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF600M",
                "size": "M",
                "color": "Granite",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF600L",
                "size": "L",
                "color": "Granite",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF60XL",
                "size": "XL",
                "color": "Granite",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF62XL",
                "size": "2XL",
                "color": "Granite",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF63XL",
                "size": "3XL",
                "color": "Granite",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF800S",
                "size": "S",
                "color": "Graphite",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF800M",
                "size": "M",
                "color": "Graphite",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF800L",
                "size": "L",
                "color": "Graphite",
                "stock": 64,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF80XL",
                "size": "XL",
                "color": "Graphite",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF82XL",
                "size": "2XL",
                "color": "Graphite",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "CCLSF83XL",
                "size": "3XL",
                "color": "Graphite",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG400S",
                "size": "S",
                "color": "Hemp",
                "stock": 82,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG400M",
                "size": "M",
                "color": "Hemp",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG400L",
                "size": "L",
                "color": "Hemp",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG40XL",
                "size": "XL",
                "color": "Hemp",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG42XL",
                "size": "2XL",
                "color": "Hemp",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG43XL",
                "size": "3XL",
                "color": "Hemp",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG000S",
                "size": "S",
                "color": "Island Reef",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG000M",
                "size": "M",
                "color": "Island Reef",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG000L",
                "size": "L",
                "color": "Island Reef",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG00XL",
                "size": "XL",
                "color": "Island Reef",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG02XL",
                "size": "2XL",
                "color": "Island Reef",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSG03XL",
                "size": "3XL",
                "color": "Island Reef",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X00S",
                "size": "S",
                "color": "Ivory",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X00M",
                "size": "M",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X00L",
                "size": "L",
                "color": "Ivory",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X0XL",
                "size": "XL",
                "color": "Ivory",
                "stock": 242,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X2XL",
                "size": "2XL",
                "color": "Ivory",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCLS0X3XL",
                "size": "3XL",
                "color": "Ivory",
                "stock": 58,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH400S",
                "size": "S",
                "color": "Lagoon",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH400M",
                "size": "M",
                "color": "Lagoon",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH400L",
                "size": "L",
                "color": "Lagoon",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH40XL",
                "size": "XL",
                "color": "Lagoon",
                "stock": 41,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH42XL",
                "size": "2XL",
                "color": "Lagoon",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH43XL",
                "size": "3XL",
                "color": "Lagoon",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH700S",
                "size": "S",
                "color": "Light Green",
                "stock": 117,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH700M",
                "size": "M",
                "color": "Light Green",
                "stock": 200,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH700L",
                "size": "L",
                "color": "Light Green",
                "stock": 155,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH70XL",
                "size": "XL",
                "color": "Light Green",
                "stock": 115,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH72XL",
                "size": "2XL",
                "color": "Light Green",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSH73XL",
                "size": "3XL",
                "color": "Light Green",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI200S",
                "size": "S",
                "color": "Melon",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI200M",
                "size": "M",
                "color": "Melon",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI200L",
                "size": "L",
                "color": "Melon",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI20XL",
                "size": "XL",
                "color": "Melon",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI22XL",
                "size": "2XL",
                "color": "Melon",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI23XL",
                "size": "3XL",
                "color": "Melon",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI400S",
                "size": "S",
                "color": "Midnight",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI400M",
                "size": "M",
                "color": "Midnight",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI400L",
                "size": "L",
                "color": "Midnight",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI40XL",
                "size": "XL",
                "color": "Midnight",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI42XL",
                "size": "2XL",
                "color": "Midnight",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI43XL",
                "size": "3XL",
                "color": "Midnight",
                "stock": 31,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI700S",
                "size": "S",
                "color": "Mustard",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI700M",
                "size": "M",
                "color": "Mustard",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI700L",
                "size": "L",
                "color": "Mustard",
                "stock": 79,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI70XL",
                "size": "XL",
                "color": "Mustard",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI72XL",
                "size": "2XL",
                "color": "Mustard",
                "stock": 45,
                "status": "In Stock"
            },
            {
                "sku": "CCLSI73XL",
                "size": "3XL",
                "color": "Mustard",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ500S",
                "size": "S",
                "color": "Orchid",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ500M",
                "size": "M",
                "color": "Orchid",
                "stock": 70,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ500L",
                "size": "L",
                "color": "Orchid",
                "stock": 30,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ50XL",
                "size": "XL",
                "color": "Orchid",
                "stock": 72,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ52XL",
                "size": "2XL",
                "color": "Orchid",
                "stock": 37,
                "status": "In Stock"
            },
            {
                "sku": "CCLSJ53XL",
                "size": "3XL",
                "color": "Orchid",
                "stock": 65,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R00S",
                "size": "S",
                "color": "Red",
                "stock": 71,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R00M",
                "size": "M",
                "color": "Red",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R00L",
                "size": "L",
                "color": "Red",
                "stock": 163,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 93,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLS1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M00S",
                "size": "S",
                "color": "Sandstone",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M00M",
                "size": "M",
                "color": "Sandstone",
                "stock": 75,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M00L",
                "size": "L",
                "color": "Sandstone",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M0XL",
                "size": "XL",
                "color": "Sandstone",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M2XL",
                "size": "2XL",
                "color": "Sandstone",
                "stock": 38,
                "status": "In Stock"
            },
            {
                "sku": "CCLS8M3XL",
                "size": "3XL",
                "color": "Sandstone",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN600S",
                "size": "S",
                "color": "True Navy",
                "stock": 81,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN600M",
                "size": "M",
                "color": "True Navy",
                "stock": 73,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN600L",
                "size": "L",
                "color": "True Navy",
                "stock": 137,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN60XL",
                "size": "XL",
                "color": "True Navy",
                "stock": 109,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN62XL",
                "size": "2XL",
                "color": "True Navy",
                "stock": 36,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN63XL",
                "size": "3XL",
                "color": "True Navy",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN000S",
                "size": "S",
                "color": "Violet",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN000M",
                "size": "M",
                "color": "Violet",
                "stock": 108,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN000L",
                "size": "L",
                "color": "Violet",
                "stock": 111,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN00XL",
                "size": "XL",
                "color": "Violet",
                "stock": 115,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN02XL",
                "size": "2XL",
                "color": "Violet",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN03XL",
                "size": "3XL",
                "color": "Violet",
                "stock": 29,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO300S",
                "size": "S",
                "color": "Watermelon",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO300M",
                "size": "M",
                "color": "Watermelon",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO300L",
                "size": "L",
                "color": "Watermelon",
                "stock": 84,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO30XL",
                "size": "XL",
                "color": "Watermelon",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO32XL",
                "size": "2XL",
                "color": "Watermelon",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCLSO33XL",
                "size": "3XL",
                "color": "Watermelon",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP400S",
                "size": "S",
                "color": "Yam",
                "stock": 43,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP400M",
                "size": "M",
                "color": "Yam",
                "stock": 54,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP400L",
                "size": "L",
                "color": "Yam",
                "stock": 44,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP40XL",
                "size": "XL",
                "color": "Yam",
                "stock": 50,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP42XL",
                "size": "2XL",
                "color": "Yam",
                "stock": 56,
                "status": "In Stock"
            },
            {
                "sku": "CCLSP43XL",
                "size": "3XL",
                "color": "Yam",
                "stock": 33,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN400S",
                "size": "S",
                "color": "Terracotta",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN400M",
                "size": "M",
                "color": "Terracotta",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN400L",
                "size": "L",
                "color": "Terracotta",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN40XL",
                "size": "XL",
                "color": "Terracotta",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "CCLSN42XL",
                "size": "2XL",
                "color": "Terracotta",
                "stock": 0,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Long Sleeve T-shirt - Comfort Colors | 6014",
        "sku": "Unisex-ComfortColor-LongSleeve",
        "id": "9c729f7d-e7e6-4556-b1d4-8904463cd2f4"
    },
    {
        "category": "Women",
        "variations": [
            {
                "sku": "WOGT5H00S",
                "size": "S",
                "color": "Ash Grey",
                "stock": 113,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOGT5H00M",
                "size": "M",
                "color": "Ash Grey",
                "stock": 149,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOGT5H00L",
                "size": "L",
                "color": "Ash Grey",
                "stock": 178,
                "status": "Out Of Stock"
            },
            {
                "sku": "WOGT5H0XL",
                "size": "XL",
                "color": "Ash Grey",
                "stock": 192,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5H2XL",
                "size": "2XL",
                "color": "Ash Grey",
                "stock": 134,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P00S",
                "size": "S",
                "color": "Azalea",
                "stock": 77,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P00M",
                "size": "M",
                "color": "Azalea",
                "stock": 164,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P00L",
                "size": "L",
                "color": "Azalea",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P0XL",
                "size": "XL",
                "color": "Azalea",
                "stock": 212,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P2XL",
                "size": "2XL",
                "color": "Azalea",
                "stock": 102,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B00S",
                "size": "S",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B00M",
                "size": "M",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B00L",
                "size": "L",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1B3XL",
                "size": "3XL",
                "color": "Black",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P00S",
                "size": "S",
                "color": "Heliconia",
                "stock": 74,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P00M",
                "size": "M",
                "color": "Heliconia",
                "stock": 107,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P00L",
                "size": "L",
                "color": "Heliconia",
                "stock": 86,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P0XL",
                "size": "XL",
                "color": "Heliconia",
                "stock": 166,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P2XL",
                "size": "2XL",
                "color": "Heliconia",
                "stock": 62,
                "status": "In Stock"
            },
            {
                "sku": "WOGT8P3XL",
                "size": "3XL",
                "color": "Heliconia",
                "stock": 189,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G00S",
                "size": "S",
                "color": "Irish Green",
                "stock": 97,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G00M",
                "size": "M",
                "color": "Irish Green",
                "stock": 235,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G00L",
                "size": "L",
                "color": "Irish Green",
                "stock": 196,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G0XL",
                "size": "XL",
                "color": "Irish Green",
                "stock": 80,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G2XL",
                "size": "2XL",
                "color": "Irish Green",
                "stock": 61,
                "status": "In Stock"
            },
            {
                "sku": "WOGT3G3XL",
                "size": "3XL",
                "color": "Irish Green",
                "stock": 134,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P00S",
                "size": "S",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P00M",
                "size": "M",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P00L",
                "size": "L",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P0XL",
                "size": "XL",
                "color": "Light Pink",
                "stock": 137,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P2XL",
                "size": "2XL",
                "color": "Light Pink",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 194,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 231,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O00S",
                "size": "S",
                "color": "Orange",
                "stock": 69,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O00M",
                "size": "M",
                "color": "Orange",
                "stock": 147,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O00L",
                "size": "L",
                "color": "Orange",
                "stock": 151,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O0XL",
                "size": "XL",
                "color": "Orange",
                "stock": 120,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O2XL",
                "size": "2XL",
                "color": "Orange",
                "stock": 87,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q00S",
                "size": "S",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q00M",
                "size": "M",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q00L",
                "size": "L",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q0XL",
                "size": "XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q2XL",
                "size": "2XL",
                "color": "Purple",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R00S",
                "size": "S",
                "color": "Red",
                "stock": 183,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R00M",
                "size": "M",
                "color": "Red",
                "stock": 217,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R00L",
                "size": "L",
                "color": "Red",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R0XL",
                "size": "XL",
                "color": "Red",
                "stock": 216,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R2XL",
                "size": "2XL",
                "color": "Red",
                "stock": 123,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1R3XL",
                "size": "3XL",
                "color": "Red",
                "stock": 204,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT7C3XL",
                "size": "3XL",
                "color": "Royal",
                "stock": 165,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4H3XL",
                "size": "3XL",
                "color": "Sport Grey",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W00S",
                "size": "S",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W00M",
                "size": "M",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W00L",
                "size": "L",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1W3XL",
                "size": "3XL",
                "color": "White",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5H3XL",
                "size": "3XL",
                "color": "Ash Grey",
                "stock": 9,
                "status": "In Stock"
            },
            {
                "sku": "WOGT6P3XL",
                "size": "3XL",
                "color": "Azalea",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B2XL",
                "size": "2XL",
                "color": "Charcoal",
                "stock": 106,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B3XL",
                "size": "3XL",
                "color": "Charcoal",
                "stock": 51,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B00L",
                "size": "L",
                "color": "Charcoal",
                "stock": 122,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B00M",
                "size": "M",
                "color": "Charcoal",
                "stock": 76,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B00S",
                "size": "S",
                "color": "Charcoal",
                "stock": 52,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5B0XL",
                "size": "XL",
                "color": "Charcoal",
                "stock": 173,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y2XL",
                "size": "2XL",
                "color": "Daisy",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y3XL",
                "size": "3XL",
                "color": "Daisy",
                "stock": 20,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y00L",
                "size": "L",
                "color": "Daisy",
                "stock": 42,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y00M",
                "size": "M",
                "color": "Daisy",
                "stock": 8,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y00S",
                "size": "S",
                "color": "Daisy",
                "stock": 40,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2Y0XL",
                "size": "XL",
                "color": "Daisy",
                "stock": 39,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K3XL",
                "size": "3XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N2XL",
                "size": "2XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N3XL",
                "size": "3XL",
                "color": "Light Blue",
                "stock": 225,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N00L",
                "size": "L",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N00M",
                "size": "M",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N00S",
                "size": "S",
                "color": "Light Blue",
                "stock": 211,
                "status": "In Stock"
            },
            {
                "sku": "WOGT4N0XL",
                "size": "XL",
                "color": "Light Blue",
                "stock": 9999,
                "status": "In Stock"
            },
            {
                "sku": "WOGT5P3XL",
                "size": "3XL",
                "color": "Light Pink",
                "stock": 60,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1N3XL",
                "size": "3XL",
                "color": "Navy",
                "stock": 141,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1O3XL",
                "size": "3XL",
                "color": "Orange",
                "stock": 32,
                "status": "In Stock"
            },
            {
                "sku": "WOGT1Q3XL",
                "size": "3XL",
                "color": "Purple",
                "stock": 106,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "name": "Women's T-shirt | G5000L",
        "sku": "Women-Gildan-WomenT-Shirt",
        "id": "5bfd6155-cd06-4188-99fa-55b0e61bdb45"
    },
    {
        "category": "Women",
        "variations": [
            {
                "sku": "GWOV1B00S",
                "size": "S",
                "color": "Black",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1B00M",
                "size": "M",
                "color": "Black",
                "stock": 17,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1B00L",
                "size": "L",
                "color": "Black",
                "stock": 12,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1B0XL",
                "size": "XL",
                "color": "Black",
                "stock": 27,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1B2XL",
                "size": "2XL",
                "color": "Black",
                "stock": 5,
                "status": "In Stock"
            },
            {
                "sku": "GWOV6P00S",
                "size": "S",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV6P00M",
                "size": "M",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV6P00L",
                "size": "L",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV6P0XL",
                "size": "XL",
                "color": "Azalea",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "GWOV6P2XL",
                "size": "2XL",
                "color": "Azalea",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1J00S",
                "size": "S",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1J00M",
                "size": "M",
                "color": "Cherry Red",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1J00L",
                "size": "L",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1J0XL",
                "size": "XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1J2XL",
                "size": "2XL",
                "color": "Cherry Red",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV2K00S",
                "size": "S",
                "color": "Dark Heather",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "GWOV2K00M",
                "size": "M",
                "color": "Dark Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV2K00L",
                "size": "L",
                "color": "Dark Heather",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV2K0XL",
                "size": "XL",
                "color": "Dark Heather",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "GWOV2K2XL",
                "size": "2XL",
                "color": "Dark Heather",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOVG200S",
                "size": "S",
                "color": "Heather Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOVG200M",
                "size": "M",
                "color": "Heather Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOVG200L",
                "size": "L",
                "color": "Heather Purple",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOVG20XL",
                "size": "XL",
                "color": "Heather Purple",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOVG22XL",
                "size": "2XL",
                "color": "Heather Purple",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1N00S",
                "size": "S",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1N00M",
                "size": "M",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1N00L",
                "size": "L",
                "color": "Navy",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1N0XL",
                "size": "XL",
                "color": "Navy",
                "stock": 10,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1N2XL",
                "size": "2XL",
                "color": "Navy",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV7C00S",
                "size": "S",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV7C00L",
                "size": "L",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV7C00M",
                "size": "M",
                "color": "Royal",
                "stock": 47,
                "status": "In Stock"
            },
            {
                "sku": "GWOV7C0XL",
                "size": "XL",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV7C2XL",
                "size": "2XL",
                "color": "Royal",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV4H00S",
                "size": "S",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV4H00M",
                "size": "M",
                "color": "Sport Grey",
                "stock": 23,
                "status": "In Stock"
            },
            {
                "sku": "GWOV4H00L",
                "size": "L",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV4H0XL",
                "size": "XL",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV4H2XL",
                "size": "2XL",
                "color": "Sport Grey",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1W00S",
                "size": "S",
                "color": "White",
                "stock": 11,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1W00M",
                "size": "M",
                "color": "White",
                "stock": 34,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1W00L",
                "size": "L",
                "color": "White",
                "stock": 0,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1W0XL",
                "size": "XL",
                "color": "White",
                "stock": 35,
                "status": "In Stock"
            },
            {
                "sku": "GWOV1W2XL",
                "size": "2XL",
                "color": "White",
                "stock": 21,
                "status": "In Stock"
            }
        ],
        "brand": null,
        "printer": "US1",
        "sku": "Women-Gildan-WomensV-NeckT-Shirt",
        "name": "Women's V-neck T-shirt | G64V00L",
        "id": "82771fc2-db52-4baa-8afe-0375cc3ec171"
    }
]
export default mango
