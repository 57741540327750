/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Card from "@mui/material/Card";
import MDCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { styled } from '@mui/system';
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDPagination from 'components/MDPagination';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NoteDialog from 'examples/NoteDialog';
import DataTable from "examples/Tables/DataTable";
import { editOrder, ffbeeful, ffmango, ffmerchfox, ffprintway, getOrders } from "features/slices";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CheckIcon from '@mui/icons-material/Check';
import bcrypt from 'bcryptjs';
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import beeful from "./beeful";
import mango from './mango';
import merchfox from "./merchfox";
import teecraft from "./teecraft";
import { updateCurrentPage } from 'features/slices';
import { ffteecraft } from 'features/slices';
const Section = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

const GreenSection = styled(Section)({
});

const BlueSection = styled(Section)({
  background: 'linear-gradient(195deg, #9dbdba, #9dbdba)',
  width: "100%",
  display: "flex",
  flexDirection: "row"
});

const DarkSection = styled(Section)({
  background: 'linear-gradient(195deg, #42424a, #191919)',
});

const CaptionText = styled('p')({
  display: "flex",
  flexDirection: "row",
  fontWeight: 'bold',
  marginTop: 4,
  fontSize: "14px",
  color: "black"
});

const CaptionText2 = styled('p')({
  fontWeight: 'bold',
  marginTop: 4,
  color: "white",
  fontSize: "14px",
});

const InfoText = styled('span')({
  marginLeft: 5,
  color: "#344767"
});

const NormalText = styled('span')({
  fontWeight: '400',
  marginLeft: 5,
  // color: 'white',
});

const HighlightedText = styled('span')({
  fontWeight: '500',
  color: 'orange',
  marginLeft: 5,
  fontSize: "14px"

});

const RedText = styled('span')({
  color: 'red',
  fontWeight: 'bold',
});
const ffSite = ["Merchfox", "Mango", "Beeful", "Printify", "Printway", "Burgershop", "Teecraft"]

function Tables() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.data || []);
  const permission = useSelector((state) => state.user.permission || []);
  const startDate = useSelector((state) => state.user.startDate);
  const type = useSelector((state) => state.user.type);
  const filterStore = useSelector((state) => state.user.filterStore);
  const order_status = useSelector((state) => state.user.order_status);
  const endDate = useSelector((state) => state.user.endDate);
  const user = useSelector((state) => state.user.user);
  const search = useSelector((state) => state.user.search);
  const currentPage = useSelector((state) => state.user.currentPage) || 1

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [clear, setClear] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [itemEditor, setItemEditor] = useState({})
  const [tracking, setTracking] = useState("");
  const [fulfil, setFulfil] = useState("");
  const [lineship, setLineship] = useState("");
  const [note, setNote] = useState("");
  const [basecost, setBasecost] = useState("");
  const [ff, setFf] = useState("Merchfox");
  const [selectedItems, setSelectedItems] = useState([]);

  function modifyUrlString(inputString) {
    if (inputString.includes("/cdn-cgi")) {
      const regex = /https:\/\//g;
      const matches = inputString.match(regex);
      if (!matches) {
        return inputString;
      } else if (matches.length === 1) {
        return 'https://' + inputString.split('https://')[1]
      } else if (matches.length === 2) {
        const parts = inputString.split('https://');
        return 'https://' + parts[2];
      } else {
        return inputString;
      }
    } else {
      return inputString;
    }

  }

  const handleDeleteNote = async (index) => {
    const updatedNotes = itemEditor.note.split("\n").filter((_, i) => i !== index);
    const res = await dispatch(editOrder({ user, field: "note", string: updatedNotes.join("\n"), id: itemEditor.id }))
    if (res.payload.status == 0) {
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search, type
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    }
    setOpen2(false)
  };
  const handleCheckboxChange = (item) => (event) => {
    const tshirt = ["YouthT-Shirt", "WomenT-Shirt", "UnisexT-Shirt", "WomensV-NeckT-Shirt", "V-NeckT-Shirt"]
    let have = ""
    let size = item.size.replace("*", "").replace("Type: ", "")
      .replace("Fabric Brand: ", "")
      .replace("Style: ", "")
      .replace("Size: ", "")
      .replace(/'/g, "")
      .replace("Product Sides Options: ", "").replace(/ /g, "")
    let sizeArray = size.split("-")
    for (let i of tshirt) {
      if (size.includes(i)) {
        have = i
        size = size.replace(`${i}-`, "")
        sizeArray = size.split("-")
        sizeArray.splice(2, 0, i);
        break;
      }
    }
    let ffSKU = []
    let cate = ""
    console.log(sizeArray)
    let mainProduct = []
    if (ff == "Mango") {
      mainProduct = mango.filter(items => items.sku == `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}`)
      ffSKU = mainProduct?.[0]?.variations.filter(items => {
        return items.size.toUpperCase().replace(/ /g, "") == sizeArray?.[3].toUpperCase().replace(/ /g, "") && items.color.toUpperCase().replace(/ /g, "") == item.color.toUpperCase().replace(/ /g, "")
      }) || []
      if (!ffSKU.length) {
        NotificationManager.error(`Mango does not have this data variable!`, "Error", 2000);
        return
      }
    } else if (ff == "Teecraft") {
      mainProduct = teecraft.filter(items => items.sku == `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}`)
      ffSKU = mainProduct?.[0]?.variations.filter(items => {
        return items.size.toUpperCase().replace(/ /g, "") == sizeArray?.[3].toUpperCase().replace(/ /g, "") && items.color.toUpperCase().replace(/ /g, "") == item.color.toUpperCase().replace(/ /g, "")
      }) || []
      if (!ffSKU.length) {
        NotificationManager.error(`Teecraft does not have this data variable!`, "Error", 2000);
        return
      }
      cate = mainProduct?.[0].category
    }
    else if (ff == "Beeful") {
      mainProduct = beeful.filter(items => items.name == `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}`)
      ffSKU = mainProduct?.[0]?.variations.filter(items => {
        return items.size.toUpperCase().replace(/ /g, "") == sizeArray?.[3].toUpperCase().replace(/ /g, "") && items.color.toUpperCase().replace(/ /g, "") == item.color.toUpperCase().replace(/ /g, "")
      }) || []
      if (!ffSKU.length) {
        NotificationManager.error(`Beeful does not have this data variable!`, "Error", 2000);
        return
      }
    }
    else if (ff == "Merchfox") {
      mainProduct = item.size.includes("Size: US") ? merchfox.filter(items => items.sku == "Crocs") : merchfox.filter(items => items.sku == `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}`)
      if (mainProduct.length) {
        if (item.size.includes("Size: US") && !mainProduct[0].size.some(items => items[1] == sizeArray[0])) {
          NotificationManager.error("Crocs Merchfox does not have this size!", "Error", 2000);
        }
        if (!item.size.includes("Size: US") && !mainProduct[0].size.some(items => items == sizeArray?.[3])) {
          NotificationManager.error("Merchfox does not have this size!", "Error", 2000);
          return;
        }
        if (!mainProduct[0].color.some(items =>
          `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}` == "Unisex-BellaCanvas-UnisexT-Shirt" || `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}` == "Unisex-ComfortColor-UnisexT-Shirt" ?
            items == item.color.trim() :
            items == item.color.trim().toUpperCase()
        ) && !item.size.includes("Size: US")) {
          NotificationManager.error(`Merchfox does not have this color!`, "Error", 2000);
          return;
        }
      } else {
        NotificationManager.error(`Merchfox does not have this data variable!`, "Error", 2000);
        return
      }
    } else {
      return
    }


    if ((item.size.replace("*", "").includes("Both Sides") && item.design_link?.front && item.design_link?.back) || ((item.size.replace("*", "").includes("Front Side Only") || item.size.replace("*", "").includes("Front Side only")) && item.design_link?.front) || ((item.size.replace("*", "").includes("Back Side Only") || item.size.replace("*", "").includes("Back Side only")) && item.design_link?.back) || (item.size.replace("*", "").includes("Size: US") && item.design_link?.front)) {
      const itemId = item.id;
      const isChecked = event.target.checked;
      let sizeCrocs = ""
      if (item.size.includes("Size: US")) {
        sizeCrocs = mainProduct[0].size.filter(items => items[1] == sizeArray[0])
      }
      setSelectedItems(prev => ({
        ...prev,
        [itemId]: isChecked ? {
          id: mainProduct?.[0]?.id || ffSKU?.[0]?.id, cate, product: null, size: item.size.includes("Size: US") ? sizeCrocs[0][0] : sizeArray?.[3], color: item.size.includes("Size: US") ? "3D Printed" : `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}` == "Unisex-BellaCanvas-UnisexT-Shirt" || `${sizeArray?.[0]}-${sizeArray?.[1]}-${sizeArray?.[2]}` == "Unisex-ComfortColor-UnisexT-Shirt" ? item.color.trim() : item.color.trim().toUpperCase(), sku: ffSKU?.[0]?.sku, items: data.data && data.data.length ? data.data.filter(items => items.id == itemId) : null
        } : undefined
      }));
    } else {
      NotificationManager.error(`Order miss design!`, "Error", 2000);
    }
  };

  const handleOpenDialogFulfil = async (items) => {
    setSelectedItems([])
    setItemEditor(items)
    setOpen5(true)
  };

  const handleCheckBoxFF = async (items) => {
    if (items.fulfil_id) {
      setItemEditor(items)
      setOpen8(true)
    }
  };

  const handleCopyClick = (items) => {
    let data_copy = [items.customer_name, items.address[0], items.address[1], items.address[2], items.address[3], items.address[4], items.address[5], "", items.images]
    NotificationManager.success(`Copied information!`, "Error", 700);
    navigator.clipboard.writeText(data_copy.join("\t"))
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const handleFulFil = async () => {
    let res
    let i = 0
    let newData = []
    Object.keys(selectedItems).forEach(itemId => {
      const item = selectedItems[itemId];
      newData.push({ items: selectedItems[itemId].items, sku: selectedItems[itemId].sku, color: selectedItems[itemId]?.color, size: selectedItems[itemId]?.size, id: selectedItems[itemId]?.id, cate: selectedItems[itemId]?.cate })
      if (ff != "Merchfox" && item != undefined && (!item?.product || !item?.size || !item?.sku)) {
        if (ff == "Mango" && item != undefined && item?.sku) {
        } else if (ff == "Teecraft" && item != undefined && item?.sku) {
        } else if (ff == "Beeful" && item != undefined && item?.id) { }
        else {
          NotificationManager.error(`One item incomplete.`, "Error", 2000);
          newData = []
          i++
          return
        }
      } else if (ff == "Merchfox" && item != undefined) {
        console.log(item)
        if ((!item?.id || !item?.size || !item?.color) && !item.items[0].size.includes("Size: US")) {
          NotificationManager.error(`One item incomplete.`, "Error", 2000);
          newData = []
          i++
          return
        }
      }
    });
    if (ff == "Mango") {
      if (i == 0) {
        res = await dispatch(ffmango({ data: newData }))
      }
    } else if (ff == "Teecraft") {
      if (i == 0) {
        res = await dispatch(ffteecraft({ data: newData }))
      }
    } else if (ff == "Merchfox") {
      if (i == 0) {
        res = await dispatch(ffmerchfox({ data: newData }))
      }
    }
    else if (ff == "Beeful") {
      if (i == 0) {
        res = await dispatch(ffbeeful({ data: newData }))
      }
    }
    else if (ff == "Printway") {
      if (i == 0) {
        res = await dispatch(ffprintway({ data: newData }))
      }
    }
    if (res?.payload?.status == 0) {
      setOpen5(false)
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search, type
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    } else {

    }
  }

  const handleChangePage = async (newPage) => {
    await dispatch(updateCurrentPage(newPage))
  };

  const handleAddBasecost = async (item) => {
    setItemEditor(item)
    item.basecost != 0 ? setBasecost(item.basecost) : setBasecost("")
    setOpen3(true)
  }
  const handleChangeTracking = async (e) => {
    const res = await dispatch(editOrder({ user, field: "tracking", liststring: [e ? e : tracking, fulfil, lineship], order_id: itemEditor.order_id, store: itemEditor.store_name, id: itemEditor.id, user }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen1(false)
  }

  const handleChangeNote = async (e) => {
    const res = await dispatch(editOrder({ user, field: "note", string: !itemEditor.note ? `${user.replace("@gmail.com", "")}: ${note}` : `${itemEditor.note}\n${user.replace("@gmail.com", "")}:${note}`, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen2(false)
  }

  const handleChangeColor2 = async (e) => {
    const res = await dispatch(editOrder({ user, field: "color", string: color, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen6(false)
  }

  const handleChangeSize2 = async (e) => {
    const res = await dispatch(editOrder({ user, field: "size", string: size, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen7(false)
  }

  const handleClear = async (e) => {
    const res = await dispatch(editOrder({ user, field: "fulfil", id: itemEditor.fulfil_id }))
    if (res.payload.status == 0) {
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search, type
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    }
    setOpen8(false)
  }
  console.log(data)

  const handleChangeBasecost = async (e) => {
    const res = await dispatch(editOrder({ user, field: "basecost", string: basecost, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen3(false)
  }

  const handleShowInformation = (item) => {
    setItemEditor(item)
    setOpen4(true)
  }

  const handleAddTracking = (item) => {
    setTracking(item.tracking)
    setLineship(item.lineship)
    setFulfil(item.fulfil)
    setItemEditor(item)
    setOpen1(true)
  };

  const handleAddNote = (item) => {
    setNote("")
    setItemEditor(item)
    setOpen2(true)
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };
  const handleClose8 = () => {
    setOpen8(false);
  };



  const changeCheckBox = async (items, checked, field, id) => {
    setItemEditor(items)
    const res = await dispatch(editOrder({ user, field, boolean: checked, id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
  }

  const handleChangeColor = async (items) => {
    setItemEditor(items)
    setColor(items.color)
    setOpen6(true)
  }

  const handleChangeSize = async (items) => {
    setItemEditor(items)
    setSize(items.size)
    setOpen7(true)
  }

  const capitalizeAndColorBeforeColon = (note) => {
    const lines = note.split('\n');
    const totalLines = lines.length;
    const linesToShow = 3;

    const displayLines = totalLines > linesToShow
      ? ['...'].concat(lines.slice(totalLines - linesToShow))
      : lines;

    return displayLines.map((line, index) => {
      const [beforeColon, afterColon] = line.split(':', 2);
      const capitalizedBeforeColon = beforeColon.trim().charAt(0).toUpperCase() + beforeColon.trim().slice(1);
      return (
        <div key={index}>
          <span style={{ color: 'red' }}>{capitalizedBeforeColon}</span>: {afterColon ? afterColon.trim() : ""}
        </div>
      );
    });
  };
  function getFirstWords(text) {
    if (text.length > 35) {
      return text.substring(0, 35) + "...";
    } else {
      return text;
    }
  }


  const renderPagination = Array.from({ length: data.totalPage }, (_, index) => index + 1)
    .filter(page =>
      page === 1 || // First page
      page === data.totalPage || // Last page
      Math.abs(page - currentPage) <= 1 // Pages adjacent to the current page
    )
    .map((page, idx, filteredPages) => (
      <>
        {/* Display ellipsis if there's a gap from the previous page */}
        {idx > 0 && page - filteredPages[idx - 1] > 1 && (
          <span key={`ellipsis-${page}`} style={{ padding: "0 5px" }}>...</span>
        )}
        <MDPagination
          item
          key={page}
          onClick={() => handleChangePage(page)}
          active={currentPage === page}
        >
          {page}
        </MDPagination>
      </>
    ));


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "date", accessor: "date", align: "center" },
                      { Header: "order id", accessor: "order_id", align: "left" },
                      { Header: "name", accessor: "items_name", align: "left" },
                      { Header: "qua", accessor: "quantity", align: "left" },
                      { Header: "basecost / price", accessor: "price", align: "left" },
                      { Header: "track / fulfil", accessor: "tracking", align: "left" },
                      { Header: "note", accessor: "note", align: "left" },
                      { Header: "design", accessor: "design", align: "left" },
                      { Header: "status fulfil", accessor: "status_fulfil", align: "center" },
                      { Header: "fulfil", accessor: "fulfil", align: "center" },
                    ],
                    rows: data.data && data.data.length > 0 ? [...data.data.map((items, index) => ({
                      order_status: items.error_order,
                      id: items.order_id,
                      cancel: items.tracking,
                      itemEditor: itemEditor.order_id,
                      date: <>
                        {items.price || search || order_status == "tracking" ? <span className="caption-text" style={{ fontWeight: items?.price > 0 ? "bold" : "", color: items?.price > 0 ? "black" : "" }}>{moment(items.date).format("HH:mm DD/MM")}</span> : ""}
                      </>,
                      order_id: <>
                        {
                          items.price || search || order_status == "tracking" ? <>
                            <p className="caption-text" style={{ color: "black", fontWeight: items.price ? "bold" : "", color: items.price ? "black" : "#7b809a" }}> {`${items.order_id}`}</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>  {items?.store_name == "Sesverin" || items?.store_name == "Navlak" || items?.store_name == "Ladyjacket" || items?.store_name == "Atorcus" || items?.store_name == "Prashirt" || items?.store_name == "Thalath" ?
                              <img src="https://egead.nyc3.digitaloceanspaces.com/1728270648803h7puf0.jpg" style={{ height: "20px", marginRight: "4px" }} />
                              : null}<p className="caption-text">{`${items.store_name}`}</p></div></> : ""}</>,

                      items_name: <div style={{ display: "flex", flexDirection: "column" }}>
                        <div><a href={items.link} target="_blank" rel="noopener noreferrer" className="caption-text" style={{ fontWeight: items?.price > 0 ? "400" : "" }}>{getFirstWords(items.items_name)}</a> <span className="caption-text" style={{ fontSize: "8px" }}>{items.sku}</span></div>
                        <span>
                          <span onClick={!items.fulfil && (user == "nnthuy1509@gmail.com" || user == "trong.quang.dao@gmail.com" || user == "thanhhuongworkbmt@gmail.com") ? () => handleChangeSize(items) : null} style={{ fontSize: "10px", cursor: "pointer", minWidth: "20px" }}>
                            {items.size.replace("Type: ", "")
                              .replace("Fabric Brand: ", "")
                              .replace("Style: ", "")
                              .replace("Size: ", "")
                              .replace("Product Sides Options: ", "")}</span> -
                          {/* <span onClick={!items.fulfil && user == "nnthuy1509@gmail.com" ? () => handleChangeColor(items) : null} style={{ fontSize: "10px", color: "red", fontWeight: "bold", cursor: "pointer" }}> */}
                          <span onClick={!items.fulfil ? () => handleChangeColor(items) : null} style={{ fontSize: "10px", color: "red", fontWeight: "bold", cursor: "pointer" }}>
                            {items.color}</span>
                          <button style={{ width: 20, height: 20, marginLeft: "5px", border: "1px solid #F3C262", background: "#F3C262", borderRadius: "100%", fontWeight: "bold" }} onClick={() => handleShowInformation(items)}>!</button>
                          <ContentCopyIcon style={{ fontSize: "small", verticalAlign: "middle", marginLeft: "5px", color: "blue" }} onClick={() => handleCopyClick(items)} />
                        </span>

                      </div>,
                      quantity: (<><span className="caption-text" style={{ color: "black" }}>{items.quantity}</span> {items.quantity > 1 ? <ArrowDropUpIcon fontSize="medium" style={{ color: "green" }} /> : null}</>),
                      price: (
                        <>
                          {items.status_fulfil && items.basecost == 0 ? <MDBadge badgeContent={"+"} color={"light"} variant="gradient" style={{ cursor: "pointer" }} size="md" onClick={permission.includes("basecost") ? () => handleAddBasecost(items) : null} />
                            : <MDBadge badgeContent={items.basecost.toFixed(2).toString()} color={items?.basecost ? "primary" : "dark"} variant="gradient" style={{ cursor: "pointer" }} size="md" max={99999999999999999999999999999} onClick={permission.includes("basecost") ? () => handleAddBasecost(items) : null} />}
                          {items.price ? <MDBadge badgeContent={items.price} color="success" variant="gradient" size="md" max={99999999999999999999999999999} /> : null}
                        </>
                      ),
                      tracking: (
                        // <div style={{ cursor: "pointer" }} onClick={() => permission.includes("tracking") ? handleAddTracking(items) : ""}>
                        <div style={{ cursor: "pointer" }} onClick={() => (items.tracking && (moment().unix() * 1000 - items.updatedAt > 86400000)) || !permission.includes("tracking") ? "" : handleAddTracking(items)}>
                          <MDBadge badgeContent={items.tracking ? items.tracking : "none"} max={99999999999999999999999999999} color={items?.tracking ? items?.tracking.includes("refund") ? "warning" : items?.tracking.includes("cancel") ? "error" : items?.cong ? "light" : "info" : "dark"} variant="gradient" size="sm" />
                          <MDBadge badgeContent={items.fulfil ? items.fulfil : "none"} max={99999999999999999999999999999} color={items.fulfil ? items.fulfil.includes("A-") ? "primary" : "warning" : "dark"} variant="gradient" size="sm" />
                        </div>
                      ),

                      note: <div style={{ display: "flex", flexDirection: "column" }}>
                        {items.price && items.customer_note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}><span style={{
                          fontSize: "11px", color: "black", fontWeight: "bold"
                        }}>Customer Note: </span>{items.customer_note}</span> : null}
                        {items.note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          color: "black",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}>{capitalizeAndColorBeforeColon(items.note)}</span> : null}
                        {permission.includes("note") ? <MDBadge badgeContent={`Add Note`} onClick={() => handleAddNote(items)} style={{ cursor: "pointer" }} color="warning" variant="gradient" size="sm" /> : null}
                      </div>,
                      design: (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {items?.design_link ? <div>
                            {items?.design_link?.front ? items?.design_link?.front == "upload" ? <MDBadge badgeContent={`Uploading`} color="warning" variant="gradient" size="sm" /> : <a key={0} target="_blank" rel="noopener noreferrer" href={items?.design_link.front}>
                              <MDBadge badgeContent={`Front`} color="light" variant="gradient" size="sm" />
                            </a> : null}
                            {items?.design_link?.back ? <a key={1} target="_blank" rel="noopener noreferrer" href={items?.design_link.back}>
                              <MDBadge badgeContent={`Back`} color="light" variant="gradient" size="sm" />
                            </a> : null}
                          </div> : null}

                        </div>
                      ),
                      status_fulfil: (
                        <FormControlLabel
                          control={<MDCheckbox checked={items?.status_fulfil}
                            icon={
                              <span
                                style={{
                                  borderRadius: '50%',
                                  width: 20,
                                  height: 20,
                                  borderColor: 'black',
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                            }
                            checkedIcon={
                              <span
                                style={{
                                  borderRadius: '50%',
                                  width: 20,
                                  height: 20,
                                  backgroundColor: '#1976d2',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                              </span>
                            }
                            onClick={() => permission.includes("status_fulfil") && !items?.tracking.includes("cancel") && !items?.tracking.includes("refund") && !items.fulfil.includes("A-") ? changeCheckBox(items, items?.status_fulfil, "status_fulfil", items.id) : items.fulfil.includes("A-") && permission.includes("status_fulfil") ? handleCheckBoxFF(items) : null} />}
                        />
                        // <>{items?.status_fulfil ? <MDCheckbox checked={true} /> : false}</>
                      ),
                      fulfil: (
                        <>{
                          items.price && permission.includes("status_fulfil") ?
                            <ArrowCircleRightTwoToneIcon style={{ color: "green" }} fontSize='medium' onClick={() => handleOpenDialogFulfil(items)} />
                            : null
                        }</>
                      ), index: true
                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  padding="5px"
                >
                  <MDPagination
                    variant="gradient"
                    color="info"
                  >
                    {currentPage > 1 && data.totalPage > 1 && (
                      <MDPagination item onClick={() => handleChangePage(currentPage - 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    )}
                    {renderPagination}
                    {currentPage < data.totalPage && (
                      <MDPagination item onClick={() => handleChangePage(currentPage + 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    )}
                  </MDPagination>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open1} onClose={handleClose1}>
        <DialogTitle>Tracking - Fulfil</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Tracking"
              sx={{ margin: "10px" }}
              value={tracking}
              onChange={(e) => setTracking(e.target.value)}
            />
            <TextField
              label="Lineship"
              sx={{ margin: "10px" }}
              value={lineship}
              onChange={(e) => setLineship(e.target.value)}
            />
            <TextField
              label="Fulfil"
              sx={{ margin: "10px" }}
              value={fulfil}
              onChange={(e) => setFulfil(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangeTracking("cancel")} variant="outline" style={{ color: "red" }}>
            Cancelled
          </Button>
          <Button onClick={() => handleChangeTracking("refund")} variant="outline" style={{ color: "orange" }}>
            Refunded
          </Button>
          <Button onClick={() => handleChangeTracking()} variant="outline" style={{ color: "green" }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <NoteDialog open2={open2} handleDeleteNote={handleDeleteNote} handleClose2={handleClose2} note2={note} note={itemEditor.note} user={user.replace("@gmail.com", "")} setNote={setNote} handleChangeNote={handleChangeNote} />
      <Dialog open={open3} onClose={handleClose3}>
        <DialogTitle>Basecost</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Basecost"
              sx={{ margin: "10px", width: 300 }}
              value={basecost}
              onChange={(e) => setBasecost(e.target.value)}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangeBasecost()} variant="outline" style={{ color: "green" }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open4} onClose={handleClose4}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <GreenSection>
              <CaptionText>Order ID: <InfoText>{itemEditor.order_id}</InfoText></CaptionText>
              <CaptionText>Store: <InfoText>{itemEditor.store_name}</InfoText></CaptionText>
              <CaptionText>Payment Method: <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}><img src={itemEditor.shield ? itemEditor.shield.includes("PayPal:") ? "https://egead.nyc3.digitaloceanspaces.com/pp.png" : itemEditor.shield.includes("Stripe:") ? "https://egead.nyc3.digitaloceanspaces.com/stripe.png" : "https://egead.nyc3.digitaloceanspaces.com/card.png" : "https://egead.nyc3.digitaloceanspaces.com/card.png"} style={{ height: 20, marginRight: 5 }}></img>{itemEditor.shield ? itemEditor.shield.replace("PayPal:", "").replace("Stripe:", "").replace("Stripe", "") : itemEditor.shield}</div></CaptionText>
            </GreenSection>
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            {data.data && data.data.length ? data.data.filter(item => item.order_id === itemEditor.order_id && item.store_name === itemEditor.store_name).map((items, index) => (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", position: "relative" }} key={index} >
                <BlueSection >
                  <img src={items.images} style={{ width: "60px", objectFit: "contain", marginRight: "10px" }}></img>
                  <div>
                    <CaptionText>
                      <a href={items.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5, marginRight: "5px", fontWeight: '400' }}>{items.items_name}</a>
                    </CaptionText>
                    <CaptionText>
                      <NormalText>{items.size ? items.size.replace("Type: ", "").replace("Fabric Brand: ", "").replace("Style: ", "").replace("Size: ", "").replace("Product Sides Options: ", "") : ""} - <RedText>{items.color}</RedText></NormalText>
                    </CaptionText>
                  </div>
                </BlueSection>
                <span style={{ position: "absolute", top: -7, right: -9, background: "white", borderRadius: "100%", width: "25px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <strong style={{ color: "black" }}>{items.quantity}</strong>
                </span>
              </div>
            )) : null}
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <DarkSection>
              <CaptionText2>
                Ship to: <NormalText><HighlightedText>{itemEditor.customer_name}</HighlightedText> - {itemEditor?.address ? `${itemEditor?.address[0]} ${itemEditor?.address[1]}, ${itemEditor?.address[2]}, ${itemEditor?.address[3]} ${itemEditor?.address[4]}, United States` : null}</NormalText>
              </CaptionText2>
              <CaptionText2>
                Email: <NormalText>{itemEditor.email}</NormalText>
              </CaptionText2>
              <CaptionText2>
                Customer Phone: <NormalText>{itemEditor.phone ? itemEditor.phone.includes("+1") ? itemEditor.phone : `+1 ${itemEditor.phone}` : ""}</NormalText>
              </CaptionText2>
            </DarkSection>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog open={open5} onClose={handleClose5} PaperProps={{
        sx: {
          maxWidth: 'md',
          alignItems: "center"
        },
      }}>
        <DialogTitle>Fulfil</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "80vw" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {ffSite.map(items => (
                <MDButton
                  disabled={items == "Printify" || items == "Burgershop" || items == "Printway"}
                  key={items}
                  color={items === ff ? "success" : "dark"}
                  style={{ margin: 2 }}
                  onClick={() => {
                    setFf(items)
                    setSelectedItems([])
                  }}
                >
                  {items}
                </MDButton>
              ))}
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              {data.data && data.data.length ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Select</th>
                      <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Item Name</th>
                      <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Options</th>
                      <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Images</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.filter(item => item.order_id === itemEditor.order_id && !item.status_fulfil).map(item => (
                      <tr key={item.id} >
                        <td style={{ padding: '8px', width: "5%" }}>
                          <FormControlLabel
                            sx={{ display: 'flex', flexDirection: 'row' }}
                            control={
                              <MDCheckbox
                                checked={!!selectedItems[item.id]}
                                value={item.id}
                                onChange={handleCheckboxChange(item)}
                              />
                            }
                          />
                        </td>
                        <td style={{ padding: '8px', width: "30%" }}>
                          <div style={{ fontWeight: item?.price > 0 ? '400' : '' }}>{item.items_name}</div>
                        </td>
                        <td style={{ padding: '8px' }}>
                          {item.size.replace("*", "").replace("Type: ", "")
                            .replace("Fabric Brand: ", "")
                            .replace("Style: ", "")
                            .replace("Size: ", "")
                            .replace("Product Sides Options: ", "").replace("Product Sides: ", "")} -
                          <span style={{ color: 'red', fontWeight: 'bold' }}> {item.color}</span>
                        </td>
                        <td style={{ padding: '8px', display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <span className="caption-text" style={{ fontSize: "10px", margin: 5 }}>Mockup:</span>
                          <a href={modifyUrlString(item?.images)}><img src={modifyUrlString(item?.images)} style={{ height: '70px' }} alt="Mockup" /></a>
                          {item.size.replace("*", "").includes("Both Sides") || item.size.replace("*", "").includes("Front Side Only") || item.size.replace("*", "").includes("Front Side only") || item.size.replace("*", "").includes("Size: US") ?
                            <><span className="caption-text" style={{ fontSize: "10px", margin: 5 }}>Front:</span>
                              <a href={item?.design_link?.front}><img src={item?.design_link?.front} style={{ height: '70px' }} alt="Front" /></a></> : null}
                          {item.size.replace("*", "").includes("Both Sides") || item.size.replace("*", "").includes("Back Side Only") || item.size.replace("*", "").includes("Back Side only") ?
                            <><span className="caption-text" style={{ fontSize: "10px", margin: 5 }}>Back:</span>
                              <a href={item?.design_link?.back}><img src={item?.design_link?.back} style={{ height: '70px' }} alt="Back" /></a></> : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>

            {Object.values(selectedItems).length > 0 && Object.values(selectedItems).filter(items => items != undefined) != 0 ? <MDButton
              color="dark"
              style={{ margin: 2 }}
              onClick={handleFulFil} >
              Fulfil
            </MDButton> : null}
          </MDBox>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>
      <Dialog open={open6} onClose={handleClose6}>
        <DialogTitle>Color</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Color"
              sx={{ margin: "10px", width: 300 }}
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangeColor2()} variant="outline" style={{ color: "green" }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open7} onClose={handleClose7}>
        <DialogTitle>Size</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Size"
              sx={{ margin: "10px", width: 800 }}
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangeSize2()} variant="outline" style={{ color: "green" }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open8} onClose={handleClose8}>
        <DialogTitle>Delete Fulfil</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label='Enter "ok" to display the delete button'
              sx={{ margin: "10px", width: 300 }}
              value={clear}
              onChange={(e) => setClear(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          {clear == "ok" ? <Button onClick={() => handleClear()} variant="outline" style={{ color: "green" }}>
            Xoá FF
          </Button> : null}
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
