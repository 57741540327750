/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";
// EGEAD Fulfilment POD example components
import ReplayIcon from '@mui/icons-material/Replay';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
// Data
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";

import { FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import { addStore, getStore, getStore2 } from "features/slices";
function Store() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.user.store || []);
  const user = useSelector((state) => state.user.user);
  const [storeName, setStoreName] = useState('');
  const [cs, setCs] = useState('');
  const [ck, setCk] = useState('');
  const [DROP, setDROP] = useState(false);
  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setDROP(isChecked);
  };
  const handleAddStore = async () => {
    await dispatch(addStore({ name: storeName, cs, ck, drop: DROP }));
    // await dispatch(
    //   getStore()
    // );
    user == "tuanah252@gmail.com" ? null : dispatch(
      getStore()
    )
  };

  const handleCheckStore = async () => {
    // await dispatch(
    //   getStore2()
    // );
    user == "tuanah252@gmail.com" ? null : dispatch(
      getStore()
    )
  };

  useEffect(async () => {
    // dispatch(
    //   getStore()
    // );
    user == "tuanah252@gmail.com" ? null : dispatch(
      getStore()
    )
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        {user != "htntmmiua@gmail.com" && user != "maitrieu9820@gmail.com" ?
          <Card style={{ textAlign: "center" }} >

            <div style={{ padding: 10, textAlign: "center" }} >
              <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>Add Store</Typography>
              <ReplayIcon style={{ cursor: "pointer" }} onClick={handleCheckStore} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  sx={{ margin: 0.5 }}
                  autoFocus
                  margin="dense"
                  id="link"
                  label="Store Name"
                  type="text"
                  fullWidth
                  value={storeName}
                  onChange={event => setStoreName(event.target.value)}
                />
                <TextField
                  sx={{ margin: 0.5 }}
                  autoFocus
                  margin="dense"
                  id="link"
                  label="CS key API"
                  type="text"
                  fullWidth
                  value={cs}
                  onChange={event => setCs(event.target.value)}
                />
                <TextField
                  sx={{ margin: 0.5 }}
                  autoFocus
                  margin="dense"
                  id="link"
                  label="CK key API"
                  type="text"
                  fullWidth
                  value={ck}
                  onChange={event => setCk(event.target.value)}
                />
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
                  control={<Switch checked={DROP} onChange={handleSwitchChange} />}
                  label="DROP"
                />
              </div>
              <MDButton variant="contained" onClick={handleAddStore} color="primary" component="span" sx={{ margin: 1 }}>
                Add Store
              </MDButton>

            </div>
            <Typography sx={{ marginTop: 2, fontWeight: "bold" }}>Store Connected</Typography>
            <div style={{ padding: 10, width: "100%", display: "flex", flexDirection: "row" }}>
              {[0, 1, 2, 3, 4].map((items, indexs) => (
                <div
                  key={indexs}
                  style={{ padding: 5, width: "100%" }}
                >
                  <DataTable
                    table={{
                      columns: [
                        { Header: "name", accessor: "name", align: "center" },
                        { Header: "type", accessor: "type", align: "center" },
                        { Header: "status", accessor: "status", align: "center" },
                      ],
                      rows: store && store.length > 0 ? [...store.filter((_, index) => index % 5 === items).map((items, index) => ({
                        id: index,
                        name: <p style={{ color: "black" }}>{items[0]}</p>,
                        type: <MDBadge badgeContent={items[1] == true ? "DROP" : "POD"} color={items[1] == true ? "warning" : "primary"} variant="gradient" size="sm" />,
                        status: items[2] ? <CheckCircleIcon style={{ color: "#43A047" }} /> : <ErrorIcon style={{ color: "#FFA726" }} />,
                      })
                      )] : []
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </div>
              ))}
            </div>

          </Card>
          : null}
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Store;
