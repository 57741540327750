/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Autocomplete, Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Card from "@mui/material/Card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDPagination from 'components/MDPagination';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { editOrder, getOrders } from "features/slices";
import moment from "moment-timezone";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import bcrypt from 'bcryptjs';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/system';
import NoteDialog from 'examples/NoteDialog';
import { updateCurrentPage } from 'features/slices';
import deliStatus from './contants';
import { NotificationManager } from 'react-notifications';
import { upSync } from 'features/slices';
const Section = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

const GreenSection = styled(Section)({
});

const BlueSection = styled(Section)({
  background: 'linear-gradient(195deg, #9dbdba, #9dbdba)',
  width: "100%",
  display: "flex",
  flexDirection: "row"
});


const DarkSection = styled(Section)({
  background: 'linear-gradient(195deg, #42424a, #191919)',
});

const CaptionText = styled('p')({
  display: "flex",
  flexDirection: "row",
  fontWeight: 'bold',
  marginTop: 4,
  fontSize: "14px",
});

const CaptionText2 = styled('p')({
  fontWeight: 'bold',
  marginTop: 4,
  color: "white",
  fontSize: "14px",
});

const InfoText = styled('span')({
  marginLeft: 5,
  color: "#344767"
});

const NormalText = styled('span')({
  fontWeight: '400',
  marginLeft: 5,
  color: 'white',
});

const HighlightedText = styled('span')({
  fontWeight: '500',
  color: 'orange',
  marginLeft: 5,
  fontSize: "14px"

});

const RedText = styled('span')({
  color: 'red',
  fontWeight: 'bold',
});
function Tables() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.data || []);
  const permission = useSelector((state) => state.user.permission || []);
  const user = useSelector((state) => state.user.user);
  const filterStore = useSelector((state) => state.user.filterStore);
  const order_status = useSelector((state) => state.user.order_status);
  const type = useSelector((state) => state.user.type);
  const [shippingMethod, setShippingMethod] = useState("usps");
  const startDate = useSelector((state) => state.user.startDate);
  const search = useSelector((state) => state.user.search);
  const currentPage = useSelector((state) => state.user.currentPage) || 1;
  const endDate = useSelector((state) => state.user.endDate);
  const [price, setPrice] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  const [itemEditor, setItemEditor] = useState({})
  const [note, setNote] = useState("");

  const shippingOptions = [
    { value: "usps", label: "USPS" },
    { value: "4px", label: "4PX" },
    { value: "aliexpress-standard-shipping", label: "AliExpress Standard Shipping" },
    { value: "china-post", label: "China Post" },
    { value: "ups", label: "UPS" },
    { value: "yanwen", label: "Yanwen" },
    { value: "yun-express-cn", label: "Yun Express" },
    { value: "fedex", label: "FedEx" },
  ];

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleChangePage = async (newPage) => {
    await dispatch(updateCurrentPage(newPage))
  };


  const handleAddPrice = async (item) => {
    setItemEditor(item)
    setPrice(item.price)
    setOpen3(true)
  }

  const handleCopyClick = (text) => {
    NotificationManager.success(`Copied tracking.`, "Error", 700);
    navigator.clipboard.writeText(text)
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const onSync = async () => {
    const res = await dispatch(upSync({ id: itemEditor.id, tracking: itemEditor.tracking, provider: shippingMethod, store_name: itemEditor.store_name, order_id: itemEditor.order_id }))
    // if (res.payload.status == 0) {
    //   // bcrypt.genSalt(10, (err, salt) => {
    //   //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
    //   //     const time = moment().unix()
    //   //     const res = await dispatch(getOrders({
    //   //       store: filterStore, order_status,
    //   //       startDate: moment(startDate).startOf("day").format("x"),
    //   //       endDate: moment(endDate).endOf("day").format("x"),
    //   //       currentPage,
    //   //       search,
    //   //       version: hash,
    //   //       type
    //   //     }))

    //   //   });
    //   // })
    // }
    setOpenSync(false)
  };

  const handleChangeDeli = async (event, items) => {
    const res = await dispatch(editOrder({ user, field: "error_order", string: event.target.value, store_name: items.store_name, id: items.order_id }))
    if (res.payload.status == 0) {
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    }
  };

  const handleDeleteNote = async (index) => {
    const updatedNotes = itemEditor.note.split("\n").filter((_, i) => i !== index);
    const res = await dispatch(editOrder({ user, field: "note", string: updatedNotes.join("\n"), id: itemEditor.id }))
    if (res.payload.status == 0) {
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    }
    setOpen2(false)
  };

  const handleChangeNote = async (e) => {
    const res = await dispatch(editOrder({ user, field: "note", string: !itemEditor.note ? `${user.replace("@gmail.com", "")}: ${note}` : `${itemEditor.note}\n${user.replace("@gmail.com", "")}:${note}`, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
    setOpen2(false)
  }

  const handleShowInformation = (item) => {
    setItemEditor(item)
    setOpen4(true)
  }


  const handleAddNote = (item) => {
    setNote("")
    setItemEditor(item)
    setOpen2(true)
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleCloseSync = () => {
    setOpenSync(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };


  const changeCheckBox = async (items, checked, field, id) => {
    setItemEditor(items)
    const res = await dispatch(editOrder({ user, field, boolean: checked, id }))
    if (res.payload.status == 0) {
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"),
      //     currentPage, search, type
      //   })
      // );
      // bcrypt.genSalt(10, (err, salt) => {
      //   bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
      //     const time = moment().unix()
      //     const res = await dispatch(getOrders({
      //       store: filterStore, order_status,
      //       startDate: moment(startDate).startOf("day").format("x"),
      //       endDate: moment(endDate).endOf("day").format("x"),
      //       currentPage,
      //       search,
      //       version: hash,
      //       type
      //     }))

      //   });
      // })
    }
  }

  const handleSync = async (item) => {
    setItemEditor(item)
    console.log(item)
    setOpenSync(true)
  }

  const handleChangePrice = async (e) => {
    const res = await dispatch(editOrder({ user, field: "price", string: price, id: itemEditor.id }))
    if (res.payload.status == 0) {
      // await dispatch(
      //   getOrders({
      //     store: filterStore, order_status,
      //     startDate: moment(startDate).startOf("day").format("x"),
      //     endDate: moment(endDate).endOf("day").format("x"), currentPage, search, type
      //   })
      // );
      bcrypt.genSalt(10, (err, salt) => {
        bcrypt.hash(`${user}đố tuấn anh phá được`, salt, async (err, hash) => {
          const time = moment().unix()
          const res = await dispatch(getOrders({
            store: filterStore, order_status,
            startDate: moment(startDate).startOf("day").format("x"),
            endDate: moment(endDate).endOf("day").format("x"),
            currentPage,
            search,
            version: hash,
            type
          }))

        });
      })
    }
    setOpen3(false)
  }

  const capitalizeAndColorBeforeColon = (note) => {
    const lines = note.split('\n');
    return lines.map((line, index) => {
      const [beforeColon, afterColon] = line.split(':', 2);
      const capitalizedBeforeColon = beforeColon.trim().charAt(0).toUpperCase() + beforeColon.trim().slice(1);
      return (
        <div key={index}>
          <span style={{ color: 'red', fontWeight: "bold" }}>{capitalizedBeforeColon}</span>: {afterColon ? afterColon.trim() : ""}
        </div>
      );
    });
  };

  const renderPagination = Array.from({ length: data.totalPage }, (_, index) => index + 1)
    .filter(page =>
      page === 1 || // First page
      page === data.totalPage || // Last page
      Math.abs(page - currentPage) <= 1 // Pages adjacent to the current page
    )
    .map((page, idx, filteredPages) => (
      <>
        {/* Display ellipsis if there's a gap from the previous page */}
        {idx > 0 && page - filteredPages[idx - 1] > 1 && (
          <span key={`ellipsis-${page}`} style={{ padding: "0 5px" }}>...</span>
        )}
        <MDPagination
          item
          key={page}
          onClick={() => handleChangePage(page)}
          active={currentPage === page}
        >
          {page}
        </MDPagination>
      </>
    ));


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "date", accessor: "date", align: "left", width: "1%" },
                      { Header: "order id", accessor: "order_id", align: "left" },
                      { Header: "name", accessor: "items_name", align: "left" },
                      { Header: "shield", accessor: "shield", align: "left" },
                      { Header: "price", accessor: "price", align: "center" },
                      { Header: "tracking", accessor: "tracking", align: "center" },
                      { Header: "input web", accessor: "import", align: "center" },
                      { Header: "customer", accessor: "customer", align: "left" },
                      { Header: "message to customer", accessor: "is_message_to_customer", align: "left" },
                      { Header: "fulfil", accessor: "fulfil", align: "left" },
                      { Header: "nhung", accessor: "nhung_check", align: "center" },
                      { Header: "order status", accessor: "error_order", align: "left" },
                      { Header: "note", accessor: "note", align: "left", width: "300px" },
                    ],
                    rows: data.data && data.data.length > 0 ? [...data.data.reduce((acc, item) => {
                      const exists = acc.some(
                        existingItem =>
                          existingItem.order_id === item.order_id &&
                          existingItem.tracking === item.tracking
                      );
                      if (!exists) {
                        acc.push(item);
                      }

                      return acc;
                    }, []).map((items, index) => ({
                      id: items.order_id,
                      cancel: items.tracking,
                      order_status: items.error_order,
                      itemEditor: itemEditor.order_id,
                      date: <>
                        {items.price ? <span className="caption-text" style={{ fontWeight: items?.price > 0 ? "bold" : "", color: items?.price > 0 ? "black" : "" }}>{moment(items.date).format("DD/MM")}</span> : ""}
                      </>,
                      order_id: <>
                        {
                          items.price || search ? <>
                            <p className="caption-text" style={{ color: "black", fontWeight: "bold" }}> {`${items.order_id}`}</p>
                            <p className="caption-text">{`${items.store_name}`}</p></> : ""}</>,
                      items_name: <button style={{ width: 19, marginLeft: "5px", border: "1px solid #F3C262", background: "#F3C262", borderRadius: "100%", fontWeight: "bold" }} onClick={() => handleShowInformation(items)}>!</button>,
                      shield: (
                        <div>
                          {items.price ? (<div style={{ display: "flex", flexDirection: "row" }}><img src={items.shield.includes("PayPal:") ? "https://egead.nyc3.digitaloceanspaces.com/pp.png" : items.shield.includes("Stripe:") ? "https://egead.nyc3.digitaloceanspaces.com/stripe.png" : items.shield.includes("AirWallex:") ? "https://egead.nyc3.digitaloceanspaces.com/1732162753304ylmhtm.jpg" : "https://egead.nyc3.digitaloceanspaces.com/card.png"} style={{ width: 20, marginRight: 5 }}></img>{items.shield.replace("PayPal:", "").replace("Stripe:", "").replace("Stripe", "").replace("AirWallex:", "")}</div>
                          ) : null}
                        </div>
                      ),
                      price: (
                        <div>
                          {items.price ? <MDBadge badgeContent={items.price} style={{ cursor: "pointer" }} onClick={permission.includes("price") ? () => handleAddPrice(items) : null} color="primary" variant="gradient" size="md" max={9999999999999999999999999999999999999999999999999999999999} /> : null}
                        </div>
                      ),
                      tracking: (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{items.tracking ? items.tracking == "cancel" ? <MDBadge badgeContent={items.tracking} color="error" variant="gradient" size="sm" /> : items.tracking == "refund" ? <MDBadge badgeContent={items.tracking} color="warning" variant="gradient" size="sm" /> : <MDBadge badgeContent={items.tracking} style={{ cursor: "pointer" }} onClick={() => handleCopyClick(items.tracking)} color={items?.cong ? "light" : "info"} variant="gradient" size="sm" max={9999999999999999999999999999999999999999999999999999999999} /> : null}{items.price && items.tracking && !items.mai ? <div style={{ alignItems: "center", display: "flex", background: "red", borderRadius: "100%", marginLeft: 5, height: "18.88px", width: "18.88px" }}> <SyncIcon style={{ color: "white", fontSize: "larger", margin: 2, cursor: "pointer" }} onClick={() => handleSync(items)} /></div> : null}</div>
                          {items.deli ? <MDBadge style={{ marginTop: 2 }} badgeContent={items.deli} color={items.deli == "Delivered" ? "success" : items.deli == "Alert" ? "error" : "light"} variant="gradient" size="sm" /> : null}
                          {items.is_deli != " - , " && items.is_deli != "" && items.is_deli != false ? <MDBadge style={{ marginTop: 2 }} badgeContent={items.is_deli} color="light" variant="gradient" size="sm" /> : null}
                        </div>
                      ),
                      import: (
                        <> {items.price ?
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={items?.mai}
                                icon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      width: 20,
                                      height: 20,
                                      borderColor: 'black',
                                      borderWidth: 1,
                                      borderStyle: 'solid',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#1976d2',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                  </span>
                                }
                                onClick={() =>
                                  permission.includes("mai")
                                    ? changeCheckBox(items, items?.mai, "mai", items.id)
                                    : null
                                }
                              />
                            }
                          /> : null
                        }</>
                      ),
                      customer: <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="caption-text" style={{ fontWeight: "bold", color: items?.price > 0 ? "black" : "" }}>{items.shipping_method}</span>
                        <span className="caption-text" style={{ fontWeight: items?.price > 0 ? "500" : "", color: items?.price > 0 ? "black" : "" }}>{items.email}</span>
                      </div >,
                      fulfil: (
                        <>
                          {items?.fulfil ?
                            <MDBadge badgeContent={items?.fulfil.toUpperCase().includes("MX") ? "Merchfox" : items?.fulfil.toUpperCase().includes("MO") ? "Mango" : items?.fulfil.toUpperCase().includes("BF") ? "Beeful" : items?.fulfil.toUpperCase().includes("PY") ? "Printway" : items?.fulfil.toUpperCase().includes("PTY") ? "Printify" : items?.fulfil.toUpperCase().includes("BG") ? "BugerPrint" : items.fulfil} color="primary" variant="gradient" size="sm" />
                            : null
                          }
                        </>
                      ),
                      is_message_to_customer: (
                        <>
                          {items.price ?
                            <FormControlLabel
                              control={<MDCheckbox checked={items?.is_message_customer}
                                icon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      width: 20,
                                      height: 20,
                                      borderColor: 'black',
                                      borderWidth: 1,
                                      borderStyle: 'solid',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#1976d2',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                  </span>
                                }
                                onClick={() => permission.includes("is_message_customer") ? changeCheckBox(items, items?.is_message_customer, "is_message_customer", items.id) : null} />}
                            /> : null
                          }
                        </>
                      ),
                      nhung_check: items.price ?
                        <FormControlLabel
                          control={<MDCheckbox checked={items?.nhung}
                            icon={
                              <span
                                style={{
                                  borderRadius: '50%',
                                  width: 20,
                                  height: 20,
                                  borderColor: '#e36255',
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                            }
                            checkedIcon={
                              <span
                                style={{
                                  borderRadius: '50%',
                                  width: 20,
                                  height: 20,
                                  backgroundColor: '#1976d2',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                              </span>
                            }
                            onClick={() => permission.includes("nhung") ? changeCheckBox(items, items?.nhung, "nhung", items.id) : null} />}
                        /> : null
                      ,
                      error_order: (
                        <>
                          <FormControl fullWidth>
                            <Select
                              labelId="delivery-status-label"
                              id="delivery-status"
                              value={items.error_order}
                              sx={{ height: 37, width: 85, background: "white" }}
                              onChange={(e) => handleChangeDeli(e, items)}
                            >
                              {deliStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      ),

                      note: <div style={{ display: "flex", flexDirection: "column" }}>
                        {items.price && items.customer_note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}><span style={{
                          fontSize: "11px", color: "black", fontWeight: "bold"
                        }}>Customer Note: </span>{items.customer_note}</span> : null}
                        {items.note ? <span style={{
                          fontSize: "11px", maxWidth: "200px",
                          color: "black",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          display: "block"
                        }}>{capitalizeAndColorBeforeColon(items.note)}</span> : null}
                        {permission.includes("note") ? <MDBadge badgeContent={`Add Note`} onClick={() => handleAddNote(items)} style={{ cursor: "pointer" }} color="warning" variant="gradient" size="sm" /> : null}
                      </div>,

                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  padding="5px"
                >
                  <MDPagination
                    variant="gradient"
                    color="info"
                  >
                    {currentPage > 1 && data.totalPage > 1 && (
                      <MDPagination item onClick={() => handleChangePage(currentPage - 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    )}
                    {renderPagination}
                    {currentPage < data.totalPage && (
                      <MDPagination item onClick={() => handleChangePage(currentPage + 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    )}
                  </MDPagination>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <NoteDialog open2={open2} handleDeleteNote={handleDeleteNote} handleClose2={handleClose2} note2={note} note={itemEditor.note} user={user.replace("@gmail.com", "")} setNote={setNote} handleChangeNote={handleChangeNote} />
      <Dialog open={open3} onClose={handleClose3}>
        <DialogTitle>Price</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Price"
              sx={{ margin: "10px", width: 300 }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangePrice()} variant="outline" style={{ color: "green" }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open4} onClose={handleClose4}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <MDBox sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <GreenSection>
              <CaptionText>Order ID: <InfoText>{itemEditor.order_id}</InfoText></CaptionText>
              <CaptionText>Store: <InfoText>{itemEditor.store_name}</InfoText></CaptionText>
              <CaptionText>Payment Method: <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}><img src={itemEditor.shield ? itemEditor.shield.includes("PayPal:") ? "https://egead.nyc3.digitaloceanspaces.com/pp.png" : itemEditor.shield.includes("Stripe:") ? "https://egead.nyc3.digitaloceanspaces.com/stripe.png" : "https://egead.nyc3.digitaloceanspaces.com/card.png" : "https://egead.nyc3.digitaloceanspaces.com/card.png"} style={{ height: 20, marginRight: 5 }}></img>{itemEditor.shield ? itemEditor.shield.replace("PayPal:", "").replace("Stripe:", "").replace("Stripe", "") : itemEditor.shield}</div></CaptionText>
            </GreenSection>
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            {data.data.filter(item => item.order_id === itemEditor.order_id && item.store_name === itemEditor.store_name).map((items, index) => (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", position: "relative" }} key={index} >
                <BlueSection >
                  <img src={items.images} style={{ width: "60px", objectFit: "contain", marginRight: "10px" }}></img>
                  <div>
                    <CaptionText>
                      <a href={items.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5, marginRight: "5px", fontWeight: '400' }}>{items.items_name}</a>
                    </CaptionText>
                    <CaptionText>
                      <NormalText>{items.size ? items.size.replace("Type: ", "").replace("Fabric Brand: ", "").replace("Style: ", "").replace("Size: ", "").replace("Product Sides Options: ", "") : ""} - <RedText>{items.color}</RedText></NormalText>
                    </CaptionText>
                  </div>
                </BlueSection>
                <span style={{ position: "absolute", top: -7, right: -9, background: "white", borderRadius: "100%", width: "25px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <strong style={{ color: "black" }}>{items.quantity}</strong>
                </span>
              </div>
            ))}
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <DarkSection>
              <CaptionText2>
                Ship to: <NormalText><HighlightedText>{itemEditor.customer_name}</HighlightedText> - {itemEditor?.address ? `${itemEditor?.address[0]} ${itemEditor?.address[1]}, ${itemEditor?.address[2]}, ${itemEditor?.address[3]} ${itemEditor?.address[4]}, United States` : null}</NormalText>
              </CaptionText2>
              <CaptionText2>
                Email: <NormalText>{itemEditor.email}</NormalText>
              </CaptionText2>
              <CaptionText2>
                Customer Phone: <NormalText>{itemEditor.phone ? itemEditor.phone.includes("+1") ? itemEditor.phone : `+1 ${itemEditor.phone}` : ""}</NormalText>
              </CaptionText2>
            </DarkSection>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog open={openSync} onClose={handleCloseSync}>
        <DialogTitle>Choose Shipping Method</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Shipping Method"
            value={shippingMethod}
            onChange={(event) => setShippingMethod(event.target.value)}
            fullWidth
            margin="normal"
          >
            {shippingOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSync} variant="outlined" style={{ color: "black" }}>
            Cancel
          </Button>
          <Button onClick={() => onSync()} variant="outlined" style={{ background: "green" }}>
            Sync
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
