/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// EGEAD Fulfilment POD example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { filterImage } from "features/slices";
import * as XLSX from 'xlsx';
// Data
import MDButton from "components/MDButton";
import { useState } from "react";

import moment from "moment";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { changeimage } from "features/slices";
function Tables() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [bucket, setBucket] = useState("");
  const [fileName, setFileName] = useState('');
  const [exportData, setExportData] = useState([]);
  const handleClick = async () => {
    let res
    if (alert) {
      res = await dispatch(changeimage({ data, bucket }));
    } else {
      res = await dispatch(filterImage({ data, bucket }));
    }
    setExportData(res.payload.data)
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      // Handle the file upload logic here
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const reader2 = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(reader2, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // const filteredData = excelData.slice(1).map(row => [row[3], row[26], row[29]])
      //   .filter(row => row.every(value => value !== null && value !== ""));

      const filteredData = excelData.map(row => [row[3], row[26], row[29], row[8], row[25]]).filter((row, index) => {

        // Dòng header
        if (index === 0) {
          return false;
        }
        return row.some(cellValue => cellValue !== null && cellValue !== "" && cellValue !== undefined);
      });
      console.log(filteredData)
      setData(filteredData)
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExport = async () => {
    const combinedData = exportData
    const ws = XLSX.utils.aoa_to_sheet(combinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${moment().unix()}.csv`);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} pb={3} style={{ alignSelf: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <input
                  accept="*"
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="file-upload"  >
                  <MDButton variant="contained" color="primary" component="span" sx={{ margin: 1, width: 300 }}>
                    {fileName || 'Upload File'}
                  </MDButton>
                </label>
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: "row", margin: 0.5 }}
                  control={<Switch checked={alert} onChange={(e) => setAlert(e.target.checked)} />}
                  label="Change Image Link"
                />

                <TextField
                  label="Bucket"
                  sx={{ margin: "10px", width: 300 }}
                  value={bucket}
                  onChange={(e) => setBucket(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <MDButton variant="contained" color="primary" style={{ margin: 5, width: 300 }} onClick={handleClick}>
                  Filter
                </MDButton>

                {exportData && exportData.length > 0 ? <MDButton variant="contained" color="primary" sx={{ width: 300 }} onClick={() => handleExport()}>EXPORT CSV</MDButton> : null}
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
